@use "../../../00-settings" as *;
@use "../../../02-components/buttons-and-links/_button-mixins.scss" as *;
@use "../../../02-components/forms/_form-mixins.scss" as *;
@use "../../../02-components/forms/fields/_fieldsets.scss" as *;

/* ---- Selects ---- */
.field-element select {
    @include field-select;
}

.field-element select[multiple] {
	background-image: none;
	padding-right: 20px;
}

.field-element select::-ms-expand,
.daterangepicker select.monthselect::-ms-expand,
.daterangepicker select.yearselect::-ms-expand {
    display: none;
}

/* Multiple selects */
.field-element.field-element--select.field-element--select--multiple select {
	padding: 8px 8px;
	background-image: none;
}

.field-element.field-element--select.field-element--select--multiple option {
	padding: 6px 10px;
}

/* Small */
.field-element--small select {
    background-image:
        url(#{$file-path}icon-system/icon_form_arrow-down-sm.svg),
        url(#{$file-path}icon-system/form_bg-fill.svg);
    background-repeat: no-repeat, repeat-Y;
    background-position: center right $form-spacing, center right;
    background-size: $form-spacing*2, $form-spacing*4;
    padding-right: $form-spacing*5.5;
}

/* Large */
.field-element--large select {
	background-position: top -247px right -105px, top -447px right -92px;
}

/* Large and regular field elements turn small */
@media screen and (max-width: 900px) {
	.field-element select,
	.field-element.field-element--large select,
	.field-element.field-element--regular select {
		background-position: top -262px right -104px, top -447px right -92px;
		font-size: 1.4rem;
		padding: 7px 15px 7px;
		padding-right: 55px;
	}
}
