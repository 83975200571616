
@use "../../00-settings" as *;
/* ---- Text level elements ---- */
abbr[title] {
    border-bottom: 1px dotted $color-grey-04;
    cursor: help;
}

b, strong {
    font-weight: bold;
}

dfn {
    font-style: italic;
}

ins {
    background-color: $color-grey-01;
    color: $color-body-font;
    text-decoration: none;
}

mark {
    background-color: $color-grey-01;
    color: $color-body-font;
    font-style: italic;
    font-weight: bold;
}

pre,
code,
kbd,
samp {
    font-family: Monaco, Courier New, monospace;
    color: $color-body-font;
    background: $color-grey-01;
    background: rgba(0, 0, 0, .07);
    padding: 0 2px;
    margin: 0 -2px;
}

pre {
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -.5em;
}

sub {
    bottom: -.25em;
}

small {
    font-size: 1.28rem;
}
