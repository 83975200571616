
@use "../../00-settings" as *;

.text-align-right {
    text-align: right;
}

.text-align-left {
    text-align: left;
}

.text-align-center {
    text-align: center;
}

@each $key, $value in $breakpoints-list {
    .text-align-right--#{$key} {
        @include screen(#{$value}) {
            text-align: right;
        }
    }
    .text-align-left--#{$key} {
        @include screen(#{$value}) {
            text-align: left;
        }
    }
    .text-align-center--#{$key} {
        @include screen(#{$value}) {
            text-align: center;
        }
    }
}

