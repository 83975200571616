@use "../../../00-settings" as *;

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
    background-color: $color-grey-02;
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px $color-grey-02 inset;
}

input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    outline: $color-grey-07 auto 5px;
    background-color: $color-grey-02;
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 80px $color-grey-02 inset;
    transition: background-color 5000s ease-in-out 0s;
}


/* ---- Autocomplete ---- */
.ui-autocomplete {
    list-style: none;
    background: #FFFFFF;
    max-height: 400px;
    overflow-y: auto;
    border: 1px solid #CED2DC;
    border-radius: 4px;
    padding: 3px 0;
    margin: 0;
}
.ui-autocomplete .ui-menu-item {
    padding: 5px 11px;
    cursor: pointer;
}
.ui-autocomplete .ui-menu-item.ui-state-focus {
    padding: 5px 11px;
    background: #358AAB;
    color: #FFF;
}

