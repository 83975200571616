@use "../../../00-settings" as *;
@use "../../../02-components/buttons-and-links/_button-mixins.scss" as *;
@use "../../../02-components/forms/_form-mixins.scss" as *;
@use "../../../02-components/forms/fields/_fieldsets.scss" as *;

.field--signature {
    :where(canvas) {
        @include form-textbox;
        @include form-textbox-styles;
        width: auto;
        // The border colour for the signature canvas is set within the field settings in the cms and is inlined, gotta thrash the specificity to have it match the form styles
        border-color: $color-grey-02 !important;
    }

    :where(button) {
        @include button($color-grey-07);
    }
}
