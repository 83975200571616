
@use "../../00-settings" as *;

:root {
    --section: 26px;
    --section-lg: 40px;
}

@include screen($bp360) {
    :root {
        --section: 32px;
        --section-lg: 48px;
    }
}

@include screen($bp768) {
    :root {
        --section: 40px;
        --section-lg: 64px;
    }
}

@include screen($bp992) {
    :root {
        --section: 56px;
        --section-lg: 80px;
    }
}

@include screen($bp1200) {
    :root {
        --section: 80px;
        --section-lg: 96px;
    }
}

.section {
    position: relative;
    padding-top: var(--section);
    padding-bottom: var(--section);
}

.section-t0 {
    padding-top: 0;
}

.section-b0 {
    padding-bottom: 0;
}
