/* ---- Color picker ---- */
.field-element--colorpicker .textbox.colorpicker {
	padding: 3px 8px;
	height: 43px;
	width: 50px;
}

/* Small */
.field-element--colorpicker.field-element--small .textbox.colorpicker {
	padding: 2px 7px;
	height: 33px;
	width: 39px;
}

/* Large and regular field elements turn small */
@media screen and (max-width: 900px) {
	.field-element.field-element--colorpicker .textbox.colorpicker,
	.field-element.field-element--colorpicker.field-element--large .textbox.colorpicker,
	.field-element.field-element--colorpicker.field-element-regular .textbox.colorpicker {
		padding: 2px 7px;
		height: 33px;
		width: 39px;
	}
}
