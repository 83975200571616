
@use "../00-settings" as *;

#content {
    background-color: $color-white;

    @include screen($bpdesktop) {
        overflow-x: hidden;
        // this is to contain bleed panels pseudo background elements
    }
}
