
@use "../../00-settings" as *;

/* ---- Expando List ---- */
.expando-list {
	list-style: none;
	padding: 20px 0 10px;
	margin: 0;
}
.expando-opener-heading {
	padding: 0 0 0 42px;
	cursor: pointer;
	position: relative;
}
.expando-opener-heading:hover {
	color: #000;
}
.expando {
	border: 1px solid #e5e5e5;
	padding: 18px 20px 10px;
	margin: 10px 0 30px;
}
ul.expando-list ul {
	list-style: disc;
}

.expando-opener-heading:before {
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	content: "\e900";
	padding: 4px;
	display: block;
	float: left;
	background-color: #f4f4f4;
	margin: -4px 15px 11px 0;
	text-align: center;
	position: absolute;
	top: 0;
	left: 0;
}
.expando-opener-heading:hover:before {
	background-color: #e4e4e4;
}
.expando-opener-heading.expanded:before {
	content: "\e903";
}
.expando.expanded {
	-webkit-animation: fadeIn 300ms ease;
	        animation: fadeIn 300ms ease;
}
