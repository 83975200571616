@use "../../../00-settings" as *;
@use "../../../02-components/buttons-and-links/_button-mixins.scss" as *;
@use "../../../02-components/forms/_form-mixins.scss" as *;
@use "../../../02-components/forms/fields/_fieldsets.scss" as *;
@use "../../../05-utilities/helpers/_utility-mixins.scss" as *;

@forward "../../../02-components/forms/utilities/_form-field-anchors.scss";

$form-icon-arrow-down: 16px;

.field-element--totalselector {
    position: relative;
}

.total-selector__output {
    width: 100%;
    @include form-textbox;
    @include form-textbox-styles;
    background-image:
        url(~@/assets/images/fields/field_arrow-down.svg),
        url(~@/assets/images/fields/field_background-fill.svg);
    background-repeat:
        no-repeat,
        repeat-Y;
    background-position:
        center right calc((40px - #{$form-icon-arrow-down}) / 2),
        center right;
    background-size:
        $form-icon-arrow-down,
        40px;
    padding-right: 52px;

    .field-element--guests & {
        @include form-textbox;
        @include form-textbox-styles;
        background-image:
            url(~@/assets/images/fields/field_persons.svg),
            url(~@/assets/images/fields/field_arrow-down.svg),
            url(~@/assets/images/fields/field_background-fill.svg);
        background-repeat:
            no-repeat,
            no-repeat,
            repeat-Y;
        background-position:
            center left 12px,
            center right calc((40px - #{$form-icon-arrow-down}) / 2),
            center right;
        background-size:
            16px,
            $form-icon-arrow-down,
            40px;
        padding-left: 40px;
        padding-right: 40px;
    }
}

.field-element--totalselector__dropdown {
    position: absolute;
    background: $color-white;
    top: 100%;
    left: 0;
    right: 0;
    padding: 20px 24px;
    margin-top: 2px;
    border-radius: 4px;
    z-index: 800;
    border: 1px solid $color-grey-02;
    display: none;
    white-space: nowrap;
    text-transform: none;
}

.field-element--totalselector.field-element--totalselector--active .field-element--totalselector__dropdown {
    display: block;
}

.field-element--totalselector__dropdown:before {
    @extend %form-field-pseudo-anchor--before-lhs;
}

.field-element--totalselector__dropdown:after {
    @extend %form-field-pseudo-anchor--after-lhs;
}

.total-selector__dropdown__field {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 0 0 16px;
}

.total-selector__dropdown__field:last-child {
    margin-bottom: 0;
}

.total-selector__dropdown__field__labels {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
}

.total-selector__dropdown__field__buttons {
    flex: 0 0 auto;
    display: flex;
    flex-wrap: nowrap;
}

.total-selector__dropdown__field__button {
    -webkit-appearance: none;
    border: 2px solid transparent;
    border-radius: 1px;
    outline: none;
    background: none;
    background-color: $color-white;
        box-shadow:
        0 1px 1px 0 rgba(0,0,0,0.14),
        0 2px 1px -1px rgba(0,0,0,0.12),
        0 1px 3px 0 rgba(0,0,0,0.2);
    padding: 0;
    height: 32px;
    width: 32px;
    display: grid;
    place-items: center;
    color: inherit;
    cursor: pointer;
    font-size: 80%;
    flex: 0 0 auto;
    border-radius: 4px;

    @include svg-contents {
        stroke: currentColor;
    }
}

.total-selector__dropdown__field__button:focus {
    color: $color-06;
    border-color: $color-grey-07;
    outline: 1px dashed $color-grey-07;
    outline-offset: 1px;
    background-color: $color-white;
}

.total-selector__dropdown__field__button:hover {
    color: $color-06;
    border-color: $color-06;
    background-color: $color-white;
}

/* Min / max */
.total-selector__dropdown__field__button.min,
.total-selector__dropdown__field__button.max {
    opacity: 0.5;
    pointer-events: none;
    border-color: transparent;
    color: $color-body-font;
}

.total-selector__dropdown__field__total,
.field-input .textbox.total-selector__dropdown__field__total {
    -webkit-appearance: none;
    flex: 0 0 auto;
    padding: 0 12px;
    width: 50px;
    text-align: center;
    background-color: transparent;
    font: inherit;
    color: $color-black;
    font-weight: 600;

    &[readonly] {
        border: none;
        text-align: center;
        background-color: transparent;

        &:focus {
            outline: none;
        }
    }
}

.total-selector__dropdown__field__labels p {
    display: block;
    margin-right: 8px;
}
.total-selector__dropdown__field__labels__title,
.field-element--totalselector__dropdown .total-selector__dropdown__field__labels__title {
    margin: 0;
    color: $color-body-font;
    font-weight: 600;
    padding-right: 8px;
}

.total-selector__dropdown__field__labels__helper,
.field-element--totalselector__dropdown .total-selector__dropdown__field__labels__helper {
    margin: 0;
    font-size: var(--milli-font-size);
    color: $color-grey-07;
    text-transform: none;
}

.field-element--totalselector__fields {
    display: none;
}
