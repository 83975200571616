@use "../../00-settings" as *;
@use "../../05-utilities/helpers/_utility-mixins.scss" as *;
@use "../../02-components/icons/_icomoon" as *;

.linklist-list {
    @include list-reset;
    @include font-set('accent');
}

.linklist-list-item {
    a {
        text-decoration: none;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        padding-block: 0.25em;

        &:after {
            @include icomoon-icon;
            content: "\e908";
            display: block;
        }
    }
}
