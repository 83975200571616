
/* ---- Time pickers ---- */
.field-element--timepicker .field-input .textbox {
	padding-left: 55px;
	background-image: url(../images/form-icons.svg),
	url(../images/form-icons.svg);
	background-repeat: no-repeat, no-repeat;
	background-position: top -170px left 12px, top -447px left 0;
}

.ui-timepicker {
	display: block;
	position: absolute;
	height: 200px;
	overflow: auto;
}

.ui-timepicker-standard {
	background-color: #FFF;
	border: 1px solid #CED2DC;
	display: block;
	margin: 3px 0 0;
	padding: 5px;
	list-style: none outside none;
	border-radius: 4px;
}

.ui-timepicker-standard a {
	color: #24292f;
}

.ui-timepicker-standard .ui-state-hover {
	background-color: #EAECF1;
}

.ui-timepicker-standard .ui-menu-item {
	clear: left;
	float: left;
	margin: 0;
	padding: 0;
	width: 100%;
}

.ui-timepicker-standard .ui-menu-item a {
	display: block;
	padding: 5px 10px;
	line-height: 1.5;
	text-decoration: none;
	border-radius: 3px;
}

.ui-timepicker-hidden {
	display: none;
}

/* White */
.field-element--timepicker.field-element--white .field-input .textbox {
	background-position: top -170px left 12px, top -607px left 0;
}

/* White + Small */
.field-element--timepicker.field-element--white.field-element--small .field-input .textbox {
	background-position: top -175px left 12px, top -607px left 0;
}

/* White + Large */
.field-element--timepicker.field-element--white.field-element--large .field-input .textbox {
	background-position: top -166px left 12px, top -607px left 0;
}

/* Small */
.field-element--timepicker.field-element--small .field-input .textbox {
	background-position: top -175px left 12px, top -447px left 0;
}

/* Large */
.field-element--timepicker.field-element--large .field-input .textbox {
	background-position: top -166px left 12px, top -447px left 0;
}


/* Large and regular field elements turn small */
@media screen and (max-width: 900px) {
	.field-element--timepicker .field-input .textbox,
	.field-element--timepicker.field-element--large .field-input .textbox,
	.field-element--timepicker.field-element--regular .field-input .textbox {
		background-position: top -175px left 12px, top -447px left 0;
		padding-left: 55px;
	}
	.field-element--white.field-element--timepicker .field-input .textbox,
	.field-element--white.field-element--timepicker.field-element--large .field-input .textbox,
	.field-element--white.field-element--timepicker.field-element--regular .field-input .textbox {
		background-position: top -175px left 12px, top -607px left 0;
	}
}
