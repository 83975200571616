
/* ---- Date pickers ---- */

/* Date picker and Date-range picker*/
.field-element--datepicker .field-input .textbox,
.field-element--daterangepicker .field-input .textbox {
	padding-left: 55px;
	background-image: url(../images/form-icons.svg),
	url(../images/form-icons.svg);
	background-repeat: no-repeat, no-repeat;
	background-position: top 11px left 12px, top -447px left 0;
}

/* White */
.field-element--datepicker.field-element--white .field-input .textbox,
.field-element--daterangepicker.field-element--white .field-input .textbox {
	background-position: top 11px left 12px, top -607px left 0;
}
/* White + Small */
.field-element--datepicker.field-element--white.field-element--small .field-input .textbox,
.field-element--daterangepicker.field-element--white.field-element--small .field-input .textbox {
	background-position: top 7px left 12px, top -607px left 0;
}
/* White + Large */
.field-element--datepicker.field-element--white.field-element--large .field-input .textbox,
.field-element--daterangepicker.field-element--white.field-element--large .field-input .textbox {
	background-position: top 15px left 12px, top -607px left 0;
}


/* Small */
.field-element--datepicker.field-element--small .field-input .textbox,
.field-element--daterangepicker.field-element--small .field-input .textbox {
	background-position: top 6px left 12px, top -447px left 0;
}

/* Large */
.field-element--datepicker.field-element--large .field-input .textbox,
.field-element--daterangepicker.field-element--large .field-input .textbox {
	background-position: top 15px left 12px, top -447px left 0;
}


/* Large and regular field elements turn small */
@media screen and (max-width: 900px) {
	.field-element--datepicker .field-input .textbox,
	.field-element--daterangepicker .field-input .textbox,
	.field-element--datepicker.field-element--large .field-input .textbox,
	.field-element--daterangepicker.field-element--regular .field-input .textbox,
	.field-element--datepicker.field-element--large .field-input .textbox,
	.field-element--daterangepicker.field-element--regular .field-input .textbox {
		background-position: top 6px left 12px, top -447px left 0;
		padding-left: 55px;
	}
	.field-element--white.field-element--datepicker .field-input .textbox,
	.field-element--white.field-element--daterangepicker .field-input .textbox,
	.field-element--white.field-element--datepicker.field-element--large .field-input .textbox,
	.field-element--white.field-element--daterangepicker.field-element--regular .field-input .textbox,
	.field-element--white.field-element--datepicker.field-element--large .field-input .textbox,
	.field-element--white.field-element--daterangepicker.field-element--regular .field-input .textbox {
		background-position: top 7px left 12px, top -607px left 0;
	}
}


/* Date/time picker */
.field-element--datetimepicker .field-input .textbox {
	padding-left: 55px;
	background-image: url(../images/form-icons.svg),
	url(../images/form-icons.svg);
	background-repeat: no-repeat, no-repeat;
	background-position: top -88px left 12px, top -447px left 0;
}

/* White */
.field-element--datetimepicker.field-element--white .field-input .textbox {
	background-position: top -88px left 12px, top -607px left 0;
}

/* White + Small */
.field-element--datetimepicker.field-element--small.field-element--white .field-input .textbox {
	background-position: top -93px left 12px, top -607px left 0;
}
/* White + Large */
.field-element--datetimepicker.field-element--large.field-element--white .field-input .textbox {
	background-position: top -85px left 12px, top -607px left 0;
}

/* Small */
.field-element--datetimepicker.field-element--small .field-input .textbox {
	background-position: top -93px left 12px, top -447px left 0;
}

/* Large */
.field-element--datetimepicker.field-element--large .field-input .textbox {
	background-position: top -85px left 12px, top -447px left 0;
}

/* Large and regular field elements turn small */
@media screen and (max-width: 900px) {
	.field-element--datetimepicker .field-input .textbox,
	.field-element--datetimepicker.field-element--large .field-input .textbox,
	.field-element--datetimepicker.field-element--regular .field-input .textbox {
		background-position: top -93px left 12px, top -447px left 0;
		padding-left: 55px;
	}
	.field-element--white.field-element--datetimepicker .field-input .textbox,
	.field-element--white.field-element--datetimepicker.field-element--large .field-input .textbox,
	.field-element--white.field-element--datetimepicker.field-element--regular .field-input .textbox {
		background-position: top -93px left 12px, top -607px left 0;
	}
}


/* Container Appearance */
.daterangepicker {
	position: absolute;
	background: #fff;
	top: 100px;
	left: 20px;
	padding: 4px;
	margin-top: 2px;
	border-radius: 4px;
	width: 278px;
}

.daterangepicker.opensleft:before {
	position: absolute;
	top: -7px;
	right: 9px;
	display: inline-block;
	border-right: 7px solid transparent;
	border-bottom: 7px solid #ccc;
	border-left: 7px solid transparent;
	border-bottom-color: rgba(0, 0, 0, 0.2);
	content: '';
}

.daterangepicker.opensleft:after {
	position: absolute;
	top: -6px;
	right: 10px;
	display: inline-block;
	border-right: 6px solid transparent;
	border-bottom: 6px solid #fff;
	border-left: 6px solid transparent;
	content: '';
}

.daterangepicker.openscenter:before {
	position: absolute;
	top: -7px;
	left: 0;
	right: 0;
	width: 0;
	margin-left: auto;
	margin-right: auto;
	display: inline-block;
	border-right: 7px solid transparent;
	border-bottom: 7px solid #ccc;
	border-left: 7px solid transparent;
	border-bottom-color: rgba(0, 0, 0, 0.2);
	content: '';
}

.daterangepicker.openscenter:after {
	position: absolute;
	top: -6px;
	left: 0;
	right: 0;
	width: 0;
	margin-left: auto;
	margin-right: auto;
	display: inline-block;
	border-right: 6px solid transparent;
	border-bottom: 6px solid #fff;
	border-left: 6px solid transparent;
	content: '';
}

.daterangepicker.opensright:before {
	position: absolute;
	top: -7px;
	left: 9px;
	display: inline-block;
	border-right: 7px solid transparent;
	border-bottom: 7px solid #ccc;
	border-left: 7px solid transparent;
	border-bottom-color: rgba(0, 0, 0, 0.2);
	content: '';
}

.daterangepicker.opensright:after {
	position: absolute;
	top: -6px;
	left: 10px;
	display: inline-block;
	border-right: 6px solid transparent;
	border-bottom: 6px solid #fff;
	border-left: 6px solid transparent;
	content: '';
}

.daterangepicker.dropup{
	margin-top: -5px;
}

.daterangepicker.dropup:before{
	top: initial;
	bottom:-7px;
	border-bottom: initial;
	border-top: 7px solid #CED2DC;
}

.daterangepicker.dropup:after{
	top: initial;
	bottom:-6px;
	border-bottom: initial;
	border-top: 6px solid #fff;
}

.daterangepicker.dropdown-menu {
	max-width: none;
	z-index: 3000;
	display: none;
	border: 1px solid #CED2DC;
}

.daterangepicker.single .ranges, .daterangepicker.single .calendar {
	float: none;
}

.daterangepicker .calendar {
	display: none;
	max-width: 270px;
	margin: 4px;
}

.daterangepicker.show-calendar .calendar {
	display: block;
}

.daterangepicker .calendar.single .calendar-table {
	border: none;
}

/* Calendars */
.daterangepicker .calendar th, .daterangepicker .calendar td {
	white-space: nowrap;
	text-align: center;
	min-width: 32px;
	border: 1px solid #E8E8E8;
}

.daterangepicker .calendar-table {
	border: 1px solid #ddd;
	padding: 4px;
	border-radius: 4px;
	background: #fff;
}

.daterangepicker table {
	width: 100%;
	margin: 0;
	border: none;
}

.daterangepicker td,
.daterangepicker th {
	text-align: center;
	width: 20px;
	height: 20px;
	white-space: nowrap;
	padding: 2px;
}
.daterangepicker td,
.daterangepicker th.available {
	cursor: pointer;
}
.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
	color: #999;
	background: #fff;
	border-color: #E8E8E8;
}
.daterangepicker td.off.in-range {
    background-color: #F4FAFC;
}
.daterangepicker td.off.active {
	background-color: #AAD8EA;
	color: #FFF;
}

.daterangepicker td.disabled,
.daterangepicker option.disabled {
	color: #999;
	cursor: not-allowed;
	text-decoration: line-through;
}

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
	background: #EAECF1;
}

.daterangepicker td.in-range {
	background: #ebf4f8;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
	background-color: #358AAB;
	border-color: #358AAB;
	color: #fff;
}

.daterangepicker td.week, .daterangepicker th.week {
	font-size: 80%;
	color: #ccc;
}

.daterangepicker select.monthselect, .daterangepicker select.yearselect {
	font-size: 12px;
	padding: 1px;
	height: auto;
	margin: 0;
	cursor: default;
}

.daterangepicker select.yearselect,
.daterangepicker select.monthselect,
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
	margin-bottom: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	width: 62px;
	background-color: #FFF;
	outline: none;
	border-radius: 5px;
	font-size: 1.4rem;
	line-height: 1.2;
	padding: 5px 23px 5px 8px;
	border: 1px solid #CED2DC;
	background-image: url(../images/form-icons.svg),
	url(../images/form-icons.svg);
	background-repeat: no-repeat, no-repeat;
	background-position: top -355px right -119px, top -607px right -115px;
}

.daterangepicker select.monthselect {
	margin-right: 2%;
	width: 40%;
}

.daterangepicker select.yearselect {
	width: 44%;
}

.daterangepicker th.month {
	width: auto;
}

/* Previous/Next buttons */
.daterangepicker th.prev .daterangepicker-prev-icon,
.daterangepicker th.next .daterangepicker-next-icon {
	width: 11px;
	height: 17px;
	background-image: url(../images/form-icons.svg);
	background-repeat: no-repeat;
	display: block;
	margin: 0 auto;
}
.daterangepicker th.prev:hover,
.daterangepicker th.next:hover {
	background: none;
	opacity: 0.8;
}
.daterangepicker th.prev .daterangepicker-prev-icon {
	background-position: top -820px left 0px;
}
.daterangepicker th.next .daterangepicker-next-icon {
	background-position: top -880px left 1px;
}

.daterangepicker th.prev,
.daterangepicker th.next,
.daterangepicker th.month,
.daterangepicker .calendar-table thead tr:first-child th {
	border: none;
	background: none;
}

.daterangepicker .calendar-table thead tr:nth-child(2) th {
	background-color: #F2F2F5;
}

/* Text Input Above Each Calendar */
.daterangepicker .input-mini {
	border: 1px solid #ccc;
	border-radius: 4px;
	color: #555;
	display: block;
	height: 30px;
	line-height: 30px;
	vertical-align: middle;
	margin: 0 0 5px 0;
	padding: 0 6px 0 28px;
	width: 100%;
}

.daterangepicker .input-mini.active {
	border: 1px solid #358AAB;
	background-color: rgb(255, 255, 238);
}

.daterangepicker .daterangepicker_input i {
	position: absolute;
	left: 8px;
	top: 7px;
	color: #596474;
}

.daterangepicker .daterangepicker_input {
	position: relative;
}

/* Time Picker */
.daterangepicker .calendar-time {
	text-align: center;
	margin: 5px auto;
	line-height: 30px;
	position: relative;
}

.daterangepicker .calendar-time select.disabled {
	color: #ccc;
	cursor: not-allowed;
}

/* Predefined Ranges */
.daterangepicker .ranges {
	font-size: 11px;
	float: none;
	margin: 4px;
	text-align: left;
}

.daterangepicker .applyBtn {
	background-color: #35ab75;
	color: #FFF;
}
.daterangepicker .applyBtn:hover,
.daterangepicker .applyBtn:focus {
	background-color: #2C9867;
	color: #FFF;
}

.daterangepicker .cancelBtn {
	background-color: #D0D3DA;
	color: #24292F;
}
.daterangepicker .cancelBtn:hover,
.daterangepicker .cancelBtn:focus {
	background-color: #BCBFC5;
	color: #24292f;
}

.daterangepicker .ranges ul {
	list-style: none;
	margin: 0 auto 12px;
	padding: 0;
	width: 100%;
}

.daterangepicker .ranges li {
	font-size: 1.3rem;
	background: #EAECF1;
	border: 1px solid #f5f5f5;
	color: #24292F;
	padding: 4px 12px 3px;
	margin-bottom: 7px;
	border-radius: 5px;
	cursor: pointer;
	line-height: 1.2;
}

.daterangepicker .ranges li.active, .daterangepicker .ranges li:hover {
	background: #358AAB;
	border: 1px solid #358AAB;
	color: #fff;
}

/*  Larger Screen Styling */
@media (min-width: 564px) {
	.daterangepicker{
		width: auto;
	}

	.daterangepicker .ranges ul {
		width: 160px;
	}

	.daterangepicker.single .ranges ul {
		width: 100%;
	}

	.daterangepicker .calendar.left .calendar-table {
		border-right: none;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	.daterangepicker .calendar.right .calendar-table {
		border-left: none;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	.daterangepicker .calendar.left {
		clear: left;
		margin-right: 0;
	}

	.daterangepicker.single .calendar.left {
		clear: none;
	}

	.daterangepicker.single .ranges,
	.daterangepicker.single .calendar{
		float:left;
	}

	.daterangepicker .calendar.right {
		margin-left: 0;
	}

	.daterangepicker .left .daterangepicker_input {
		padding-right: 12px;
	}

	.daterangepicker .calendar.left .calendar-table {
		padding-right: 12px;
	}

	.daterangepicker .ranges,
	.daterangepicker .calendar {
		float: left;
	}

}

@media (min-width: 730px) {
	.daterangepicker .ranges {
		width: auto;
		float: left;
	}

	.daterangepicker .calendar.left {
		clear: none;
	}
}

