@use "../../00-settings" as *;

.widget-RelatedLinks {
	margin-bottom: 25px;
	font-size: 1.4rem;
	text-transform: uppercase;
    @include font-set('accent-bold');
}
.widget-RelatedLinks ul {
	list-style: none;
	padding: 0;
	margin:  0;
}
.widget-RelatedLinks li {
	line-height: 1.3;
	margin-bottom: 8px;
}
.widget-RelatedLinks li.depth1:last-child {
	margin-bottom: 0;
}
.widget-RelatedLinks a {
	text-decoration: none;
	display: block;
	color: lighten($color-00-pale, 37%);
	padding: 3px 0 0;
}
.widget-RelatedLinks a:hover {
	color: #5c6e96;
}
.widget-RelatedLinks li.on > a,
.widget-RelatedLinks li.on > a:hover {
	color: $color-00;
	padding-left: 7px;
	border-left: 3px solid #00aae9;
}
.widget-RelatedLinks li.on > ul.depth2 {
	margin-top: 8px;
}
.widget-RelatedLinks ul.depth2 {
	padding-left: 20px;
}
.widget-RelatedLinks li.depth2 {
	margin-bottom: 4px;
}
