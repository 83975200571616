
@use "../../00-settings" as *;
@use "../../01-base/typography/reverse-text-mixins" as *;
@use "../../05-utilities/helpers/utility-mixins" as *;

/* ---- Pre footer ---- */
.section--pre-footer .icon-circle {
	text-align: center;

	@include screen($bp559, 'max') {
		line-height: 1.6;
	}
}

.section--pre-footer .icon-circle-list,
.section--pre-footer .icon-circle {
	@include font-set('alternative');
	text-align: center;
	margin-bottom: 15px;

	@include screen($bp768) {
		margin-bottom: 30px;
	}

	@include screen($bp1200) {
		margin-bottom: 35px;
	}
}

.section--pre-footer .linklist-list {
	font-size: var(--large-font-size);
}

.section--pre-footer .icon-email-address:before {
	display: inline-grid;
	place-items: center;
}

.footer-box__text {
	text-align: center;
	@include font-set('alternative');
	line-height: 1.3;
	margin-bottom: 12px;
	font-size: 1.8rem;

	@include screen($bp768) {
		font-size: 2rem;
		margin-bottom: 20px;
	}

	@include screen($bp992) {
		font-size: 2.4rem;
	}

	@include screen($bp1200) {
		font-size: 3rem;
		margin: 0 0 25px;
	}
}

/* BP 3 */
@media screen and (max-width: 760px) {
	.pre-footer__news .field-element {
		margin-bottom: 0;
	}
}

/* ---- Footer ---- */
.section--footer {
	padding-top: 22px;
	padding-bottom: 24px;
	font-size: 1.6rem;
}
.section--footer:before {
	content: " ";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-image: url(../images/texture-dots.svg);
	opacity: 0.1;
}
.section-footer__inner {
	position: relative;
	z-index: 1;
}
.footer-text {
	margin: 0;
	float: left;
}
.footer-list {
	float: right;
	list-style: none;
	margin: 0;
	padding: 0;
}
.footer-list__item {
	display: inline;
}
.section--footer,
.section--footer a {
	color: #687388;
}
.section--footer a {
	text-decoration: none;
}
.section--footer a:hover,
.section--footer a:focus {
	text-decoration: underline;
	color: #fff;
}
.section--footer .footer-list__item:after {
	content: " | ";
}
.section--footer .footer-list__item:last-child:after {
	content: none;
}

/* BP 1 */
@media screen and (max-width: 1240px) {
	.section--footer {
		padding-top: 18px;
		padding-bottom: 19px;
		font-size: 1.5rem;
	}
}

/* BP 3 */
@media screen and (max-width: 760px) {
	.footer-text {
		float: none;
	}
	.footer-list {
		float: none;
	}
	.section--footer {
		font-size: 1.3rem;
		padding-top: 12px;
		padding-bottom: 12px;
	}
}
