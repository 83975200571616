
@use "../../00-settings" as *;

* {
    margin: 0;
}

html,
body {
    background: $color-white;
    height: 100%;
    scroll-behavior: smooth;
}

html {
    font-size: 62.5%;
    // scroll-padding-top: calc(var(--header-height) + $spacing*4);
}

body {
    line-height: var(--body-line-height);
    font-size: $fs-body;
    color: $color-body-font;
    font-family: $primary-font;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

