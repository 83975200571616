@use '../00-settings/colors' as *;


/* ------------------------------------------------------
**** Animations
------------------------------------------------------ */
/* ---- Mobile menu button ---- */
@-webkit-keyframes mobileMenuBtnTopClose {
	0% {
		-ms-transform: translate(0, 9px) rotate(45deg);
		-webkit-transform: translate(0, 9px) rotate(45deg);
		        transform: translate(0, 9px) rotate(45deg)
	}
	50%, 70% {
		-ms-transform: translate(0, 9px);
		-webkit-transform: translate(0, 9px);
		        transform: translate(0, 9px);
	}
	100% {
		-ms-transform: translate(0, 0);
		-webkit-transform: translate(0, 0);
		        transform: translate(0, 0);
	}
}
@keyframes mobileMenuBtnTopClose {
	0% {
		-ms-transform: translate(0, 9px) rotate(45deg);
		-webkit-transform: translate(0, 9px) rotate(45deg);
		        transform: translate(0, 9px) rotate(45deg)
	}
	50%, 70% {
		-ms-transform: translate(0, 9px);
		-webkit-transform: translate(0, 9px);
		        transform: translate(0, 9px);
	}
	100% {
		-ms-transform: translate(0, 0);
		-webkit-transform: translate(0, 0);
		        transform: translate(0, 0);
	}
}
@-webkit-keyframes mobileMenuBtnTopOpen {
	0% {
		-ms-transform: translate(0, 0);
		-webkit-transform: translate(0, 0);
		        transform: translate(0, 0);
	}
	50%, 70% {
		-ms-transform: translate(0, 9px);
		-webkit-transform: translate(0, 9px);
		        transform: translate(0, 9px);
	}
	100% {
		-ms-transform: translate(0, 9px) rotate(45deg);
		-webkit-transform: translate(0, 9px) rotate(45deg);
		        transform: translate(0, 9px) rotate(45deg);
	}
}
@keyframes mobileMenuBtnTopOpen {
	0% {
		-ms-transform: translate(0, 0);
		-webkit-transform: translate(0, 0);
		        transform: translate(0, 0);
	}
	50%, 70% {
		-ms-transform: translate(0, 9px);
		-webkit-transform: translate(0, 9px);
		        transform: translate(0, 9px);
	}
	100% {
		-ms-transform: translate(0, 9px) rotate(45deg);
		-webkit-transform: translate(0, 9px) rotate(45deg);
		        transform: translate(0, 9px) rotate(45deg);
	}
}
@-webkit-keyframes mobileMenuBtnBottomClose {
	0% {
		-ms-transform: translate(0, -9px) rotate(-45deg);
		-webkit-transform: translate(0, -9px) rotate(-45deg);
		        transform: translate(0, -9px) rotate(-45deg);
	}
	50%, 70% {
		-ms-transform: translate(0, -9px);
		-webkit-transform: translate(0, -9px);
		        transform: translate(0, -9px);
	}
	100% {
		-ms-transform: translate(0, 0);
		-webkit-transform: translate(0, 0);
		        transform: translate(0, 0);
	}
}
@keyframes mobileMenuBtnBottomClose {
	0% {
		-ms-transform: translate(0, -9px) rotate(-45deg);
		-webkit-transform: translate(0, -9px) rotate(-45deg);
		        transform: translate(0, -9px) rotate(-45deg);
	}
	50%, 70% {
		-ms-transform: translate(0, -9px);
		-webkit-transform: translate(0, -9px);
		        transform: translate(0, -9px);
	}
	100% {
		-ms-transform: translate(0, 0);
		-webkit-transform: translate(0, 0);
		        transform: translate(0, 0);
	}
}
@-webkit-keyframes mobileMenuBtnBottomOpen {
	0% {
		-ms-transform: translate(0, 0);
		-webkit-transform: translate(0, 0);
		        transform: translate(0, 0);
	}
	50%, 70% {
		-ms-transform: translate(0, -9px);
		-webkit-transform: translate(0, -9px);
		        transform: translate(0, -9px);
	}
	100% {
		-ms-transform: translate(0, -9px) rotate(-45deg);
		-webkit-transform: translate(0, -9px) rotate(-45deg);
		        transform: translate(0, -9px) rotate(-45deg);
	}
}
@keyframes mobileMenuBtnBottomOpen {
	0% {
		-ms-transform: translate(0, 0);
		-webkit-transform: translate(0, 0);
		        transform: translate(0, 0);
	}
	50%, 70% {
		-ms-transform: translate(0, -9px);
		-webkit-transform: translate(0, -9px);
		        transform: translate(0, -9px);
	}
	100% {
		-ms-transform: translate(0, -9px) rotate(-45deg);
		-webkit-transform: translate(0, -9px) rotate(-45deg);
		        transform: translate(0, -9px) rotate(-45deg);
	}
}
@-webkit-keyframes mobileMenuBtnMiddleClose {
	0%, 40% {
		opacity: 0;
	}
	50%, 100% {
		opacity: 1;
	}
}
@keyframes mobileMenuBtnMiddleClose {
	0%, 40% {
		opacity: 0;
	}
	50%, 100% {
		opacity: 1;
	}
}
@-webkit-keyframes mobileMenuBtnMiddleOpen {
	0%, 40% {
		opacity: 1;
	}
	50%, 100% {
		opacity: 0;
	}
}
@keyframes mobileMenuBtnMiddleOpen {
	0%, 40% {
		opacity: 1;
	}
	50%, 100% {
		opacity: 0;
	}
}



/* Fade in */
@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* ---- Content Entry ---- */
/* Fade in down */
@keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translateY(-25px);
                transform: translateY(-25px);
    }
}

/* Fade in up */
@keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translateY(25px);
                transform: translateY(25px);
    }
    to {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

/* Fade in up margin */
@keyframes fadeInUpMargin {
	from {
		opacity: 0;
		margin-bottom: -20px;
	}
	to {
		opacity: 1;
		margin-bottom: 0;
	}
}


/* Grow in fade */
@keyframes growInFade {
	from {
		opacity: 0;
		-webkit-transform: scale(0.9);
		        transform: scale(0.9);
	}
	to {
		opacity: 1;
		-webkit-transform: none;
		        transform: none;
	}
}

/* ---- Highlight pulse ---- */
@keyframes highlightPulse {
	40% {
		-webkit-transform: scale(1.25);
		        transform: scale(1.25);
	}
}

/* ---- Highlight pulse with outline ---- */
@keyframes highlightPulseOutline {
	0% {
        outline: 3px solid $color-utility-selection;
        outline-offset: 4px;
        -webkit-transform: scale(1);
		        transform: scale(1);
    }
	13% {
		-webkit-transform: scale(1.25);
		        transform: scale(1.25);
	}
    33% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    75% {
        outline: 3px solid $color-utility-selection;
        outline-offset: 4px;
    }
    100% {
        outline: 3px solid transparent;
        outline-offset: 4px;
    }
}
