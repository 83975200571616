
@use "../../../00-settings" as *;

/* ---- Content tables ---- */
.table--content-standard {
    width: 100%;
    margin: 2rem 0;
}

.table--content-standard caption {
    text-align: left;
    padding-bottom: 13px;
}

.table--content-standard th,
.table--content-standard thead td {
    font-weight: bold;
    background-color: $color-grey-01;
}

.table--content-standard td {
    background-color: $color-white;
}

.table--content-standard th,
.table--content-standard td {
    border: 1px solid $color-grey-03;
    padding: 12px 15px;
    text-align: left;
}

/* Small */
.table--content-standard.table--content-small {
	font-size: 1.3rem;
}
.table--content-standard.table--content-small th,
.table--content-standard.table--content-small td {
	padding: 5px 10px;
}
.table--content-standard.table--content-small th,
.table--content-standard.table--content-small thead td {
	font-size: 1.4rem;
}

