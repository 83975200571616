
@use "../../00-settings" as *;

/* ---- Unpadded row ---- */
.row-gap--0 {
    --gutter-width: 0rem;
    --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
    --half-gutter-width: calc((var(--gutter-width) * 0.5));
}

/* ---- small padded row ---- */
.row-gap--small {
    --gutter-width: 3.66666666vw;
    --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
    --half-gutter-width: calc((var(--gutter-width) * 0.5));
}

@media screen and (min-width: 48em) { /* 768 */
    .row-gap--small {
        --gutter-width: 30px;
        --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
        --half-gutter-width: calc((var(--gutter-width) * 0.5));
    }
}

/* ---- Medium padded row ---- */
// half column width gap
.row-gap--medium {
    --gutter-width: 3.66666666vw;
    --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
    --half-gutter-width: calc((var(--gutter-width) * 0.5));


    @include screen($bp768) {
        --gutter-width: 3.83333334vw;
        --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
        --half-gutter-width: calc((var(--gutter-width) * 0.5));
    }

    @include screen($bp1600) {
        --gutter-width: 60px;
        --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
        --half-gutter-width: calc((var(--gutter-width) * 0.5));
    }
}

/* ---- Large padded row ---- */
// 2/3 column width gap
.row-gap--large {
    --gutter-width: 4.8888888vw;
    --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
    --half-gutter-width: calc((var(--gutter-width) * 0.5));


    @include screen($bp768) {
        --gutter-width: 5.11111vw;
        --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
        --half-gutter-width: calc((var(--gutter-width) * 0.5));

    }

    @include screen($bp1600) {
        --gutter-width: 80px;
        --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
        --half-gutter-width: calc((var(--gutter-width) * 0.5));
    }
}

.row-gap--column {
    // column width gap
    // container width: 88vw
    // 88vw/12 = 7.3333333vw
    --gutter-width: 7.3333333vw;
    --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
    --half-gutter-width: calc((var(--gutter-width) * 0.5));

    @include screen($bp768) {
        // container width: 92vw
        // 92vw/12 = 7.66666667vw
        --gutter-width: 7.66666667vw;
        --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
        --half-gutter-width: calc((var(--gutter-width) * 0.5));
    }

    @include screen($bp1600) {
        // container width: 1440px
        // 1440px/12 = 120
        --gutter-width: 120px;
        --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
        --half-gutter-width: calc((var(--gutter-width) * 0.5));
    }

}
