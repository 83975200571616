
@use "../../00-settings" as *;

/* ---- Events ---- */
.event-list__item__date {
	font-family: $accent-font;
	text-transform: uppercase;
}

.event-list__item__date-day {
	background-color: $color-00-dark;
	padding: 6px 10px 3px;
	line-height: 3.4rem;
	font-size: 3.4rem;
    color: $color-white;
}
.event-list__item__date-month {
	background-color: $color-01;
	padding: 6px 10px 3px;
    color: $color-white;
}
.event-list__item__date-year {
	background-color: $color-01;
	padding: 6px 10px 3px;
    color: $color-white;
}

