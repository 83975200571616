
@use "../../00-settings" as *;
@use "../../02-components/icons/_icomoon" as *;

:root {
    --button-border-width: 0;
    --button-border-radius: 0;
    --button-padding-vertical: 0.75em;
    --button-padding-horizontal: 1em;
    --button-line-height: 1;
    --button-font-size: var(--milli-font-size);
}

@include screen($bp768) {
    :root {
        --button-padding-vertical: 1em;
        --button-padding-horizontal: 1.25em;
        --button-font-size: var(--body-font-size);
        --button-line-height: 1.2;
    }
}

@mixin button-strip {
    vertical-align: middle;
    white-space: normal;
    cursor: pointer;
    opacity: 1;
    text-decoration: none;
    font: inherit;
}

@mixin button-base($size: 'default') {
    @include button-strip;
    text-align: center;
    display: inline-grid;
    grid-auto-flow: column;
    column-gap: 0.5em;
    justify-content: center;
    align-items: center;
    border-radius: var(--button-border-radius);
    position: relative;
    margin: 0;
    transition:
        background-color $link-transition,
        border-color $link-transition,
        color $link-transition;
    @include button-size($size);
    @include button-typography;

    &:after {
        @include icomoon-icon;
        content: "\e908";
    }
}

@mixin button-typography() {
    line-height: var(--button-line-height);
    text-transform: uppercase;
    font-size: var(--button-font-size);
    @include font-set('accent-bold');
}

@mixin button-disabled() {
    border: none;
	background-image: none;
	opacity: 0.4;
	cursor: default;
    box-shadow: none;
}

@mixin button($background: $color-06, $colour: $color-white, $icon: $color-00) {
    @include button-base;
    color: $colour;
    background: $background;
    border: var(--button-border-width) solid $background;
    transition:
        background-color $link-transition,
        border-color $link-transition,
        color $link-transition;

    svg {
        height: 1em;
        width: 1em;

        [stroke*="#"] {
            stroke: currentColor;
        }

        [fill*="#"] {
            fill: currentColor;
        }
    }

    &:before,
    &:after {
        color: $icon;
    }

    &:hover,
    &:focus,
    &:active {
        background-color: darken($background, 10%);
        border-color: darken($background, 10%);
        color: $colour;
    }

    &:focus {
        outline: 1px auto $color-utility-neutral;
    }
}

/* --------------------------------
Text Link
----------------------------------- */

@mixin text-link($colour: $color-06, $padding: false) {
    @include button-typography;
    color: $colour;
    background: transparent;
    transition:
        transform $link-transition,
        background-color $link-transition,
        color $link-transition;

    @if $padding {
        @include button-height-regular;
    }

    &:after {
        content: " >";
    }

    svg {
        height: 1em;
        width: 1em;

        @include svg-contents {
            fill: $colour;
        }
    }

    &:hover,
    &:focus,
    &:active {
        background-color: transparent;
        color: $colour;

        svg {
            @include svg-contents {
                fill: $colour;
            }
        }
    }
}

/* --------------------------------
Sizes
----------------------------------- */

@mixin button-size($size: 'default') {
    padding-block: calc(var(--button-padding-vertical) + 0.2em) var(--button-padding-vertical);
    padding-left: var(--button-padding-horizontal);
    padding-right: var(--button-padding-icon);

    @if $size == 'small' {
        @include screen($bp768) {
            --button-font-size: var(--milli-font-size);
            --button-padding-vertical: 0.75em;
            --button-padding-horizontal: 1em;
            --button-padding-icon: 2.2em;
            --button-line-height: 1;

            --button-icon-size: 1.6em;
            --button-icon-top: 0.38em;
            --button-icon-edge-align: 0.38em;
            --button-padding-icon: 2.38em;
        }
    }

    @if $size == 'large' {
        @include screen($bp1200) {
            --button-font-size: 1.9rem;
            --button-padding-vertical: 1.15em;
            --button-padding-horizontal: 1.35em;
            --button-padding-icon: 2.95em;

            --button-icon-size: 1.74em;
            --button-icon-top: 0.74em;
            --button-icon-edge-align: 1em;
            --button-padding-icon: 2.95em;
        }
    }
}
