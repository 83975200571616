// This field is quite odd and I'm not sure what its use is yet - I kept the styles basic
:root {
    --multiple-select-item-vertical-padding: 0.4em;
}

.field--multiple_select :where(select) {
    padding: calc(var(--field-padding-vertical) - var(--multiple-select-item-vertical-padding)) 0;
    background-image: none;
}

.field--multiple_select :where(option) {
    padding: var(--multiple-select-item-vertical-padding) var(--field-padding-horizontal);
}
