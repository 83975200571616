@use "../../../00-settings" as *;

/* -- Field element attach --- */
.field-element-attach {
	position: relative;
}
.field-element-attach__button {
	position: absolute;
	top: 0;
	right: 0;
}
.field-element-attach .textbox,
.field-element-attach select {
	padding-right: 95px;
}

@include screen($bp1200) {
	/* Large */
	.field-element-attach .field-element--large .textbox,
	.field-element-attach .field-element--large select {
		padding-right: 120px;
	}
}

/* BP 3 */
@media screen and (max-width: 760px) {
	.field-element-attach {
		.textbox,
		select,
		.field-element--regular .textbox,
		.field-element--regular select,
		.field-element--large .textbox,
		.field-element--large select {
			padding-right: 72px;
		}
	}
}
