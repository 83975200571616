@use '../../00-settings/colors' as *;

/* ---- Search pagination ---- */
.search-paginate {
	padding: 10px 0;
	text-align: center;
}

.search-paginate .page-prev {
    margin: 0 8px 0 0;
}

.search-paginate .page {
    padding: 2px 9px;
    margin: 0 3px;
    background: $color-grey-02;
    border: 1px $color-utility-alert-subtle solid;
    text-decoration: none;
}

.search-paginate .page.on {
    color: $color-black;
    border-color: $color-black;
}

.search-paginate .page-next {
    margin: 0 0 0 8px;
}

