@use "../../00-settings" as *;

/* ---- Landing page styles eg. Crowdstrike pg ---- */

.landing {
	overflow-x: hidden;
}

.landing .section--inner-banner__inner {
	padding-top: 80px;
}

.landing .container {
	position: relative;
	z-index: 1;
}

.landing .widget.widget-RichText {
	padding: 30px 0;
	position: relative;
}

.landing .widget.widget-RichText:before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: -60%;
	right: -60%;
	z-index: -2;
}

.landing .widget.widget-RichText:nth-child(1) {
	color: #FFF;
}

.landing .widget.widget-RichText:nth-child(1):before {
	background-color: $color-01;
}

.landing .widget.widget-RichText:nth-child(1):after {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: -60%;
	right: -60%;
	background-image: url(../images/texture-dots.svg);
	opacity: 0.1;
	z-index: -1;
}

.landing .widget.widget-RichText:nth-child(2n+3):before {
	background-color: rgb(240,240,240);
}

@media screen and (min-width: 1241px) {
	.landing .widget.widget-RichText {
		padding: 80px 0;
		font-size: 2rem;
	}

	.landing .widget.widget-RichText h4 {
		font-size: 2.2rem;
	}
	.landing .widget.widget-RichText:before {
		left: calc(-50% - 570px);
		right: calc(-50% - 570px);
	}
	.landing .widget.widget-RichText:nth-child(1):after {
		left: calc(-50% - 570px);
		right: calc(-50% - 570px);
	}
}



/* ---- Line title ---- */

.landing .widget-RichText {
	text-align: center;
}

.landing .widget-RichText h2:first-child {
	position: relative;
	color: $color-00;
	text-transform: uppercase;
	font-weight: 100;
	line-height: 1.2;
	display: inline-block;
	margin: 0 auto 32px;
	font-family: 'DIN Next W01', sans-serif;
}
.landing .widget-RichText h2:first-child:after {
	content: " ";
	display: block;
	height: 18px;
	margin-top: 18px;
}

.landing .widget-RichText:first-child h2:first-child {
	color: #fff;
	font-weight: bold;
}


/* Dark blue */
.landing .widget-RichText h2:first-child:after {
	background-image: url(../images/texture-lines-blue.png);
}
