
@use "../../00-settings" as *;

#wrap {
    margin-top: var(--header-height);
    position: relative;
    overflow-x: hidden;

    @include screen($bp2400) {
        max-width: $max-page-width;
        margin-inline: auto;
    }
}
