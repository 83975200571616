@use '../../00-settings/breakpoints' as *;

:root {
	--button-icon-size: 1.6em;
    --button-icon-top: 0.38em;
    --button-icon-edge-align: 0.38em;
    --button-padding-icon: 2.38em;
}

@include screen($bp768) {
	:root {
		--button-icon-size: 1.8em;
		// --button-icon-top: 0.625em;
		--button-icon-edge-align: 0.5625em;
		--button-padding-icon: 2.875em;
	}
}

/* Buttons with icons */
.button:after,
.button.icon-before:before,
.button.icon-after:after {
	font-size: var(--button-icon-size);
	position: absolute;
	top: var(--button-icon-top);
}

.button.icon-before:before {
	left: var(--button-icon-edge-align);
}

.button.icon-before {
	padding-left: var(--button-padding-icon);
	padding-right: var(--button-padding-horizontal);
}

.button:after,
.button.icon-after:after {
	right: var(--button-icon-edge-align);
}

.button.icon-before:after {
	content: none;
}

/* No icons */
.button.button-no-icon:after,
.button.button-no-icon:before {
	content: none;
}

.button.button-no-icon {
	padding-right: var(--button-padding-horizontal);
}
