/* ---- Range ---- */
.field-element--range .field-input .textbox {
	-webkit-appearance: slider-horizontal;
	background: none;
	padding: 0;
	border-radius: 0;
	border: none;
	background: none !important;
}

input[type=range]::-moz-focus-outer {
    border: 0;
}
