@use "../../../00-settings" as *;
@use "../../../02-components/forms/_form-mixins.scss" as *;

:where(label),
.field-label,
.fieldset__legend {
    display: block;
    @include field-label;
}

.field-instructions {
    font-size: var(--centi-font-size);
    margin-top: 0.25em;
}

/* Instead of h3s */
.label.heading {
	margin: 30px 0px 20px;
	padding: 8px 8px 8px 0px;
	border-bottom: 1px solid $color-grey-02;
}

/* Hidden labels */
.field-element--hidden-label .field-label,
.field-element--hidden-label .fieldset__legend {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/* ---- Field helpers ---- */
.field-helper {
	font-weight: 400;
	font-size: 1.3rem;
	margin-bottom: 1em;
}

.field-label .field-helper {
	margin-top: 0.3em;
	margin-bottom: 0;
}
