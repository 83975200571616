
@use "../../00-settings" as *;

:root {
    --field-border-width: 0;
    --field-border-colour: #{$color-grey-02};
    --field-border: var(--field-border-width) solid var(--field-border-colour);
    --field-background: #{$color-grey-01};
    --field-text-color: #{$color-body-font};
    --field-padding-vertical: var(--button-padding-vertical);
    --field-padding-horizontal: var(--button-padding-horizontal);
    --field-line-height: calc(var(--button-line-height) * var(--button-font-size));
    --field-radius: 2px;
    --field-vertical-whitespace: 13px;
    --fieldset-input-diameter: calc(var(--body-font-size) * 1.25);
    --fieldset-input-diameter-small: var(--centi-font-size);
    --field-font-size: 1.4rem;
}

@include screen($bp768) {
    :root {
        --field-padding-vertical: var(--button-padding-vertical);
        --field-padding-horizontal: var(--button-padding-horizontal);
        --field-font-size: 1.5rem;
        --field-line-height: 1.2;
        --field-vertical-whitespace: 21px;
    }
}

@mixin form-textbox-styles {
    border: var(--field-border);
    background-color: var(--field-background);
    color: var(--field-text-color);
    border-radius: var(--field-radius);
    // If you set a height here,
    // make sure you unset the height on <textarea>
    // see the forms/fields/_text.scss partial
}

@mixin form-textbox($size: 'default') {
    -webkit-appearance: none;
    display: block;
    outline: none;
    width: 100%;
    line-height: var(--field-line-height);
    padding: var(--field-padding-vertical) var(--field-padding-horizontal);

    @if $size == 'small' {
        --field-font-size: var(--centi-font-size);
        --field-padding-vertical: 0.57em;
        --field-padding-horizontal: 1.1em;
    }

    @if $size == 'large' {
        --field-padding-vertical: 0.8em;

        @include screen($bp1200) {
            --field-font-size: 1.8rem;
            --field-padding-vertical: 1.167em;
            --field-padding-horizontal: 1.389em;
        }
    }
}

@mixin field-select($size: 'default') {
    @include form-textbox($size);
    @include form-textbox-styles;
    -moz-appearance: none;
    background-image:
        url(#{$file-path}icon-system/icon_form_arrow-down.svg),
        url(#{$file-path}icon-system/form_bg-fill.svg);
    background-repeat: no-repeat, repeat-Y;
    background-position: center right $form-spacing, center right;
    background-size: $form-spacing*3, $form-spacing*5;
    padding-right: $form-spacing*6.5;
}

@mixin field-label {
    font-weight: $fw-semibold;
    font-size: var(--body-font-size);
    margin-bottom: $form-spacing; // used to be 10px
    line-height: 1.2;
    font-family: $alternative-font-face-02;
}

@mixin field-message {
    margin: 0 0 $spacing;
    padding: $spacing $spacing*2;
    text-align: left;

    a {
        color: inherit;
    }
}
