@use "../../00-settings" as *;

/* Sidebar */
.sidebar h2 {
	text-transform: uppercase;
	color: $color-00;
	font-size: 2rem;
    @include font-set('accent-bold');
}

/* ---- Catch all ---- */
.sidebar .widget {
	margin-bottom: 25px;
}

.sidebar .widget:last-child {
	margin-bottom: 0;
}
