@use '../fields' as *;

/* -------------------------------
Fieldsets Mixins
---------------------------------- */
@mixin fieldsetLabel {
    position: relative;
    cursor: pointer;
    display: grid;
    grid-auto-flow: column;
    gap: 8px;
    justify-content: start;
    align-items: center;
    font-weight: $fw-normal;
}

@mixin checkbox($small: false) {
    @include form-textbox-styles;
    display: block;
    width: var(--fieldset-input-diameter);
    height: var(--fieldset-input-diameter);

    @if $small {
        width: var(--fieldset-input-diameter-small);
        height: var(--fieldset-input-diameter-small);
    }
}

@mixin checkboxChecked($small: false) {
    @include checkbox($small);
    position: absolute;
    top: 0;
    background:
        $color-utility-neutral
        no-repeat
        center/90%
        url(../assets/icon-system/icon_form_tick-thick-white.svg);
    border-color: $color-utility-neutral;
    z-index: 1;
}

@mixin radio($small: false) {
    @include form-textbox-styles;
    display: block;
    border-radius: 100%;
    width: var(--fieldset-input-diameter);
    height: var(--fieldset-input-diameter);

    @if $small {
        width: var(--fieldset-input-diameter-small);
        height: var(--fieldset-input-diameter-small);
    }
}

@mixin radioChecked($small: false) {
    position: absolute;
    left: calc(var(--fieldset-input-diameter)/4);
    top: 50%;
    transform: translateY(-50%);
    width: calc(var(--fieldset-input-diameter)/2);
    height: calc(var(--fieldset-input-diameter)/2);
    border-radius: 100%;
    background: $color-white;
    z-index: 1;

    @if $small {
        width: calc(var(--fieldset-input-diameter-small)/2);
        height: calc(var(--fieldset-input-diameter-small)/2);
    }
}

// I believe the newer styles already have this covered
// leaving it here for prosperity in case there's rampant wonkiness

/* ---- Fieldset ---- */
// .field-element fieldset {
// 	border: none;
// 	padding: 0;
// 	margin: 0;
// }


// /* ---- Checkboxes and radio buttons ---- */
// .field-element--checkbox,
// .field-element--radio {
// 	font-size: 1.6rem;
// }

// .field-element input[type="checkbox"],
// .field-element input[type="radio"] {
// 	border: 0;
// 	clip: rect(0 0 0 0);
// 	height: 1px;
// 	margin: -1px;
// 	overflow: hidden;
// 	padding: 0;
// 	position: absolute;
// 	width: 1px;
// }

// .field-element input[type="checkbox"] + label,
// .field-element input[type="radio"] + label {
// 	padding-left: 34px;
// 	position: relative;
// 	cursor: pointer;
// 	display: inline-block;
// }

// .field-element input[type="checkbox"] + label:before,
// .field-element input[type="radio"] + label:before {
// 	content: " ";
// 	display: inline-block;
// 	width: 20px;
// 	height: 20px;
// 	margin-right: 13px;
// 	position: relative;
// 	top: 4px;
// 	background-color: #EEF0F3;
// 	margin-left: -34px;
// 	border-radius: 1px;
// }

// /* Check and dot */
// .field-element input[type="checkbox"]:checked + label:after,
// .field-element input[type="radio"]:checked + label:after {
// 	content: " ";
// 	position: absolute;
// 	left: 1px;
// 	top: 6px;
// 	font-size: 15px;
// }


/* Without labels */
.field-element--checkbox--no-label input[type="checkbox"] + label,
.field-element--radio--no-label input[type="radio"] + label {
	padding-left: 0;
}

.field-element--checkbox--no-label input[type="checkbox"] + label:before,
.field-element--radio--no-label input[type="radio"] + label:before {
	margin-left: 0;
	margin-right: 0;
}

.field-element--checkbox--no-label input[type="checkbox"]:checked + label:after,
.field-element--radio--no-label input[type="radio"]:checked + label:after {
	left: 1px;
}

.field-element--radio--no-label input[type="radio"]:checked + label:after {
	margin-left: 4px;
}


// I believe the newer styles already have this covered
// leaving it here for prosperity in case there's rampant wonkiness
// /* -- Checkboxes -- */
// /* Check (pseudo element) */
// .field-element input[type="checkbox"]:checked + label:after {
// 	content: " ";
// 	background-image: url(../images/form-icons.svg);
// 	background-repeat: no-repeat, no-repeat;
// 	background-position: top -711px left 1px;
// 	width: 20px;
// 	height: 20px;
// 	margin: 0 0 0 -1px;
// }

// .field-element--checkboxlist--columns .field-element__input-set {
//     -moz-columns: 3;
//     -webkit-columns: 3;
//             columns: 3;
// }

// /* -- Radio Buttons -- */

// /* Radio button (pseudo element) */
// .field-element input[type="radio"] + label:before {
// 	border-radius: 10px;
// }

// /* Radio dot (pseudo element) */
// .field-element input[type="radio"]:checked + label:after {
// 	left: 6px;
// 	top: 12px;
// 	width: 8px;
// 	height: 8px;
// 	border-radius: 4px;
// 	background: #58595B;
// }

/* Large */
.field-element--large input[type="checkbox"] + label:before,
.field-element--large input[type="radio"] + label:before {
	width: 24px;
	height: 24px;
	margin-right: 16px;
	top: 5px;
}

.field-element--large input[type="radio"]:checked + label:after {
	left: 7px;
	top: 12px;
	width: 10px;
	height: 10px;
	border-radius: 5px;
}
.field-element--large input[type="checkbox"]:checked + label:after {
	background-position: top -760px left 1px;
	width: 23px;
	height: 23px;
	margin: -2px 0 0 -1px;
}

