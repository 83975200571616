@use '../../00-settings/colors' as *;

hr {
	-webkit-box-sizing: content-box;
	        box-sizing: content-box;
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid $color-grey-02;
	margin: 2em 0;
	padding: 0;
	clear: right;
}
