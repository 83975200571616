@use "../../../00-settings" as *;
@use "../../../05-utilities/helpers/utility-mixins" as *;


.top-nav-section {
    background-color: $color-00;
    color: $color-white;
    padding: $spacing 0;

    @include screen($bpmobile, 'max') {
        display: none;
    }
}

.top-nav {
    @include list-reset;
    margin: 0;
    display: flex;
    gap: $spacing;
    justify-content: flex-end;

    :where(a) {
        color: rgba($color-white, .5);
        text-decoration-color: transparent;
        text-decoration-thickness: 1px;
        transition: color $link-transition,
                    text-decoration $link-transition;

        &:hover,
        &:focus,
        &:active {
            color: $color-grey-02;
            text-decoration-color: currentColor;
        }
    }
}

.top-nav-item {
    display: grid;
    gap: $spacing;
    grid-auto-flow: column;
    align-items: center;

    svg {
        @include svg-contents {
            fill: currentColor;
        }
    }

    &__link {
        display: grid;
    }

    &::before {
        content: "|";
        font-weight: normal;
        opacity: .25;
    }

    &:first-child {
        &::before {
            content: none;
        }
    }

    &--search {
        svg {
            width: $spacing*2;
            height: $spacing*2;
        }
    }
}
