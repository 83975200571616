@use "../../../00-settings" as *;

/* ---- Placeholders ---- */
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $color-utility-placeholder;
}

::-moz-placeholder { /* Firefox 19+ */
    color: $color-utility-placeholder;
}

:-ms-input-placeholder { /* IE 10+ */
    color: $color-utility-placeholder;
}

:-moz-placeholder { /* Firefox 18- */
    color: $color-utility-placeholder;
}

.field-element--lightgreen {
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $color-08-dark;
    }

    ::-moz-placeholder { /* Firefox 19+ */
        color: $color-08-dark;
    }

    :-ms-input-placeholder { /* IE 10+ */
        color: $color-08-dark;
    }

    :-moz-placeholder { /* Firefox 18- */
        color: $color-08-dark;
    }
}
