
@use "../../00-settings" as *;
@use "../../05-utilities/helpers/utility-mixins" as *;

.related-links {
    margin-bottom: 1.5em;

    &__title {
        line-height: normal;
    }

    ul {
        @include list-reset;
        margin: 0;

        a {
            position: relative;
            display: inline-block;
            text-decoration: none;
            color: inherit;
            transition:
                color 250ms ease-in-out,
                background-color 250ms ease-in-out;
            padding-top: 0.5em;
            padding-bottom: 0.5em;

            &:after {
                content: '';
                position: absolute;
                bottom: 0.5em;
                left: 0;
                right: 0;
                height: 1px;
                background-color: currentColor;
                transition:
                    left 250ms ease-in-out,
                    right 250ms ease-in-out,
                    background-color 250ms ease-in-out;
            }

            &:after {
                left: 50%;
                right: 50%;
            }

            &:hover,
            &:focus,
            &:active {
                color: $color-01;

                &:after {
                    left: 0;
                    right: 0;
                }
            }
        }

        /* Active / on / current */
        li.current-item > a {

            &:before {
                background-color: currentColor;
                transition:
                    left 250ms ease-in-out,
                    right 250ms ease-in-out,
                    background-color 100ms ease-in-out;
            }

            &:after {
                background-color: $color-01;
                transition:
                    left 250ms 100ms ease-in-out,
                    right 250ms 100ms ease-in-out,
                    background-color 250ms ease-in-out;
            }

            &:hover,
            &:focus,
            &:active {
                color: $color-01;

                &:before {
                    background-color: $color-grey-01;
                }
            }
        }
    }

    /* - Depth 1 - */
    .depth1 {
        li {
            display: block;
        }
    }

    /* - Depth 2 - */
    .depth2 {
        margin-left: 0.5em;
    }
}
