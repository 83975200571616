
@use "../../00-settings" as *;

// .float-left,
// .float-center,
// .float-right {
//     margin: 30px auto;
//     display: block;
//     clear: both;
// }


// /* BP Smaller */
// @include screen($bp560) {
//     .float-left {
//         margin: 0 30px 30px 0;
//         float: left;
//     }

//     .float-right {
//         margin: 0 0 30px 30px;
//         float: right;
//     }
// }


/* ---- Floats and positioning ---- */

.left {
	margin: 0 30px 30px 0;
	float: left;
}

.right {
	margin: 0 0 30px 30px;
	float: right;
}

.center {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.block {
	margin: 0 0 30px;
	display: block;
}

@media screen and (max-width: 620px) {
	.left,
	.center,
	.right {
		margin-left: 0;
		margin-right: 0;
		width: 100%;
		display: block;
		float: none;
	}
}
