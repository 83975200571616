
@mixin bleed-left($bg-colour: inherit, $width: 100vw) {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 100%;
        width: $width;
        background-color: $bg-colour;
    }
}

@mixin bleed-right($bg-colour: inherit, $width: 100vw) {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 100%;
        width: $width;
        background-color: $bg-colour;
    }
}
