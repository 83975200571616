
/* ---- Grid ---- */
.grid:before,
.grid:after {
	content: " ";
	display: table;
}

.grid:after {
	clear: both;
}

.grid-col {
	float: left;
	margin-right: 3.8596491228070176%;
}
.grid-col:last-child,
.grid-col.grid-col-last-in-row {
	margin-right: 0;
}

/* Reverse */
.grid.grid-reverse .grid-col {
	float: right;
	margin-right: 0;
	margin-left: 3.8596491228070176%;
}
.grid.grid-reverse .grid-col:last-child,
.grid.grid-reverse .grid-col.grid-col-last-in-row {
	margin-left: 0;
}

/* 1 col */
.grid-col-1 {
	width: 4.795321637427%;
}
/* 2 col */
.grid-col-2 {
	width: 13.450292397661%;
}
/* 3 col */
.grid-col-3 {
	width: 22.105263157895%;
}
/* 4 col */
.grid-col-4 {
	width: 30.760233918129%;
}
/* 5 col */
.grid-col-5 {
	width: 39.415204678363%;
}
/* 6 col */
.grid-col-6 {
	width: 48.070175438596%;
}
/* 7 col */
.grid-col-7 {
	width: 56.725146198830%;
}
/* 8 col */
.grid-col-8 {
	width: 65.380116959064%;
}
/* 9 col */
.grid-col-9 {
	width: 74.035087719298%;
}
/* 10 col */
.grid-col-10 {
	width: 82.690058479532%;
}
/* 11 col */
.grid-col-11 {
	width: 91.345029239766%;
}
/* 12 col */
.grid-col-12 {
	width: 100%;
}

/* BP 1 */
@media screen and (max-width: 1240px) {
	/* 1 col */
	.grid-col-1-bp1 {
		width: 4.795321637427%;
	}
	/* 2 col */
	.grid-col-2-bp1 {
		width: 13.450292397661%;
	}
	/* 3 col */
	.grid-col-3-bp1 {
		width: 22.105263157895%;
	}
	/* 4 col */
	.grid-col-4-bp1 {
		width: 30.760233918129%;
	}
	/* 5 col */
	.grid-col-5-bp1 {
		width: 39.415204678363%;
	}
	/* 6 col */
	.grid-col-6-bp1 {
		width: 48.070175438596%;
	}
	/* 7 col */
	.grid-col-7-bp1 {
		width: 56.725146198830%;
	}
	/* 8 col */
	.grid-col-8-bp1 {
		width: 65.380116959064%;
	}
	/* 9 col */
	.grid-col-9-bp1 {
		width: 74.035087719298%;
	}
	/* 10 col */
	.grid-col-10-bp1 {
		width: 82.690058479532%;
	}
	/* 11 col */
	.grid-col-11-bp1 {
		width: 91.345029239766%;
	}
	/* 12 col */
	.grid-col-12-bp1 {
		width: 100%;
	}
}

/* BP 2 */
@media screen and (max-width: 1000px) {
	.grid-col-last-of-row-bp2 {
		margin-right: 0;
	}

	/* 1 col */
	.grid-col-1-bp2 {
		width: 4.795321637427%;
	}
	/* 2 col */
	.grid-col-2-bp2 {
		width: 13.450292397661%;
	}
	/* 3 col */
	.grid-col-3-bp2 {
		width: 22.105263157895%;
	}
	/* 4 col */
	.grid-col-4-bp2 {
		width: 30.760233918129%;
	}
	/* 5 col */
	.grid-col-5-bp2 {
		width: 39.415204678363%;
	}
	/* 6 col */
	.grid-col-6-bp2 {
		width: 48.070175438596%;
	}
	/* 7 col */
	.grid-col-7-bp2 {
		width: 56.725146198830%;
	}
	/* 8 col */
	.grid-col-8-bp2 {
		width: 65.380116959064%;
	}
	/* 9 col */
	.grid-col-9-bp2 {
		width: 74.035087719298%;
	}
	/* 10 col */
	.grid-col-10-bp2 {
		width: 82.690058479532%;
	}
	/* 11 col */
	.grid-col-11-bp2 {
		width: 91.345029239766%;
	}
	/* 12 col */
	.grid-col-12-bp2 {
		width: 100%;
	}
}

/* BP 3 */
@media screen and (max-width: 760px) {
	/* 1 col */
	.grid-col-1-bp3 {
		width: 4.795321637427%;
	}
	/* 2 col */
	.grid-col-2-bp3 {
		width: 13.450292397661%;
	}
	/* 3 col */
	.grid-col-3-bp3 {
		width: 22.105263157895%;
	}
	/* 4 col */
	.grid-col-4-bp3 {
		width: 30.760233918129%;
	}
	/* 5 col */
	.grid-col-5-bp3 {
		width: 39.415204678363%;
	}
	/* 6 col */
	.grid-col-6-bp3 {
		width: 48.070175438596%;
	}
	/* 7 col */
	.grid-col-7-bp3 {
		width: 56.725146198830%;
	}
	/* 8 col */
	.grid-col-8-bp3 {
		width: 65.380116959064%;
	}
	/* 9 col */
	.grid-col-9-bp3 {
		width: 74.035087719298%;
	}
	/* 10 col */
	.grid-col-10-bp3 {
		width: 82.690058479532%;
	}
	/* 11 col */
	.grid-col-11-bp3 {
		width: 91.345029239766%;
	}
	/* 12 col */
	.grid-col-12-bp3 {
		width: 100%;
	}
}

/* BP 4 */
@media screen and (max-width: 620px) {
	/* 1 col */
	.grid-col-1-bp4 {
		width: 4.795321637427%;
	}
	/* 2 col */
	.grid-col-2-bp4 {
		width: 13.450292397661%;
	}
	/* 3 col */
	.grid-col-3-bp4 {
		width: 22.105263157895%;
	}
	/* 4 col */
	.grid-col-4-bp4 {
		width: 30.760233918129%;
	}
	/* 5 col */
	.grid-col-5-bp4 {
		width: 39.415204678363%;
	}
	/* 6 col */
	.grid-col-6-bp4 {
		width: 48.070175438596%;
	}
	/* 7 col */
	.grid-col-7-bp4 {
		width: 56.725146198830%;
	}
	/* 8 col */
	.grid-col-8-bp4 {
		width: 65.380116959064%;
	}
	/* 9 col */
	.grid-col-9-bp4 {
		width: 74.035087719298%;
	}
	/* 10 col */
	.grid-col-10-bp4 {
		width: 82.690058479532%;
	}
	/* 11 col */
	.grid-col-11-bp4 {
		width: 91.345029239766%;
	}
	/* 12 col */
	.grid-col-12-bp4 {
		width: 100%;
	}
}
