
/* ---- File upload area ---- */
.file-upload__input {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
.file-upload__helptext {
	padding-top: 14px;
}
.file-upload__helptext--hidden {
	display: none;
}
.file-upload__helptext__line2 {
	display: block;
	font-size: 1.3rem;
}
.file-upload__helptext p {
	margin: 0;
}
.file-upload__helptext:before {
	content: " ";
	display: block;
	width: 27px;
	height: 32px;
	background-image: url(../images/form-icons.svg);
	background-repeat: no-repeat;
	background-position: 0 -1317px;
	margin: 0 auto 10px;
}

.file-upload__area {
	cursor: pointer;
	text-align: center;
}
.field-input .textbox.file-upload__area--dragenter {
	border-color: #35ab75;
}

.file-upload__uploads:before,
.file-upload__uploads:after {
	content: " ";
	display: table;
}

.file-upload__uploads:after {
	clear: both;
}
.file-upload__item,
.file-upload__helptext {
	height: 110px;
	margin-top: 8px;
	margin-bottom: 8px;
}
.file-upload__item {
	background-color: #FFF;
	border: 1px solid #ced2dc;
	width: 110px;
	display: inline-block;
	margin-left: 6px;
	margin-right: 6px;
	position: relative;
	cursor: default;
	vertical-align: top;
}

.file-upload__item__remove {
	-webkit-appearance: none;
	padding: 0;
	outline: none;
	border: none;
	background: none;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
	background: rgba(0, 0, 0, 0.3);
}
.file-upload__item__remove:hover,
.file-upload__item__remove:focus {
	background: rgba(0, 0, 0, 0.7);
}

.file-upload__item__remove:after {
	content: " ";
	display: block;
	width: 17px;
	height: 17px;
	background-image: url(../images/form-icons.svg);
	background-repeat: no-repeat;
	background-position: 3px -938px;
}
.file-upload__item__remove__text {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

/* Feedback */
.file-upload__item__feedback__response {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.file-upload__item__feedback__response p {
	white-space: nowrap;
	-o-text-overflow: ellipsis;
	   text-overflow: ellipsis;
	overflow: hidden;
	margin: 0;
}

/* Not image response */
.file-upload__item__feedback__response .file-upload__item__feedback__name {
	font-size: 1.2rem;
	margin: 0 0 5px;
}
.file-upload__item__feedback__size {
	font-size: 1.1rem;
	margin: 0;
}
.file-upload__item__feedback__response--success--not-image {
	padding: 26px 15px 20px;
}
.file-upload__item__feedback__response--success--not-image:before {
	content: " ";
	display: block;
	width: 28px;
	height: 24px;
	background-image: url(../images/form-icons.svg);
	background-repeat: no-repeat;
	background-position: 0px -1396px;
	margin: 0 auto 10px;
}

/* Image response */
.file-upload__item__feedback__image {
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center center;
}

/* Error */
.file-upload__item__feedback__response--error {
	padding: 25px 15px 20px;
}
.file-upload__item__feedback__response .file-upload__item__feedback__error__text {
	font-size: 1.2rem;
	white-space: normal;
	overflow: visible;
}
.file-upload__item__feedback__response--error:before {
	content: " ";
	display: block;
	width: 23px;
	height: 24px;
	background-image: url(../images/form-icons.svg);
	background-repeat: no-repeat;
	background-position: 0px -1473px;
	margin: 0 auto 10px;
}

/* Hover */
.file-upload__item__feedback__hover {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0,0,0,0.5);
	color: #FFF;
	padding: 33px 15px 20px;
	overflow: hidden;
}
.file-upload__item:hover .file-upload__item__feedback__hover {
	opacity: 1;
}
.file-upload__item__feedback__hover__size {
	font-size: 1.1rem;
}
.file-upload__item__feedback__hover__dimensions {
	font-size: 1.1rem;
}
.file-upload__item__feedback__hover .file-upload__item__feedback__hover__name {
	font-size: 1.1rem;
	margin-bottom: 6px;
}

/* Progress circles */
.file-upload__progress-circle {
	position: relative;
	font-size: 1.3rem;
	line-height: 1.1;
	display: block;
	width: 56px;
	height: 56px;
	padding: 21px 0 0 0;
	margin: 26px auto 0;
	text-align: center;
}
.file-upload__progress-circle__amount {
	z-index: 1;
	position: relative;
}
.file-upload__progress-circle__pie {
	-ms-transform: rotate(-90deg);
	-webkit-transform: rotate(-90deg);
	        transform: rotate(-90deg);
	border-radius: 50%;
	position: absolute;
	top: 0;
	left: 0;
}
.file-upload__progress-circle__pie__piece {
	-webkit-transition: all 100ms ease-in-out;
	-o-transition: all 100ms ease-in-out;
	transition: all 100ms ease-in-out;
}

/* Feedback */



/* ---- File selector ---- */
.field-element--fileselector .fs-select-button {
	float: left;
	margin-right: 9px;
}

.field-element--fileselector .fs-preview-wrapper {
	background-color: #EEF0F3;
	position: relative;
	overflow: hidden;
	display: block;
}

.field-element--fileselector .fs-filename {
	padding: 10px 15px;
	word-break: break-all;
	overflow: hidden;
	display: block;
}
.field-element--fileselector .fs-file-selected .fs-filename {
	padding-left: 10px;
	padding-right: 38px;
}

.field-element--fileselector .fs-preview {
	width: 39px;
	display: none;
	float: left;
}
.field-element--fileselector .fs-file-selected .fs-preview {
	display: block;
}

.field-element--fileselector .fs-remove {
	display: none;
	-webkit-appearance: none;
	outline: none;
	border: none;
	background: none;
	padding: 0;
	color: #D4D7DE;
	position: absolute;
	right: 6px;
	top: 9px;
	background-image: url(../images/form-icons.svg);
	background-repeat: no-repeat;
	background-position: top -1075px left 3px;
	width: 22px;
	height: 22px;
}
.field-element--fileselector .fs-remove:hover,
.field-element--fileselector .fs-remove:focus {background-position: top -1135px left 3px;}
.field-element--fileselector .fs-file-selected .fs-remove {
	display: block;
}


/* Small */
.field-element--fileselector.field-element--small .fs-preview {
	width: 30px;
}
.field-element--fileselector.field-element--small .fs-filename {
	padding-top: 8px;
	padding-bottom: 6px;
	font-size: 1.4rem;
}
.field-element--fileselector.field-element--small .fs-remove {
	top: 6px;
	background-position: top -964px left 3px;
	width: 20px;
	height: 20px;
}
.field-element--fileselector.field-element--small .fs-remove:hover,
.field-element--fileselector.field-element--small .fs-remove:focus {
	background-position: top -1023px left 3px;
}
.field-element--fileselector.field-element--small .fs-file-selected .fs-filename {
	padding-right: 31px;
}

/* Large */
.field-element--fileselector.field-element--large .fs-preview {
	width: 48px;
}
.field-element--fileselector.field-element--large .fs-filename {
	padding-top: 13px;
	padding-bottom: 13px;
	padding-left: 25px;
	padding-right: 25px;
	font-size: 1.8rem;
}
.field-element--fileselector.field-element--large .fs-remove {
	top: 14px;
	right: 9px;
}
.field-element--fileselector.field-element--large .fs-file-selected .fs-filename {
	padding-right: 41px;
	padding-left: 20px;
}

/* Popup */
.file-selector-search-wrapper  {
	display: none;
}
.file-selector-search-wrapper.searched  {
	display: block;
}
#file-selector-preview {
	display: block;
	background: #f9f9fb;
	border: 1px solid #E0E3EA;
	margin: 0 0 20px;
	position: relative;
	padding: 20px 25px;
}
.no-results #file-selector-preview {
	display: none;
}
#file-selector-preview .preview-title {
	margin: 0 0 12px;
}
#file-selector-preview .preview-box {
	position: relative;
	height: 150px;
}
#file-selector-preview img {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-right: -50%;
	-ms-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	margin: 0 auto;
	display: block;
}

#file-selector-upload .field-element--new-category,
#file-selector-upload.new-category .field-element--select-category {
	display: none;
}
#file-selector-upload.new-category .field-element--new-category,
#file-selector-upload .field-element--select-category {
	display: block;
}

#file-selector-result-wrap {
	max-height: 450px;
	overflow: auto;
	border-top: 1px solid #D7D7D7;
	border-bottom: 1px solid #D7D7D7;
}
#file-selector-upload .field-elements-inline,
#file-selector-search .field-elements-inline {
	display: -ms-flexbox;
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
}
#file-selector-upload .field-elements-inline .field-element--dropdown,
#file-selector-search .field-elements-inline .field-element--dropdown {
	-ms-flex-positive: 1;
	    -webkit-box-flex: 1;
	    -webkit-flex-grow: 1;
	        flex-grow: 1;
}
#file-selector-upload .field-elements-inline .field-element--text,
#file-selector-search .field-elements-inline .field-element--text {
	-ms-flex-positive: 1;
	    -webkit-box-flex: 1;
	    -webkit-flex-grow: 1;
	        flex-grow: 1;
}

#file-selector-stats {
	font-size: 1.6rem;
	margin-bottom: 14px;
}
#file-selector-results .file-select-table {
	margin: 0;
	width: 100%;
	border-left: 1px solid #E0E3EA;
	border-right: 1px solid #E0E3EA;
}
#file-selector-results .file-select-table tr {
	border-bottom: 1px solid #D7D7D7;
	cursor: pointer;
}
#file-selector-results .file-select-table tr:last-child {
	border-bottom: 0;
}
#file-selector-results .file-select-table tr:nth-child(odd) {
	background-color: #f9f9fb;
}

/* Hover */
#file-selector-results .file-select-table tr:hover {
    background-color: #ECE9E9;
}

#file-selector-paginate {
	margin-top: 16px;
}
#file-selector-prev {
	float: left;
}
#file-selector-next {
	float: right;
}
#file-selector-results a {
	text-decoration: none;
	color: inherit;
	display: block;
}
#file-selector-results .file-select-table-thumbnail {
	width: 100px;
	padding: 5px 20px;
}
#file-selector-results .file-select-table-text a {
	padding: 27px 25px 27px 0;
}
#file-selector-results .table--content-standard tr:hover td {
	background-color: red;
}
