/* ---- Icomoon ---- */
@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icomoon/fonts/icomoon.eot?vyb8er');
    src: url('../fonts/icomoon/fonts/icomoon.eot?vyb8er#iefix') format('embedded-opentype'),
         url('../fonts/icomoon/fonts/icomoon.ttf?vyb8er') format('truetype'),
         url('../fonts/icomoon/fonts/icomoon.woff?vyb8er') format('woff'),
         url('../fonts/icomoon/fonts/icomoon.svg?vyb8er#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

@mixin icomoon-icon {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
}

.icon-before:before,
.icon-after:after {
    @include icomoon-icon;
}

.icon-before.icon-plus:before,
.icon-after.icon-plus:after {
  content: "\e917";
}
.icon-before.icon-minus:before,
.icon-after.icon-minus:after {
  content: "\e916";
}
.icon-before.icon-arrow-down:before,
.icon-after.icon-arrow-down:after {
    content: "\e900";
}
.icon-before.icon-arrow-left:before,
.icon-after.icon-arrow-left:after {
    content: "\e901";
}
.icon-before.icon-arrow-right:before,
.icon-after.icon-arrow-right:after {
    content: "\e902";
}
.icon-before.icon-arrow-up:before,
.icon-after.icon-arrow-up:after {
    content: "\e903";
}
.icon-before.icon-check:before,
.icon-after.icon-check:after {
    content: "\e904";
}
.icon-before.icon-cloud-services:before,
.icon-after.icon-cloud-services:after {
    content: "\e905";
}
.icon-before.icon-double-arrows-down:before,
.icon-after.icon-double-arrows-down:after {
    content: "\e906";
}
.icon-before.icon-double-arrows-left:before,
.icon-after.icon-double-arrows-left:after {
    content: "\e907";
}
.icon-before.icon-double-arrows-right:before,
.icon-after.icon-double-arrows-right:after {
    content: "\e908";
}
.icon-before.icon-double-arrows-up:before,
.icon-after.icon-double-arrows-up:after {
    content: "\e909";
}
.icon-before.icon-email-address:before,
.icon-after.icon-email-address:after {
    content: "\e90a";
}
.icon-before.icon-email:before,
.icon-after.icon-email:after {
    content: "\e90b";
}
.icon-before.icon-facebook:before,
.icon-after.icon-facebook:after {
    content: "\e90c";
}
.icon-before.icon-home:before,
.icon-after.icon-home:after {
    content: "\e90d";
}
.icon-before.icon-integration:before,
.icon-after.icon-integration:after {
    content: "\e90e";
}
.icon-before.icon-linked-in:before,
.icon-after.icon-linked-in:after {
    content: "\e90f";
}
.icon-before.icon-logitech-logo:before,
.icon-after.icon-logitech-logo:after {
    content: "\e910";
}
.icon-before.icon-map-marker:before,
.icon-after.icon-map-marker:after {
    content: "\e911";
}
.icon-before.icon-phone:before,
.icon-after.icon-phone:after {
    content: "\e912";
}
.icon-before.icon-rocket:before,
.icon-after.icon-rocket:after {
    content: "\e913";
}
.icon-before.icon-timer-24hr:before,
.icon-after.icon-timer-24hr:after {
    content: "\e914";
}
.icon-before.icon-work-apps:before,
.icon-after.icon-work-apps:after {
    content: "\e915";
}
