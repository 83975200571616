

/* ----- Brand colours ----- */
// Converted the giant CSS file to SCSS
// Apologies, this saddens me to, but it's whatcha get when you don't have variables

$color-00: 					#2c364a; // navy

$color-00-dark: 			#282f40; // darker navy

$color-00-pale: 			#445374; // pale navy

$color-01:                  #0073BC; // sky blue

$color-01-dark:             #0575b3; // sky blue

$color-02:                  #009EE2; // bright blue

$color-02-dark:             #069dd6; // bright blue

$color-03:                  #00AAE9; // light blue

$color-04:                  #0098BF; // bright blue

$color-04-dull:             #4096b5; // bright blue

$color-05:                  #009C85; // ocean green

$color-05-pale:             #35ab75; // ocean green pale

$color-06: 				    #61b258; // green

$color-07:                  #39A935; // bright green

$color-08: 				    #50a247; // dense green

$color-08-dark: 		    #439b3a; // dark dense green

$color-09:                  #9CC330; // lime green

$color-10:                  #95d38e; // pale green

$color-alternative:		    #821c1c; // Deep red

$color-alternative-subtle:  #DC5959; // Coral


$brand-color-list: (
    00: $color-00,
    01: $color-01,
    02: $color-02,
    03: $color-03,
    04: $color-04,
    05: $color-05,
    06: $color-06,
    07: $color-07,
    08: $color-08,
    09: $color-09,
    10: $color-10,
    alternative: $color-alternative
) !default;


// Neutral Colours

$color-white:						#ffffff;

$color-grey-01:                     #f6f6f6; //

$color-lightblue:                   #eef4f7; //

$color-grey-02:						#EEE; //

$color-grey-03:						#D7D7D7; //

$color-grey-04:						#b8b8b8;

$color-grey-05:						#9c9c9c;

$color-grey-06:						#8a7b7b;

$color-grey-07:						#696d73;

$color-grey-08:						#444;

$color-grey-09:						#4b4b4b; //

$color-grey-10:						#24292f; //

$color-grey-11:						#0f0f0f;

$color-black:						#000000;


$neutral-color-list: (
    "white": $color-white,
    grey-01: $color-grey-01,
    grey-02: $color-grey-02,
    grey-03: $color-grey-04,
    grey-04: $color-grey-05,
    grey-05: $color-grey-06,
    grey-06: $color-grey-07,
    grey-07: $color-grey-09,
    grey-08: $color-grey-10,
    grey-09: $color-grey-11,
    "black": $color-black
) !default;


// Utility Colours

$color-utility-positive:			#439230; //

$color-utility-positive-subtle:		#6DBB5A; //

$color-utility-alert:				#CC4925; //

$color-utility-alert-subtle:		#e85505; //

$color-utility-warning:				#B72323; //

$color-utility-warning-subtle:		#E04D4D; //

$color-utility-neutral:				#0192d0;

$color-utility-neutral-subtle:		#d3f2ff;

$color-utility-selection:           #FFC57D;

$color-utility-placeholder:         $color-grey-05;


$utility-color-list: (
    positive: $color-utility-positive,
    positive-subtle: $color-utility-positive-subtle,
    alert: $color-utility-alert,
    alert-subtle: $color-utility-alert-subtle,
    warning: $color-utility-warning,
    warning-subtle: $color-utility-warning-subtle,
    neutral: $color-utility-neutral,
    neutral-subtle: $color-utility-neutral-subtle,
    selection: $color-utility-selection,
    placeholder: $color-utility-placeholder,
) !default;


// Body font color

$color-body-font: $color-grey-09;
