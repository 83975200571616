@use "00-settings" as *;

/* ---- Line title ---- */
.line-title {
	margin: 0 0 45px;
}
.line-title__title {
	position: relative;
}
.line-title__title:after {
	content: " ";
	display: block;
	height: 18px;
	margin-top: 18px;
}
.line-title__title h2,
.line-title__title .h2,
.line-title__title .line-title__title__copy {
	margin: 0;
	font-size: 2rem;
	color: $color-00;
	text-transform: uppercase;
	line-height: 1.2;
	@include font-set('accent');
}
.line-title__link {
	float: right;
	margin-left: 50px;
}

/* Side */
.line-title.line-title--side .line-title__title {
	padding-bottom: 26px;
}
.line-title.line-title--side .line-title__title:after {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 1000px;
	background-image: url(../images/texture-lines-lightblue.png);
}
.line-title.line-title--side .line-title__title {
	display: inline-block;
}

/* Dark blue */
.line-title.line-title--darkblue .line-title__title:after {
	background-image: url(../images/texture-lines-blue.png);
}
/* Green */
.line-title.line-title--green .line-title__title:after {
	background-image: url(../images/texture-lines-green.png);
}

/* BP 1 */
@media screen and (max-width: 1240px) {
	.line-title {
		margin-bottom: 27px;
	}
}

/* BP 3 */
@media screen and (max-width: 760px) {
	.line-title {
		margin-bottom: 20px;
	}
	.line-title__title:after {
		height: 12px;
		margin-top: 12px;
	}
	.line-title.line-title--side .line-title__title {
		padding-bottom: 18px;
	}
}

/* BP 4 */
@media screen and (max-width: 620px) {
	.line-title {
		margin-bottom: 15px;
	}
	.line-title__title:after {
		height: 8px;
		margin-top: 8px;
	}
	.line-title.line-title--side .line-title__title {
		padding-bottom: 12px;
	}
	.line-title__link {
		float: none;
		margin: 20px 0 10px;
	}
	.line-title.line-title--side .line-title__title {
		display: block;
	}
}

/* BP 3 */
@media screen and (max-width: 760px) {
	.inner-page-title .line-title__title {
		display: none;
	}
}


/* Green */
.line-title.line-title--green .line-title__title:after {
	background-image: url(../images/texture-lines-green.png);
}

/* BP 1 */
@media screen and (max-width: 1240px) {
	.line-title {
		margin-bottom: 27px;
	}
}

/* BP 3 */
@media screen and (max-width: 760px) {
	.line-title {
		margin-bottom: 20px;
	}
	.line-title__title:after {
		height: 12px;
		margin-top: 12px;
	}
}

/* BP 4 */
@media screen and (max-width: 620px) {
	.line-title {
		margin-bottom: 15px;
	}
	.line-title__title:after {
		height: 8px;
		margin-top: 8px;
	}
}
