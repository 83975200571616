@use "../../../00-settings" as *;
@use "../../../05-utilities/helpers/utility-mixins" as *;

:root {
	--menu-item-vertical-padding: 5px;
}

@include screen($bp768) {
	:root {
		--menu-item-vertical-padding: 6px;
	}
}

@include screen($bp992) {
	:root {
		--menu-item-vertical-padding: 8px;
	}
}

@include screen($bp1200) {
	:root {
		// match circle height at 40px
		--menu-item-vertical-padding: 7px;
	}
}


/** Desktop and Mobile styling
===================================*/
#frankenmenu-list,
#frankenmenu-list ul {
	list-style: none;
	padding: 0;
	margin: 0;
	display: block;
}

#frankenmenu-list a {
	text-decoration: none;
	display: block;
	font-size: 1.6rem;
}


/** Desktop menu only
===================================*/
@include screen($bpdesktop) {

	#frankenmenu-list {
		text-align: left;
	}
	#mobile-header {
		display: none;
	}

	/* ---- All level menu items ---- */
	#frankenmenu-list .menu-item {
		position: relative;
	}
	#frankenmenu-list .sub-menu {
		display: none;
		min-width: 200px;
		position: absolute;
		box-shadow: 0 0 8px 0px rgba(0,0,0,0.4);
		z-index: 1;
		min-width: 250px;
		max-width: 350px;
		text-align: left;
		top: 0;
		left: 100%;
	}

	/* ---- First level menu ---- */
	#frankenmenu-list .menu-item-depth1 {
		float: left;
		text-align: center;
		margin-right: 11px;

		@include screen($bp768) {
			margin-right: 8px;
		}

		@include screen($bp992) {
			margin-right: 14px;
		}
	}
	#frankenmenu-list .menu-item-depth1 > a {
		display: block;
		color: lighten($color-00-pale, 37%);
		font-size: 1.2rem;
		padding-top: calc(var(--menu-item-vertical-padding) * 1.4);
		padding-bottom: calc(var(--menu-item-vertical-padding) * 0.8);
		padding-inline: 3px;
		font-family: 'DIN Next W01', sans-serif;
		text-transform: uppercase;
		font-weight: 500;
		line-height: 1.2;

		@include screen($bp768) {
			padding-inline: 2px;
		}

		@include screen($bp991) {
			font-size: 1.4rem;
		}

		@include screen($bp1200) {
			padding-top: calc(var(--menu-item-vertical-padding) * 1.5);
			padding-inline: 5px;
			font-size: 1.8rem;
		}
	}
	#frankenmenu-list .menu-item-depth1 > a:hover,
	#frankenmenu-list .menu-item-depth1 > a:focus,
	#frankenmenu-list .menu-item-depth1.frankenhover > a,
	#frankenmenu-list .menu-item-depth1.frankenhold > a {
		color: $color-00-dark;
	}
	/* Home */
	#frankenmenu-list > .menu-item.menu-home-page > a {
		text-indent: -9999px;
		text-align: center;
		padding-left: 0;
		padding-right: 0;
		width: 22px;

		@include screen($bp768) {
			width: 24px;
		}

		@include screen($bp991) {
			width: 26px;
		}

		@include screen($bp1200) {
			width: 29px;
		}
	}
	#frankenmenu-list > .menu-item.menu-home-page > a:before {
		content: "\e90d";
		font-family: 'icomoon' !important;
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
		text-indent: 0;
		position: absolute;
		top: 1px;
		left: 0;
		font-size: 22px;

		@include screen($bp768) {
			top: calc(var(--menu-item-vertical-padding) * 0.4);
			left: 0;
			font-size: 23px;
		}

		@include screen($bp991) {
			left: -1px;
			font-size: 28px;
		}

		@include screen($bp1200) {
			top: calc(var(--menu-item-vertical-padding) * 0.25);
			left: -2px;
			font-size: 34px;
		}
	}

	/* Current item */
	#frankenmenu-list .menu-item-depth1.menu-current-item > a,
	#frankenmenu-list .menu-item-depth1.menu-current-item-ancestor > a {
		border-bottom: 2px solid $color-01;
		color: $color-01;

		@include screen($bp1200) {
			border-bottom-width: 3px;
		}
	}

	/* ---- Submenus ---- */
	#frankenmenu-list .sub-menu a {
		border-top: 1px solid $color-white;
		padding: 9px 18px 10px;
		font-size: 1.5rem;
		line-height: 1.8rem;
		background-color: $color-white;
		color: $color-00-pale;
		transition: all 200ms ease;
	}
	#frankenmenu-list .sub-menu .menu-item:first-child a {
		border-top: none;
	}
	#frankenmenu-list .sub-menu .menu-item > a:hover,
	#frankenmenu-list .sub-menu .menu-item > a:focus,
	#frankenmenu-list .sub-menu .menu-item.frankenhover > a,
	#frankenmenu-list .sub-menu .menu-item.frankenhold > a {
		background: $color-00-pale;
		color: $color-white;
	}

	#frankenmenu-list .frankenhold > .sub-menu {
		display: block !important;
		opacity: 1 !important;
	}

	/* ---- First level submenus ---- */
	#frankenmenu-list .sub-menu-depth1 {
		top: 100%;
		left: auto;
	}

	/* ---- Right aligned submenus ---- */
	#frankenmenu-list .sub-menu.submenu-right-align,
	#frankenmenu-list .sub-menu.submenu-right-align .sub-menu {
		left: auto;
		right: 100%;
	}
	#frankenmenu-list .sub-menu-depth1.submenu-right-align {
		left: auto;
		right: 0;
	}

	/* ---- Sub-navigation ---- */
	#frankenmenu-list .menu-item-subnav {
		display: none;
	}
	#frankenmenu-subnav {}
	#frankenmenu-subnav-list {
		list-style: none;
		padding: 0;
		margin: 0;
	}
	#frankenmenu-subnav-list .menu-item-depth1 {
		display: inline-block;
	}
	#frankenmenu-subnav-list .menu-item-depth1 > a {
		text-decoration: none;
		display: block;
		color: inherit;
	}
	#frankenmenu-subnav-list .menu-item-depth1 > a:hover {
		text-decoration: underline;
	}
	#frankenmenu-subnav-list .sub-menu {
		display: none;
	}

	/* ---- Moved elements ---- */
	.frankenmoved {
		display: none;
	}

	/* ---- Megamenu ---- */
	#frankenmenu-list .mega-menu {
		left: 0;
		right: 0;
		background-color: #FFF;
		text-align: left;
		top: 100%;
		position: absolute;
		border-bottom: 4px solid #D8D8D8;
		z-index: 51;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		width: 1px;
	}
	/* Remove relative positioning for mega menu */
	.frankenmenu-mega #frankenmenu-list .menu-item > a {
		position: static;
	}
	#frankenmenu-list .menu-item.frankenhover .mega-menu,
	#frankenmenu-list .menu-item.frankenhold .mega-menu {
		padding: 40px 0;
		width: auto;
		height: auto;
		clip: auto;
		overflow: visible;
		margin: auto;
	}
	#frankenmenu-list .menu-item.frankenhold .mega-menu {
		display: block !important;
		height: auto !important;
	}

	/* Megamenu preview */
	#frankenmenu-list .mega-menu-preview {
		float: left;
		color: #FFF;
		padding: 18px 20px;
		background-color: #6F6F6F;
		width: 22%;
		margin-right: 3%;
	}
	#frankenmenu-list .mega-menu-preview-image {
		height: 120px;
		background: url(../images/mega-menu-image-fallback.jpg) no-repeat center center;
		background-size: cover;
	}
	#frankenmenu-list .mega-menu-preview-text {
		margin-bottom: 0;
		line-height: 1.2;
	}

	/* Megamenu columns */
	#frankenmenu-list .mega-menu .mega-menu-columns {
		float: left;
		width: 75%;
	}
	#frankenmenu-list .mega-menu-column {
		width: 31.33333333%;
		float: left;
		margin-right: 3%;
	}
	#frankenmenu-list .mega-menu-column:last-child {
		margin-right: 0;
	}

	/* Megamenu submenus */
	#frankenmenu-list .mega-menu-submenu .menu-item {
		border-top: 1px solid #D8D8D8;
	}
	#frankenmenu-list .mega-menu-submenu .menu-item:last-child {
		border-bottom: 1px solid #D8D8D8;
	}
	#frankenmenu-list .mega-menu-submenu a {
		color: #000;
		padding: 5px 0;
	}

}

/** Mobile menu only
===================================*/
@include screen($bpmobile, 'max') {

	/* Force iOS click events trigger anywhere */
	html {
		cursor: pointer;
	}

	#wrap {
		margin-top: 40px;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		background-color: $color-white;
	}
	#frankenmenu {
		top: 0;
		position: fixed;
		overflow-y: scroll;
		overflow-x: hidden;
		height: 100%;
		background-color: $color-white;
		color: $color-00-pale;
		width: 70%;
		z-index: 0;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		margin-top: 40px;
		padding-top: 16px;
		border-top: 1px solid $color-lightblue;
	}
	#frankenmenu .container {
		position: relative;
		padding: 0;
		max-width: none;
		width: 100%;
		margin: 0;
		opacity: 0;
	}

	/* ---- Mobile header ---- */
	#mobile-header {
		background-color: $color-white;
		height: var(--header-height);
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 100;

		.mobile-header__logo-text {
			width: 68px;
		}

		.mobile-header__logo-circle {
			width: 36px;
			margin-right: 6px;
		}

		.container {
			display: flex;
			justify-content: space-between;
		}
	}

	/* -- Menu button -- */
	#mobile-menu-button {
		-webkit-appearance: none;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		outline: none;
		border: none;
		padding: 0;
		position: relative;
		width: 42px;
		height: 40px;
		background: none;
		vertical-align: top;
		margin: 0;
	}

	.show-focus-outlines #mobile-menu-button:focus {
		outline: 1px dotted #ff2128;
	}

	.mobile-menu-button-line {
		height: 3px;
		background-color: $color-00-pale;
		position: absolute;
		display: block;
		left: 6px;
		animation-fill-mode: forwards;
		animation-duration: 300ms;
		right: 6px;
	}
	.mobile-menu-button-top {
		top: 10px;
	}
	.mobile-menu-button-middle {
		top: 19px;
	}
	.mobile-menu-button-bottom {
		top: 28px;
	}

	/* Animations active */
	.nav-happened .mobile-menu-button-top {
		animation-name: mobileMenuBtnTopClose;
	}
	.nav-happened .mobile-menu-button-middle {
		animation-name: mobileMenuBtnMiddleClose;
	}
	.nav-happened .mobile-menu-button-bottom {
		animation-name: mobileMenuBtnBottomClose;
	}

	/* Close button */
	.frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-top {
		animation-name: mobileMenuBtnTopOpen;
	}
	.frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-middle {
		animation-name: mobileMenuBtnMiddleOpen;
	}
	.frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-bottom {
		animation-name: mobileMenuBtnBottomOpen;
	}



	/* ---- All level menu items ---- */
	#frankenmenu-list, #frankenmenu-list ul {
		width: 100%;
	}
	#frankenmenu-list .menu-item {
		position: relative;
		border-top: 1px solid rgba($color-00-pale, 0.1);
		float: none;
		width: 100%;
	}
	#frankenmenu-list a {
		min-height: 39px;
		width: 100%;
		padding: 14px 85px 9px 20px;
		line-height: 1.05;
		color: $color-00-pale;
		font-family: 'DIN Next W01', sans-serif;
		text-transform: uppercase;
	}
	#frankenmenu-list .menu-item.menu-current-item > a {
		background-color: $color-02;
		color: $color-white;
		padding-left: 17px;
	}
	#frankenmenu-list .menu-item.menu-current-item .submenu-toggle {
		color: $color-white;
	}

	#frankenmenu-list .menu-item.menu-current-item .submenu-toggle:before {
		background-image: url(../images/icon-menu-white.svg);
	}



	/* ---- First level menu ---- */
	#frankenmenu-list .menu-item-depth1:last-child {
		border-bottom: 1px solid rgba($color-00-pale, 0.1);
	}
	#frankenmenu-list .menu-item-depth1 > a {
		font-size: 1.4rem;
	}

	#frankenmenu-list .icon-circle-list__item .icon-before:before {
		content: none;
	}

	#frankenmenu-list .icon-circle-list__item .-vis-hidden {
		clip: auto;
		height: auto;
		margin: auto;
		overflow: visible;
		position: relative;
		width: auto;
	}
	#frankenmenu-list .icon-circle-list__item {
		margin: 0;
	}

	/* ---- Submenus ---- */
	#frankenmenu-list .sub-menu {
		display: none;
	}
	#frankenmenu-list .menu-current-item-ancestor > .sub-menu {
		display: block;
	}

	#frankenmenu-list .menu-item-depth1.menu-current-item-ancestor > a {
		border-left: 3px solid $color-02;
		background-color: lighten($color-02, 50%);
	}

	/* Depth differences */
	#frankenmenu-list .menu-item-depth1 > a {
		padding-left: 20px;
		background-color: $color-white;
		color: $color-00-pale;
	}
	#frankenmenu-list .menu-item-depth2 a {
		font-size: 1.4rem;
	}
	#frankenmenu-list .menu-item-depth2 > a  {
		padding-left: 28px;
		// background-color: rgba($color-00-pale,0.08);
	}
	#frankenmenu-list .menu-item-depth3 > a  {
		padding-left: 36px;
		// background-color: rgba($color-00-pale,0.16);
	}
	#frankenmenu-list .menu-item-depth4 > a  {
		padding-left: 44px;
		// background-color: rgba($color-00-pale,0.24);
	}
	#frankenmenu-list .menu-item-depth5 > a  {
		padding-left: 52px;
		// background-color: rgba($color-00-pale,0.32);
	}
	#frankenmenu-list .menu-item-depth6 > a  {
		padding-left: 52px;
		// background-color: rgba($color-00-pale,0.4);
	}

	/* Expand buttons */
	.submenu-toggle {
		-webkit-appearance: none;
		-moz-appearance: none;
		outline: none;
		border: none;
		color: $color-00-pale;
		font-size: 2.3rem;
		background: transparent;
		width: 55px;
		height: 39px;
		display: block;
		padding: 0;
		margin: 0;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		cursor: pointer;
		padding: 0;
	}
	.submenu-toggle:before {
		content: " ";
		width: 21px;
		height: 12px;
		background: url(../images/icon-menu.svg) no-repeat 0 -30px;
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: 0 -50% 0 0;
		-webkit-transform: translate(-50%, -50%);
		        transform: translate(-50%, -50%);
		-webkit-transform-origin: center center;
		        transform-origin: center center;
		transition: -webkit-transform 300ms ease-in;
		transition: transform 300ms ease-in;
	}
	.menu-item-submenu-open > .submenu-toggle:before {
		-webkit-transform: translate(-50%, -50%) rotate(180deg);
		        transform: translate(-50%, -50%) rotate(180deg);
	}

	/* ---- Sub-navigation ---- */
	#frankenmenu-subnav-list {
		display: none;
	}

	/* ---- Menu positions ---- */

	/* Left */
	.frankenmenu-mobile-pos-left #frankenmenu {
		left: 0;
	}
	.frankenmenu-mobile-pos-left #frankenmenu .container {
		-webkit-transform: translate3d(-10%, 0, 0) scale(0.9);
		        transform: translate3d(-10%, 0, 0) scale(0.9);
	}
	.frankenmenu-mobile-pos-left.frankenmenu-mob-menu-visible #wrap {
		-webkit-transform: translate3d(70%, 0, 0);
		        transform: translate3d(70%, 0, 0);
	}

	/* Right */
	.frankenmenu-mobile-pos-right #frankenmenu {
		right: 0;
	}
	.frankenmenu-mobile-pos-right #frankenmenu .container {
		-webkit-transform: translate3d(10%, 0, 0) scale(0.9);
		        transform: translate3d(10%, 0, 0) scale(0.9);
	}
	.frankenmenu-mobile-pos-right.frankenmenu-mob-menu-visible #wrap {
		-webkit-transform: translate3d(-70%, 0, 0);
		        transform: translate3d(-70%, 0, 0);
	}
	.frankenmenu-mobile-pos-right #mobile-menu-button {
		float: right;
	}

	/* ---- Transitions ---- */
	#frankenmenu,
	#wrap,
	#frankenmenu .container {
		transition: all 350ms ease;
	}

	/* Menu open */
	.frankenmenu-mob-menu-visible #frankenmenu {
		-webkit-transform: translate3d(0, 0, 0);
		        transform: translate3d(0, 0, 0);
	}
	.frankenmenu-mob-menu-visible #frankenmenu .container {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0) scale(1);
		        transform: translate3d(0, 0, 0) scale(1);
	}

	/* ---- Moved elements ---- */
	.frankenmove {
		display: none;
	}

	/* ---- Megamenu ---- */
	.mega-menu-preview {
		display: none;
	}
	.mega-menu-preview-image {
		background-image: none !important;
	}
	.mega-menu-column-title {
		color: #FFF;
		font-size: 1.6rem;
		padding: 3px 20px;
		margin: 0;
		background: rgba(0,0,0,0.3);
	}
	#frankenmenu-list .mega-menu {
		display: none;
	}
	#frankenmenu-list .mega-menu ul {
		display: block;
	}
}
