/* ---- Money ---- */
.field-element--money .field-input .textbox {
	padding-left: 55px;
	background-image: url(../images/form-icons.svg);
	background-repeat: no-repeat;
	background-position: top -447px left 0;
}

.field-element--money .field-input {
	position: relative;
}
.field-element--money .field-input .money-symbol:before {
	content: "\00a4";		/* Generic currency sign */
	position: absolute;
	display: block;
	top: 8px;
	left: 0;
	color: #58595B;
	font-weight: bold;
	font-size: 19px;
	width: 41px;
	text-align: center;
}
.field-element--money .field-input .money-symbol--dollar:before {
	content: "$";    /* Dollars */
}
.field-element--money .field-input .money-symbol--pound:before {
	content: "\00a3";    /* Pounds */
}
.field-element--money .field-input .money-symbol--yen:before {
    content: "\00a5";    /* Yen */
}
.field-element--money .field-input .money-symbol--indian_rupee:before {
    content: "\20B9";   /* Indian rupee */
}

/* White */
.field-element--money.field-element--white .field-input .textbox,
.field-element--money.field-element--white.field-element--small .field-input .textbox,
.field-element--money.field-element--white.field-element--large .field-input .textbox {
	background-position: top -607px left 0;
}

/* Small */
.field-element--money.field-element--small .field-input .textbox {
	background-position: top -447px left 0;
}
.field-element--money.field-element--small .field-input .money-symbol:before {
	top: 4px;
}

/* Large */
.field-element--money.field-element--large .field-input .textbox {
	background-position: top -447px left 0;
}
.field-element--money.field-element--large .field-input .money-symbol:before {
	top: 14px;
}

/* Large and regular field elements turn small */
@media screen and (max-width: 900px) {
	.field-element--money .field-input .textbox,
	.field-element--money.field-element--large .field-input .textbox,
	.field-element--money.field-element--regular .field-input .textbox {
		background-position: top -447px left 0;
	}
	.field-element--white.field-element--money .field-input .textbox,
	.field-element--white.field-element--money.field-element--large .field-input .textbox,
	.field-element--white.field-element--money.field-element--regular .field-input .textbox {
		background-position: top -607px left 0;
	}
	.field-element--money .field-input .money-symbol:before,
	.field-element--money.field-element--large .field-input .money-symbol:before,
	.field-element--money.field-element--regular .field-input .money-symbol:before {
		top: 5px;
	}
}
