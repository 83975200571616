
@use "../../00-settings" as *;
@use "../../01-base/typography/reverse-text-mixins" as *;

/* ---- Inner banner ---- */
.section--inner-banner {
	padding-top: 23px;
	padding-bottom: 65px;
}
.section--inner-banner:before {
	content: " ";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-image: url(../images/texture-dots.svg);
	opacity: 0.1;
}
.section--inner-banner__inner {
	position: relative;
}
.section--inner-banner__title {
	color: #fff;
	position: relative;
	display: inline-block;
	padding-left: 10px;
	margin-bottom: 30px;
}
.section--inner-banner__title:before {
	content: " ";
	display: block;
	position: absolute;
	top: 5px;
	right: 100%;
	width: 530px;
	height: 26px;
	background-image: url(../images/texture-lines-blue.png);
}
.section--inner-banner__title:after {
	font-size: 100px;
	position: absolute;
	left: 100%;
	top: -31px;
}

.section--inner-banner__subtitle {
	font-size: 6.3rem;
	font-family: 'Source Sans Pro', sans-serif;
	font-weight: 700;
	line-height: 1;
	margin: 0;
}

/* BP 1 */
@media screen and (max-width: 1240px) {
	.section--inner-banner {
		padding-top: 17px;
		padding-bottom: 47px;
	}
	.section--inner-banner__subtitle {
		font-size: 5rem;
	}
	.section--inner-banner__title:after {
		font-size: 80px;
		top: -24px;
	}
	.section--inner-banner__title:before {
		height: 21px;
	}
}

/* BP 2 */
@media screen and (max-width: 1000px) {
	.section--inner-banner {
		padding-top: 14px;
		padding-bottom: 35px;
	}
	.section--inner-banner__subtitle {
		font-size: 4rem;
	}
	.section--inner-banner__title:after {
		font-size: 70px;
		top: -22px;
	}
	.section--inner-banner__title:before {
		height: 17px;
	}
	.section--inner-banner__title {
		margin-bottom: 20px;
	}
}

/* BP 3 */
@media screen and (max-width: 760px) {
	.section--inner-banner {
		padding-top: 10px;
		padding-bottom: 25px;
	}
	.section--inner-banner__subtitle {
		font-size: 3rem;
	}
	.section--inner-banner__title:after {
		font-size: 60px;
		top: -15px;
	}
	.section--inner-banner__title:before {
		height: 17px;
		top: 5px;
	}
	.section--inner-banner__title {
		margin-bottom: 15px;
	}
}

/* BP 4 */
@media screen and (max-width: 620px) {
	.section--inner-banner__subtitle {
		font-size: 2.4rem;
	}
	.section--inner-banner__title:before {
		height: 16px;
		top: 3px;
	}
	.section--inner-banner__title:after {
		font-size: 50px;
		top: -14px;
	}
}

/* Extra BP */
@media screen and (max-width: 450px) {
	.section--inner-banner__title:before {
		height: 13px;
		top: 4px;
	}
	.section--inner-banner__title:after {
		font-size: 40px;
		top: -9px;
	}
}
