@use "button-mixins" as *;

/* ---- Button Variations ---- */
// block
.button--block,
.button-block {
    display: block;
    width: 100%;
}

// Disabled/unavailable button style
.button[disabled],
.button-disabled,
.button--disabled,
.button-disabled:hover,
.button-disabled:focus,
.button-disabled:active {
    @include button-disabled;
}

/* Firefox: Get rid of the inner focus border */
.button::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/* Hover/unhover states */
.button-hover-state {
	display: none;
}
.button:hover .button-hover-state {
	display: inline;
}
.button:hover .button-unhover-state {
	display: none;
}

/* Gets the buttons to line up with form inputs when you want them to */
.button.inline {
	margin-bottom: 0.75em;
}

/* Inline buttons */
.inline-buttons .button {
	margin-right: 8px;
	float: left;
}
.inline-buttons .button:last-child {
	margin-right: 0;
}

/* right */
.inline-buttons.inline-buttons-right {
	text-align: right;
}
.inline-buttons.inline-buttons-right .button {
	margin-right: 0;
	margin-left: 8px
}
.inline-buttons.inline-buttons-right .button:first-child {
	margin-left: 0;
}
