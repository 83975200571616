@use '../../../00-settings/colors' as *;
/* ---- Captcha ---- */
.captcha {
	background-color: $color-grey-01;
	padding: 10px;
	width: 100%;
	border: 1px solid $color-grey-03;
}

.captcha .captcha-info, .captcha .info {
    float: right;
    padding-bottom: 8px;
}

.captcha .captcha-info, .captcha .info a {
    text-decoration: none;
}

.captcha .captcha-info .captcha-icon {
    width: 16px;
    height: 16px;
    margin-bottom: 16px;
}

.captcha .captcha-img {
    width: 200px;
    margin-bottom: 10px;
}

.captcha .captcha-input {
    width: 200px;
}

/* ---- reCAPTCHA ---- */
.g-recaptcha {
    padding-bottom: 15px;
}

