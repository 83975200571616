
/* ---- children page gallery ---- */
.children-gallery-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.widget-ChildrenGallery .children-gallery-list-item {
	width: 21%;
	float: left;
	margin: 0 4% 20px 0;
	position: relative;
}
.widget-ChildrenGallery .children-gallery-list-item-link {
	display: block;
	text-decoration: none;
	color: inherit;
}
.widget-ChildrenGallery .children-gallery-list-item-link:hover {
	text-decoration: underline;
}
.widget-ChildrenGallery .children-gallery-list-item-image {
	margin-bottom: 9px;
}
.widget-ChildrenGallery .children-gallery-list-item-link:hover .children-gallery-list-item-image {
	opacity: 0.9;
}
.widget-ChildrenGallery .children-gallery-list-item-title {
	margin: 0;
}
.widget-ChildrenGallery .children-gallery-list-item-anchor {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

@media screen and (max-width: 760px) {
	.widget-ChildrenGallery .children-gallery-list-item {
		width: 30.666666%;
	}
	.widget-ChildrenGallery .children-gallery-list-item:nth-child(3n+3) {
		margin-right: 4%;
	}
	.widget-ChildrenGallery .children-gallery-list-item:nth-child(3n+3) {
		margin-right: 0;
	}
}

@media screen and (max-width: 620px) {
	.widget-ChildrenGallery .children-gallery-list-item {
		width: 48%;
	}
	.widget-ChildrenGallery .children-gallery-list-item:nth-child(3n+3) {
		margin-right: 4%;
	}
	.widget-ChildrenGallery .children-gallery-list-item:nth-child(2n+2) {
		margin-right: 0;
	}
}

