
@use "../../00-settings" as *;

@mixin reverse-text($body-color: $color-white, $hover-color: rgba($color-white, 0.75), $heading-color: $color-white) {
    color: $body-color;

    :where(a) {
        color: currentColor;
    }
    :where(a):hover ,
    :where(a):focus ,
    :where(a):active {
        color: $hover-color;
    }

    :where(h1),
    :where(h2),
    :where(h3),
    :where(h4) {
        color: $heading-color;
    }
}

@mixin default-text {
    color: $color-body-font;

    :where(a) {
        color: var(--text-link-colour);
    }
    :where(a):hover ,
    :where(a):focus ,
    :where(a):active {
        color: var(--text-link-hover-colour);
        opacity: 1;
    }
}


@mixin subtle-links($color: currentColor, $hover-color: currentColor) {
    color: $color;
    text-decoration-color: transparent;
    transition:
        color $link-transition,
        text-decoration-color $link-transition;

    &:hover,
    &:focus,
    &:active {
        color: $hover-color;
        text-decoration-color: $hover-color;
    }
}

.reverse-text {
    @include reverse-text();
}
