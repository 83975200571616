/* ---- TinyMCE tweaks ---- */
.mce-panel {
	border-color: #CED2DC !important;
}
.mce-toolbar-grp,
.mce-flow-layout {
	background-color: #EAECF1 !important;
}
.mce-tinymce{
	border-radius: 5px !important;
	overflow: hidden !important;
}
.mce-btn {
	border: 1px solid #EAECF1 !important;
	background-color: #EAECF1 !important;
}
.mce-btn:hover,
.mce-btn:focus{
	color: #333;
	background-color: #DDE0E6 !important;
	border-color: #C6CAD2 !important;
}
.mce-btn.mce-active,
.mce-btn.mce-active:hover{
	background-color: #CED2DC !important;
	border-color: #B5B8C1 !important;
}
.mce-btn:active{
	background-color:#e0e0e0 !important;
	border-color: #ccc !important;
}
.mce-menu-item:hover, .mce-menu-item.mce-selected, .mce-menu-item:focus {
	background-color: #358AAB !important;
	color: #FFF !important;
}
