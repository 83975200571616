.table__no-styles {
    border-collapse: separate !important;
    border: none !important;
    background: none !important;
    min-width: 0 !important;
}

.table__no-styles th,
.table__no-styles thead td,
.table__no-styles td {
    padding: 0 !important;
    border: 0 !important;
    background: none !important;
}

