@use "../../00-settings" as *;

/* ---- Icon circle list ---- */
@mixin icon-circle-list {
	list-style: none;
	margin: 25px 0;
	padding: 0;
	line-height: 1.5;
}
.icon-circle-list {
	@include icon-circle-list;
}
.icon-circle-list__item {
	margin-bottom: 12px;
}

.icon-circle-list a,
.icon-circle-list span.icon-before {
	text-decoration: none;
	color: inherit;
	position: relative;
	white-space: nowrap;
}


@mixin circle-icon {
	background-color: $color-01;
	border-radius: 50%;
	display: block;
	text-align: center;
	color: #FFF;
	display: inline-block;
	vertical-align: top;
	height: 24px;
	width: 24px;
	font-size: 21px;
	line-height: 1.1;

	@include screen($bp768) {
		height: 28px;
		width: 28px;
		font-size: 22px;
		line-height: 1.3;
	}

	@include screen($bp992) {
		height: 40px;
		width: 40px;
		line-height: 1.5;
		font-size: 28px;
	}
}

.icon-circle-list__item a:before,
.icon-circle-list__item span.icon-before:before,
.icon-circle:before {
	@include circle-icon;
}


/* With text */
.icon-circle-list__item--text {
	display: flex;
	align-items: center;
}

.icon-circle-list__item--text a:before,
.icon-circle-list__item--text span.icon-before:before {
	margin-top: 0;
	margin-right: 10px;

	@include screen($bp624) {
		margin-top: -4px;
		margin-right: 9px;
	}

	@include screen($bp768) {
		margin-right: 8px;
	}

	@include screen($bp992) {
		margin-right: 9px;
	}

	@include screen($bp1200) {
		margin-right: 13px;
	}
}

/* -- Inline -- */
.icon-circle-list--inline {
	margin: 0;
	line-height: 1.5;

	@include screen($bp1200) {
		line-height: 1.2;
	}

	.icon-circle-list__item {
		display: inline-block;
		margin-bottom: 0;
		margin-right: 2px;
		line-height: 1.6;

		@include screen($bp992) {
			margin-right: 5px;
			line-height: inherit;
		}
	}

	.icon-circle-list__item--text a:before,
	.icon-circle-list__item--text span.icon-before:before {
		margin-top: 0;
	}

	.icon-circle-list__item:last-child {
		margin-right: 0;
	}

	&.icon-circle-list--large {
		line-height: 1.6;

		@include screen($bp1200) {
			line-height: 1.9;
		}
	}
}


/* Lightblue */
.icon-circle-list__item.icon-circle-list__item--lightblue a:before,
.icon-circle-list__item.icon-circle-list__item--lightblue span.icon-before:before,
.icon-circle--lightblue:before {
	background-color: $color-00-pale;
	color: $color-00;
}
.icon-circle-list__item.icon-circle-list__item--lightblue a:hover:before {
	background-color: lighten($color-00-pale, 9%);
}

.icon-circle-list__item.icon-circle-list__item--lightblue-white a:before,
.icon-circle-list__item.icon-circle-list__item--lightblue-white span.icon-before:before,
.icon-circle--lightblue:before {
	background-color: $color-00-pale;
	color: $color-white;
}
.icon-circle-list__item.icon-circle-list__item--lightblue-white a:hover:before {
	background-color: lighten($color-00-pale, 9%);
}

/* White */
.icon-circle-list__item.icon-circle-list__item--white a:before,
.icon-circle-list__item.icon-circle-list__item--white span.icon-before:before,
.icon-circle--white:before {
	background-color: #FFF;
	color: $color-00;
}
.icon-circle-list__item.icon-circle-list__item--white a,
.icon-circle-list__item.icon-circle-list__item--white span.icon-before,
.icon-circle--white {
	color: #FFF;
}
.icon-circle-list__item.icon-circle-list__item--white a:hover:before {
	color: #566582;
}

/* White green */
.icon-circle-list__item.icon-circle-list__item--whitegreen a:before,
.icon-circle-list__item.icon-circle-list__item--whitegreen span.icon-before:before,
.icon-circle--whitegreen:before {
	background-color: #FFF;
	color: #61b258;
}
.icon-circle-list__item.icon-circle-list__item--whitegreen a,
.icon-circle-list__item.icon-circle-list__item--whitegreen span.icon-before,
.icon-circle--whitegreen {
	color: #FFF;
}
.icon-circle-list__item.icon-circle-list__item--whitegreen a:hover:before {
	color: #7aca71;
}

/* Small */
.icon-circle-list--small a:before,
.icon-circle-list--small span.icon-before:before,
.icon-circle--small:before {
	height: 30px;
	width: 30px;
	font-size: 22px;
	line-height: 1.3;
}

@include screen($bp1200) {
/* Large until BP2, then regular */
	.icon-circle-list--large,
	.icon-circle--large {
		font-size: 2.7rem;
		line-height: 2.2;
	}
	.icon-circle-list--large a:before,
	.icon-circle-list--large span.icon-before:before,
	.icon-circle--large:before {
		height: 56px;
		width: 56px;
		font-size: 37px;
		line-height: 1.5;
	}

	.icon-circle-list--large.icon-circle-list--inline .icon-circle-list__item {
		margin-right: 17px;
	}
}
