.field-label__required {
	text-indent: -9999px;
	line-height: 0;
	display: inline-block;
}

.field-label__required:after {
	content: "*";
	text-indent: 0;
	display: block;
	line-height: inherit;
}
