
@use "../../00-settings" as *;

:root {
    --text-link-colour: #{$color-03};
    --text-link-hover-colour: #{$color-01};
}

a {
    color: var(--text-link-colour);
    word-break: break-word;
    transition:
        color $link-transition,
        opacity $link-transition;

    &:hover,
    &:active,
    &:focus {
        color: var(--text-link-hover-colour);
    }
}

/* Address `outline` inconsistency between Chrome and other browsers. */
a:focus {
    outline: thin dotted;
}

/* Improve readability when focused and also mouse hovered in all browsers. */
a:active,
a:hover {
    outline: 0;
}

/* Include file type and size in document links */
a.document::after {
    content: " (" attr(data-ext) " " attr(data-size) ")";
}

:target {
    animation: highlightPulse 700ms ease;
    outline: 3px solid $color-utility-selection;
    outline-offset: 4px;
}

.active-target {
    animation: highlightPulseOutline 2100ms ease;
}


.subtle-link {
    color: currentColor;
    text-decoration-color: transparent;
    transition:
        text-decoration-color $link-transition;

    &:hover,
    &:active,
    &:focus {
        text-decoration-color: var(--text-link-hover-colour);
    }
}
