@use "../../../00-settings" as *;
@use "../../../02-components/buttons-and-links/_button-mixins.scss" as *;
@use "../../../02-components/forms/_form-mixins.scss" as *;
@use "../../../02-components/forms/fields/_fieldsets.scss" as *;

.field--submit {
    margin-top: $spacing*1.5;
    margin-bottom: var(--field-vertical-whitespace);
    background-color: $color-grey-01;
    gap: $spacing;

    :where(button):last-child {
        margin-left: auto;
    }
}

/* ---- Submit Bar ---- */
.submit-bar {
	text-align: right;
	background: $color-grey-01;
	padding: 14px;
}

.sidebar .submit-bar {
	padding: 10px 0;
}

.submit-bar a {
	margin-right: 20px;
}

