/* -----------------------------
File Path
-------------------------------- */
$file-path: '../images/';


/* -----------------------------
Spacing
-------------------------------- */
$spacing:                   8px;

$spacing-xxsmall:           $spacing*.25;

$spacing-xsmall:            $spacing*.5;

$spacing-small:             $spacing;

$spacing-medium:            $spacing*2;

$spacing-large:             $spacing*3;

$spacing-xlarge:            $spacing*4;

$spacing-xxlarge:           $spacing*8;


$spacing-sizes: (
    spacing:    $spacing,
    xxsmall:    $spacing-xxsmall,
    xsmall:     $spacing-xsmall,
    small:      $spacing-small,
    medium:     $spacing-medium,
    large:      $spacing-large,
    xlarge:     $spacing-xlarge,
    xxlarge:    $spacing-xxlarge
) !default;


/* -----------------------------
style variables
-------------------------------- */
$standard-radius:            4px; // note: button radius set manually

$form-spacing:            8px;

$link-transition:   250ms ease-in-out;

$link-transition-duration: 250ms;


/* -----------------------------
Header
-------------------------------- */
$header-transition: 350ms ease;

$header-index: 100;


/* -----------------------------
Page
-------------------------------- */
$max-page-width: 2400px;
