
/* ---- image gallery ---- */
.widget-ImageGallery {
	margin: 30px 0;
}


/* ---- simple image gallery ---- */
.widget-GallerySimple {
	margin: 15px -10px;
}

.widget-GallerySimple h3 {
	padding: 10px;
}

.widget-GallerySimple .gallery-simple-thumb {
	width: 33.333%;
	padding: 10px;
	float: left;
}
.widget-GallerySimple .gallery-simple-thumb img {
	width: 100%;
}
