
@use "../../00-settings" as *;
@use "../../05-utilities/helpers/utility-mixins" as *;

.website-attribution {
    padding: 20px 0;
    background-color: $color-grey-10;
    color: $color-white;

    &__inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: var(--milli-font-size);
        margin-left: -4px;
        margin-right: -4px;
        margin-bottom: 0;
    }

    &__item {
        display: block;
        flex: 0 0 auto;
        padding-left: 4px;
        padding-right: 4px;
        transition: filter 250ms ease;

        @supports(filter: grayscale(1)){
            filter: grayscale(1);

            &:hover,
            &:focus,
            &:active {
                filter: grayscale(0);
            }
        }
    }

    &__img {
        width: 20px;
        height: auto;
    }

    &__note {
        display: block;
        flex: 0 0 100%;
        margin-bottom: 1em;

        @include vis-hidden;
    }
}
