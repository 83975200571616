
@use "../../00-settings" as *;

:root {
    --default-container: 90vw;
    --default-container-gutter: 5vw;

    --edge-container: var(--default-container);
    --edge-container-gutter: var(--default-container-gutter);
}

@include screen($bp560) {
    :root {
        --default-container: 92vw;
        --default-container-gutter: 4vw;

        --edge-container: calc(100vw - 64px);
        --edge-container-gutter: 32px;
    }
}

@include screen($bp1240) {
    :root {
        --default-container: 1140px;
        --default-container-gutter: calc(50vw - (var(--default-container) / 2));
    }
}

@include screen($bp1920) {
    :root {
        --edge-container: 1856px;
        --edge-container-gutter: calc(50vw - (var(--edge-container) / 2));
    }

}

@include screen($bp2400) {
    :root {
        --default-container-gutter: calc((#{$max-page-width} - var(--default-container)) / 2);
        --edge-container-gutter: calc((#{$max-page-width} - var(--edge-container)) / 2);
    }
}

.container {
    width: var(--default-container);
    margin: 0 auto;
}

/* [01] The bleed utility classes now live in the background colours file */

// Legacy fixed width container
// /* BP 1 */
// @media screen and (max-width: 1240px) {
// 	.container {
// 		width: 900px;
// 	}
// }
// /* BP 2 */
// @media screen and (max-width: 1000px) {
// 	.container {
// 		width: 700px;
// 	}
// }
// /* BP 3 */
// @media screen and (max-width: 760px) {
// 	.container {
// 		width: 550px;
// 	}
// }
// /* BP 4 */
// @media screen and (max-width: 620px) {
// 	.container {
// 		width: 90%;
// 		margin: 0 5%;
// 	}
// }
