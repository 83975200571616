.quote-box {
	padding: 24px 25px;
	color: #3e8f35;
	font-family: 'Source Sans Pro', sans-serif;
	line-height: 1.3;
	font-size: 2.8rem;
	position: relative;
}
.quote-box p {
	margin: 0;
}
.quote-box:before {
	content: " ";
	position: absolute;
	bottom: 100%;
	left: 25px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 0 20px 20px;
	border-color: transparent transparent #007bff transparent;
}

/* Light green */
.quote-box--lightgreen {
	background-color: #95d38e;
}
.quote-box.quote-box--lightgreen:before {
	border-color: transparent transparent #95d38e transparent;
}

/* BP 1 */
@media screen and (max-width: 1240px) {
	.quote-box {
		padding: 18px 20px;
		font-size: 2.2rem;
	}
}

/* BP 2 */
@media screen and (max-width: 1000px) {
	.quote-box {
		padding: 16px 18px;
		font-size: 1.9rem;
	}
}

/* BP 4 */
@media screen and (max-width: 620px) {
	.quote-box:before {
		border-width: 0 0 11px 11px;
	}
	.quote-box {
		padding: 9px 15px;
		font-size: 1.6rem;
	}
}
