
@use "../../00-settings" as *;
@use "../../02-components/section/section-mixins" as *;

:root {
    --header-height: 40px;
    --header-index: 100;
}

@include screen($bpdesktop) {
    :root {
        --header-height: 44px;
    }
}

@include screen($bp768) {
    :root {
        --header-height: 50px;
    }
}

@include screen($bp992) {
    :root {
        --header-height: 69px;
    }
}

@include screen($bp1200) {
    :root {
        --header-height: 91px;
    }
}

/* ---- Header ---- */
.section--header {
    background-color: $color-white;
	border-bottom: 1px solid $color-grey-01;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	padding-top: 0;
	padding-bottom: 0;
	z-index: 200;
	height: var(--header-height);
    display: flex;
    justify-content: center;
    align-items: center;

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include screen($bp768) {
            justify-content: flex-end;
        }
    }
}

.section--header__logo {
	flex: 0 1 auto;

    @include screen($bp768) {
        margin-right: auto; // align-self: flex-start;
    }
}

.section--header__logo__link {
	text-decoration: none;
	display: flex;
    margin-top: 0;

    @include screen($bpdesktop) {
        margin-top: 2px;
    }
}

.header__logo {
    height: 40px;

    @include screen($bp768) {
        height: 44px;
    }

    @include screen($bp992) {
        height: 60px;
    }
}

.section--header__logo__link:hover,
.section--header__logo__link:focus {
	color: $color-01;
}

.header__contact-links {
    display: none;

    @include screen($bp992) {
        display: block;
        font-family: 'Source Sans Pro', sans-serif;
        border-left: 1px solid $color-00-pale;
        margin-left: 7px;
        padding-left: 7px;
        line-height: 1.7;
        font-size: 1.8rem;
    }

    @include screen($bp1200) {
        line-height: 1.5;
        font-size: 2.6rem;
        padding-left: 10px;
        margin-left: 10px;
    }

    @include screen($bp1400) {
        margin-left: 12px;
        padding-left: 12px;
        font-size: 3.1rem;
	    line-height: 1.2;
    }
}

.header__contact-links {
    .icon-circle-list {
        display: flex;
        align-items: center;

        a {
            display: block;

            &:before {
                @include screen($bp1199, 'max') {
                    height: 30px;
                    width: 30px;
                    font-size: 22px;
                    line-height: 1.3;
                }
            }
        }
    }

    .icon-circle-list__item--text a {
        display: flex;
        align-items: center;
    }
}

@include screen($bpmobile, 'max') {
	.section--header {
		display: none;
	}
}
