@charset "UTF-8";
/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/*
    You can see a clear demo of use/forwards here...
    https://www.youtube.com/watch?v=CR-a8upNjJ0&t=286s
*/
/* -----------------------------
File Path
-------------------------------- */
/* -----------------------------
Spacing
-------------------------------- */
/* -----------------------------
style variables
-------------------------------- */
/* -----------------------------
Header
-------------------------------- */
/* -----------------------------
Page
-------------------------------- */
/*
    Breakpoint variables
*/
/*
    Variable Example
*/
/*
    Breakpoint Mixins
*/
/*
    Joint hover and Breakpoint Mixin
*/
/* ------------------------------------------------------
**** Fonts
------------------------------------------------------ */
/* ---- Webfonts ---- */
/*

Source Sans Pro
Google fonts
font-family: 'Source Sans Pro', sans-serif;

DIN Next
Fonts.com
font-family: 'DIN Next W01', sans-serif;

Neue Helvetica
Fonts.com
font-family: 'Neue Helvetica W01', sans-serif;

Montserrat
Field labels

*/
:root {
  --body-font-size: 1.5rem;
  --body-line-height: 1.5;
  --large-font-size: 1.6rem;
  --h1-font-size: 2rem;
  --h1-line-height: 1.2;
  --h2-font-size: 1.8rem;
  --h2-line-height: 1.2;
  --h3-font-size: 1.8rem;
  --h3-line-height: 1.2;
  --h4-font-size: 1.8rem;
  --h4-line-height: 1.2;
  --h5-font-size: 1.7rem;
  --h5-line-height: 1.2;
  --h6-font-size: 1.7rem;
  --h6-line-height: 1.2;
  /* [02] */
  --giga-font-size: 1.8rem;
  --mega-font-size: 4.4rem;
  --kilo-font-size: 4rem;
  --milli-font-size: 1.3rem;
  --centi-font-size: 1.4rem;
}

@media screen and (min-width: 22.5em) {
  :root {
    --h1-font-size: 2.2rem;
    --h2-font-size: 2rem;
    --h3-font-size: 1.9rem;
    --giga-font-size: 2.8rem;
  }
}
@media screen and (min-width: 39em) {
  :root {
    --giga-font-size: 4.3rem;
  }
}
@media screen and (min-width: 48em) {
  :root {
    --body-font-size: 1.6rem;
    --large-font-size: 1.8rem;
    --h1-font-size: 2.6rem;
    --h2-font-size: 2.2rem;
  }
}
@media screen and (min-width: 62em) {
  :root {
    --body-font-size: 1.8rem;
    --large-font-size: 2rem;
    --h1-font-size: 3rem;
    --h2-font-size: 2.8rem;
    --giga-font-size: 6.7rem;
  }
}
@media screen and (min-width: 75em) {
  :root {
    --h1-font-size: 4rem;
    --h2-font-size: 3.2rem;
    --h3-font-size: 1.9rem;
    --h4-font-size: 1.8rem;
    --h5-font-size: 1.7rem;
    --h6-font-size: 1.7rem;
    --giga-font-size: 7.3rem;
  }
}
/*
    Basic system font stacks
*/
/*
    Brand fonts
*/
/*
    Font stacks
*/
/*
    Font Family key for Fractal
*/
/* Weights */
/* Size */
/* -----
* NOTES
* [02]
* A series of classes for setting massive type; for use in heroes, mastheads,
* promos, etc.
* As per: csswizardry.com/2012/02/pragmatic-practical-font-sizing-in-css
------ */
/*
    Font Mixins
*/
/* ----- Brand colours ----- */
/* ------------------------------------------------------
**** Animations
------------------------------------------------------ */
/* ---- Mobile menu button ---- */
@-webkit-keyframes mobileMenuBtnTopClose {
  0% {
    -ms-transform: translate(0, 9px) rotate(45deg);
    -webkit-transform: translate(0, 9px) rotate(45deg);
    transform: translate(0, 9px) rotate(45deg);
  }
  50%, 70% {
    -ms-transform: translate(0, 9px);
    -webkit-transform: translate(0, 9px);
    transform: translate(0, 9px);
  }
  100% {
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@keyframes mobileMenuBtnTopClose {
  0% {
    -ms-transform: translate(0, 9px) rotate(45deg);
    -webkit-transform: translate(0, 9px) rotate(45deg);
    transform: translate(0, 9px) rotate(45deg);
  }
  50%, 70% {
    -ms-transform: translate(0, 9px);
    -webkit-transform: translate(0, 9px);
    transform: translate(0, 9px);
  }
  100% {
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@-webkit-keyframes mobileMenuBtnTopOpen {
  0% {
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50%, 70% {
    -ms-transform: translate(0, 9px);
    -webkit-transform: translate(0, 9px);
    transform: translate(0, 9px);
  }
  100% {
    -ms-transform: translate(0, 9px) rotate(45deg);
    -webkit-transform: translate(0, 9px) rotate(45deg);
    transform: translate(0, 9px) rotate(45deg);
  }
}
@keyframes mobileMenuBtnTopOpen {
  0% {
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50%, 70% {
    -ms-transform: translate(0, 9px);
    -webkit-transform: translate(0, 9px);
    transform: translate(0, 9px);
  }
  100% {
    -ms-transform: translate(0, 9px) rotate(45deg);
    -webkit-transform: translate(0, 9px) rotate(45deg);
    transform: translate(0, 9px) rotate(45deg);
  }
}
@-webkit-keyframes mobileMenuBtnBottomClose {
  0% {
    -ms-transform: translate(0, -9px) rotate(-45deg);
    -webkit-transform: translate(0, -9px) rotate(-45deg);
    transform: translate(0, -9px) rotate(-45deg);
  }
  50%, 70% {
    -ms-transform: translate(0, -9px);
    -webkit-transform: translate(0, -9px);
    transform: translate(0, -9px);
  }
  100% {
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@keyframes mobileMenuBtnBottomClose {
  0% {
    -ms-transform: translate(0, -9px) rotate(-45deg);
    -webkit-transform: translate(0, -9px) rotate(-45deg);
    transform: translate(0, -9px) rotate(-45deg);
  }
  50%, 70% {
    -ms-transform: translate(0, -9px);
    -webkit-transform: translate(0, -9px);
    transform: translate(0, -9px);
  }
  100% {
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@-webkit-keyframes mobileMenuBtnBottomOpen {
  0% {
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50%, 70% {
    -ms-transform: translate(0, -9px);
    -webkit-transform: translate(0, -9px);
    transform: translate(0, -9px);
  }
  100% {
    -ms-transform: translate(0, -9px) rotate(-45deg);
    -webkit-transform: translate(0, -9px) rotate(-45deg);
    transform: translate(0, -9px) rotate(-45deg);
  }
}
@keyframes mobileMenuBtnBottomOpen {
  0% {
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50%, 70% {
    -ms-transform: translate(0, -9px);
    -webkit-transform: translate(0, -9px);
    transform: translate(0, -9px);
  }
  100% {
    -ms-transform: translate(0, -9px) rotate(-45deg);
    -webkit-transform: translate(0, -9px) rotate(-45deg);
    transform: translate(0, -9px) rotate(-45deg);
  }
}
@-webkit-keyframes mobileMenuBtnMiddleClose {
  0%, 40% {
    opacity: 0;
  }
  50%, 100% {
    opacity: 1;
  }
}
@keyframes mobileMenuBtnMiddleClose {
  0%, 40% {
    opacity: 0;
  }
  50%, 100% {
    opacity: 1;
  }
}
@-webkit-keyframes mobileMenuBtnMiddleOpen {
  0%, 40% {
    opacity: 1;
  }
  50%, 100% {
    opacity: 0;
  }
}
@keyframes mobileMenuBtnMiddleOpen {
  0%, 40% {
    opacity: 1;
  }
  50%, 100% {
    opacity: 0;
  }
}
/* Fade in */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* ---- Content Entry ---- */
/* Fade in down */
@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px);
  }
}
/* Fade in up */
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translateY(25px);
    transform: translateY(25px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
/* Fade in up margin */
@keyframes fadeInUpMargin {
  from {
    opacity: 0;
    margin-bottom: -20px;
  }
  to {
    opacity: 1;
    margin-bottom: 0;
  }
}
/* Grow in fade */
@keyframes growInFade {
  from {
    opacity: 0;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
/* ---- Highlight pulse ---- */
@keyframes highlightPulse {
  40% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
}
/* ---- Highlight pulse with outline ---- */
@keyframes highlightPulseOutline {
  0% {
    outline: 3px solid #FFC57D;
    outline-offset: 4px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  13% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
  33% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    outline: 3px solid #FFC57D;
    outline-offset: 4px;
  }
  100% {
    outline: 3px solid transparent;
    outline-offset: 4px;
  }
}
html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
* {
  margin: 0;
}

html,
body {
  background: #ffffff;
  height: 100%;
  scroll-behavior: smooth;
}

html {
  font-size: 62.5%;
}

body {
  line-height: var(--body-line-height);
  font-size: var(--body-font-size);
  color: #4b4b4b;
  font-family: "Neue Helvetica W01", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@viewport {
  width: device-width;
  zoom: 1;
}
img {
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
table {
  border-collapse: collapse;
  border: 1px solid #D7D7D7;
  width: 100%;
}
.content-block table {
  margin-bottom: 24px;
}

th,
td {
  padding: 5px 10px;
  border: 1px solid #D7D7D7;
}

th,
thead td {
  background: #f6f6f6;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/* ---- Content tables ---- */
.table--content-standard {
  width: 100%;
  margin: 2rem 0;
}

.table--content-standard caption {
  text-align: left;
  padding-bottom: 13px;
}

.table--content-standard th,
.table--content-standard thead td {
  font-weight: bold;
  background-color: #f6f6f6;
}

.table--content-standard td {
  background-color: #ffffff;
}

.table--content-standard th,
.table--content-standard td {
  border: 1px solid #D7D7D7;
  padding: 12px 15px;
  text-align: left;
}

/* Small */
.table--content-standard.table--content-small {
  font-size: 1.3rem;
}

.table--content-standard.table--content-small th,
.table--content-standard.table--content-small td {
  padding: 5px 10px;
}

.table--content-standard.table--content-small th,
.table--content-standard.table--content-small thead td {
  font-size: 1.4rem;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/* ---- Responsive table ---- */
table.responsive {
  overflow: auto;
  overflow-y: hidden;
  overflow-x: auto;
  display: block;
}

.table--content-small {
  font-size: 1.3rem;
}

.table--content-small th,
.table--content-small td {
  padding: 5px 10px;
}

.table--content-small th,
.table--content-small thead td {
  font-size: 1.4rem;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
.table--header-column th {
  text-align: left;
}

.table--row-striped {
  border: 1px solid transparent;
}
.table--row-striped th,
.table--row-striped td {
  border: 1px solid transparent;
}
.table--row-striped th,
.table--row-striped thead td {
  background: transparent;
}
.table--row-striped tr:nth-child(odd) td,
.table--row-striped tr:nth-child(odd) th {
  background-color: #ffffff;
}
.table--row-striped tr:nth-child(even):last-child td, .table--row-striped tr:nth-child(even):last-child th {
  border-bottom-color: #ffffff;
  border-bottom-width: 2px;
}
.table--row-striped tr:first-child td:first-child,
.table--row-striped tr:first-child th:first-child {
  border-top-left-radius: 2px;
}
.table--row-striped tr:first-child td:last-child,
.table--row-striped tr:first-child th:last-child {
  border-top-right-radius: 2px;
}
.table--row-striped tr:last-child td:first-child,
.table--row-striped tr:last-child th:first-child {
  border-bottom-left-radius: 2px;
}
.table--row-striped tr:last-child td:last-child,
.table--row-striped tr:last-child th:last-child {
  border-bottom-right-radius: 2px;
}

[data-hidden-by-rules=true] {
  display: none;
}

.table__no-styles {
  border-collapse: separate !important;
  border: none !important;
  background: none !important;
  min-width: 0 !important;
}

.table__no-styles th,
.table__no-styles thead td,
.table__no-styles td {
  padding: 0 !important;
  border: 0 !important;
  background: none !important;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin: 0 0 var(--paragraph-break);
  margin: 1.6rem 0 1.2rem;
  font-family: "DIN Next W01", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 100;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-weight: normal;
  line-height: 1;
  color: #8a7b7b;
}

p + h1,
p + h2,
p + h3,
p + h4,
p + h5,
p + h6 {
  margin-top: 3rem;
}

h1, .h1 {
  font-family: "DIN Next W01", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 100;
  font-size: var(--h1-font-size);
  line-height: var(--h1-line-height);
  text-transform: uppercase;
  margin: 0.75em 0 var(--large-heading-break) 0;
  color: #2c364a;
}

h2, .h2 {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 400;
  font-size: var(--h2-font-size);
  line-height: var(--h2-line-height);
  margin: 0.66em 0 var(--large-heading-break);
  color: #0073BC;
}

h3, .h3 {
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  color: #0073BC;
}

h4, .h4 {
  font-size: var(--h4-font-size);
  line-height: var(--h4-line-height);
  color: inherit;
}

h5, .h5 {
  font-size: var(--h5-font-size);
  line-height: var(--h5-line-height);
  color: inherit;
}

h6, .h6 {
  font-size: var(--h6-font-size);
  line-height: var(--h6-line-height);
  color: inherit;
}

p,
ol,
ul,
dl,
address {
  margin: 0 0 var(--body-font-size);
}

small {
  font-size: var(--milli-font-size);
}

.heading-inline {
  display: inline;
  float: left;
  padding: 0;
}

.heading-w-subtitle {
  margin-bottom: 0;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #EEE;
  margin: 2em 0;
  padding: 0;
  clear: right;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/* ---- Text level elements ---- */
abbr[title] {
  border-bottom: 1px dotted #b8b8b8;
  cursor: help;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

ins {
  background-color: #f6f6f6;
  color: #4b4b4b;
  text-decoration: none;
}

mark {
  background-color: #f6f6f6;
  color: #4b4b4b;
  font-style: italic;
  font-weight: bold;
}

pre,
code,
kbd,
samp {
  font-family: Monaco, Courier New, monospace;
  color: #4b4b4b;
  background: #f6f6f6;
  background: rgba(0, 0, 0, 0.07);
  padding: 0 2px;
  margin: 0 -2px;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

small {
  font-size: 1.28rem;
}

:root {
  --paragraph-break: 1.5rem;
  --large-heading-break: 1rem;
}

@media screen and (min-width: 48em) {
  :root {
    --large-heading-break: 1.4rem;
  }
}
@media screen and (min-width: 62em) {
  :root {
    --large-heading-break: 1.7rem;
  }
}
@media screen and (min-width: 75em) {
  :root {
    --large-heading-break: 1.9rem;
  }
}
p,
address {
  margin: 0 0 var(--paragraph-break);
}

small {
  font-size: var(--milli-font-size);
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
.reverse-text {
  color: #ffffff;
}
.reverse-text :where(a) {
  color: currentColor;
}
.reverse-text :where(a):hover,
.reverse-text :where(a):focus,
.reverse-text :where(a):active {
  color: rgba(255, 255, 255, 0.75);
}
.reverse-text :where(h1),
.reverse-text :where(h2),
.reverse-text :where(h3),
.reverse-text :where(h4) {
  color: #ffffff;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/* ---- Text selection ---- */
::-moz-selection {
  color: #000;
  background: #FFC57D;
}

::selection {
  color: #000;
  background: #FFC57D;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/* ---- Line title ---- */
.line-title {
  margin: 0 0 45px;
}

.line-title__title {
  position: relative;
}

.line-title__title:after {
  content: " ";
  display: block;
  height: 18px;
  margin-top: 18px;
}

.line-title__title h2,
.line-title__title .h2,
.line-title__title .line-title__title__copy {
  margin: 0;
  font-size: 2rem;
  color: #2c364a;
  text-transform: uppercase;
  line-height: 1.2;
  font-family: "DIN Next W01", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 100;
}

.line-title__link {
  float: right;
  margin-left: 50px;
}

/* Side */
.line-title.line-title--side .line-title__title {
  padding-bottom: 26px;
}

.line-title.line-title--side .line-title__title:after {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 1000px;
  background-image: url(../images/texture-lines-lightblue.png);
}

.line-title.line-title--side .line-title__title {
  display: inline-block;
}

/* Dark blue */
.line-title.line-title--darkblue .line-title__title:after {
  background-image: url(../images/texture-lines-blue.png);
}

/* Green */
.line-title.line-title--green .line-title__title:after {
  background-image: url(../images/texture-lines-green.png);
}

/* BP 1 */
@media screen and (max-width: 1240px) {
  .line-title {
    margin-bottom: 27px;
  }
}
/* BP 3 */
@media screen and (max-width: 760px) {
  .line-title {
    margin-bottom: 20px;
  }
  .line-title__title:after {
    height: 12px;
    margin-top: 12px;
  }
  .line-title.line-title--side .line-title__title {
    padding-bottom: 18px;
  }
}
/* BP 4 */
@media screen and (max-width: 620px) {
  .line-title {
    margin-bottom: 15px;
  }
  .line-title__title:after {
    height: 8px;
    margin-top: 8px;
  }
  .line-title.line-title--side .line-title__title {
    padding-bottom: 12px;
  }
  .line-title__link {
    float: none;
    margin: 20px 0 10px;
  }
  .line-title.line-title--side .line-title__title {
    display: block;
  }
}
/* BP 3 */
@media screen and (max-width: 760px) {
  .inner-page-title .line-title__title {
    display: none;
  }
}
/* Green */
.line-title.line-title--green .line-title__title:after {
  background-image: url(../images/texture-lines-green.png);
}

/* BP 1 */
@media screen and (max-width: 1240px) {
  .line-title {
    margin-bottom: 27px;
  }
}
/* BP 3 */
@media screen and (max-width: 760px) {
  .line-title {
    margin-bottom: 20px;
  }
  .line-title__title:after {
    height: 12px;
    margin-top: 12px;
  }
}
/* BP 4 */
@media screen and (max-width: 620px) {
  .line-title {
    margin-bottom: 15px;
  }
  .line-title__title:after {
    height: 8px;
    margin-top: 8px;
  }
}
/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/* ---- Inner banner ---- */
.section--inner-banner {
  padding-top: 23px;
  padding-bottom: 65px;
}

.section--inner-banner:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(../images/texture-dots.svg);
  opacity: 0.1;
}

.section--inner-banner__inner {
  position: relative;
}

.section--inner-banner__title {
  color: #fff;
  position: relative;
  display: inline-block;
  padding-left: 10px;
  margin-bottom: 30px;
}

.section--inner-banner__title:before {
  content: " ";
  display: block;
  position: absolute;
  top: 5px;
  right: 100%;
  width: 530px;
  height: 26px;
  background-image: url(../images/texture-lines-blue.png);
}

.section--inner-banner__title:after {
  font-size: 100px;
  position: absolute;
  left: 100%;
  top: -31px;
}

.section--inner-banner__subtitle {
  font-size: 6.3rem;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  line-height: 1;
  margin: 0;
}

/* BP 1 */
@media screen and (max-width: 1240px) {
  .section--inner-banner {
    padding-top: 17px;
    padding-bottom: 47px;
  }
  .section--inner-banner__subtitle {
    font-size: 5rem;
  }
  .section--inner-banner__title:after {
    font-size: 80px;
    top: -24px;
  }
  .section--inner-banner__title:before {
    height: 21px;
  }
}
/* BP 2 */
@media screen and (max-width: 1000px) {
  .section--inner-banner {
    padding-top: 14px;
    padding-bottom: 35px;
  }
  .section--inner-banner__subtitle {
    font-size: 4rem;
  }
  .section--inner-banner__title:after {
    font-size: 70px;
    top: -22px;
  }
  .section--inner-banner__title:before {
    height: 17px;
  }
  .section--inner-banner__title {
    margin-bottom: 20px;
  }
}
/* BP 3 */
@media screen and (max-width: 760px) {
  .section--inner-banner {
    padding-top: 10px;
    padding-bottom: 25px;
  }
  .section--inner-banner__subtitle {
    font-size: 3rem;
  }
  .section--inner-banner__title:after {
    font-size: 60px;
    top: -15px;
  }
  .section--inner-banner__title:before {
    height: 17px;
    top: 5px;
  }
  .section--inner-banner__title {
    margin-bottom: 15px;
  }
}
/* BP 4 */
@media screen and (max-width: 620px) {
  .section--inner-banner__subtitle {
    font-size: 2.4rem;
  }
  .section--inner-banner__title:before {
    height: 16px;
    top: 3px;
  }
  .section--inner-banner__title:after {
    font-size: 50px;
    top: -14px;
  }
}
/* Extra BP */
@media screen and (max-width: 450px) {
  .section--inner-banner__title:before {
    height: 13px;
    top: 4px;
  }
  .section--inner-banner__title:after {
    font-size: 40px;
    top: -9px;
  }
}
:root {
  --button-icon-size: 1.6em;
  --button-icon-top: 0.38em;
  --button-icon-edge-align: 0.38em;
  --button-padding-icon: 2.38em;
}

@media screen and (min-width: 48em) {
  :root {
    --button-icon-size: 1.8em;
    --button-icon-edge-align: 0.5625em;
    --button-padding-icon: 2.875em;
  }
}
/* Buttons with icons */
.button:after,
.button.icon-before:before,
.button.icon-after:after {
  font-size: var(--button-icon-size);
  position: absolute;
  top: var(--button-icon-top);
}

.button.icon-before:before {
  left: var(--button-icon-edge-align);
}

.button.icon-before {
  padding-left: var(--button-padding-icon);
  padding-right: var(--button-padding-horizontal);
}

.button:after,
.button.icon-after:after {
  right: var(--button-icon-edge-align);
}

.button.icon-before:after {
  content: none;
}

/* No icons */
.button.button-no-icon:after,
.button.button-no-icon:before {
  content: none;
}

.button.button-no-icon {
  padding-right: var(--button-padding-horizontal);
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/* ---- Icomoon ---- */
@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon/fonts/icomoon.eot?vyb8er");
  src: url("../fonts/icomoon/fonts/icomoon.eot?vyb8er#iefix") format("embedded-opentype"), url("../fonts/icomoon/fonts/icomoon.ttf?vyb8er") format("truetype"), url("../fonts/icomoon/fonts/icomoon.woff?vyb8er") format("woff"), url("../fonts/icomoon/fonts/icomoon.svg?vyb8er#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}
.icon-before:before,
.icon-after:after {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}

.icon-before.icon-plus:before,
.icon-after.icon-plus:after {
  content: "\e917";
}

.icon-before.icon-minus:before,
.icon-after.icon-minus:after {
  content: "\e916";
}

.icon-before.icon-arrow-down:before,
.icon-after.icon-arrow-down:after {
  content: "\e900";
}

.icon-before.icon-arrow-left:before,
.icon-after.icon-arrow-left:after {
  content: "\e901";
}

.icon-before.icon-arrow-right:before,
.icon-after.icon-arrow-right:after {
  content: "\e902";
}

.icon-before.icon-arrow-up:before,
.icon-after.icon-arrow-up:after {
  content: "\e903";
}

.icon-before.icon-check:before,
.icon-after.icon-check:after {
  content: "\e904";
}

.icon-before.icon-cloud-services:before,
.icon-after.icon-cloud-services:after {
  content: "\e905";
}

.icon-before.icon-double-arrows-down:before,
.icon-after.icon-double-arrows-down:after {
  content: "\e906";
}

.icon-before.icon-double-arrows-left:before,
.icon-after.icon-double-arrows-left:after {
  content: "\e907";
}

.icon-before.icon-double-arrows-right:before,
.icon-after.icon-double-arrows-right:after {
  content: "\e908";
}

.icon-before.icon-double-arrows-up:before,
.icon-after.icon-double-arrows-up:after {
  content: "\e909";
}

.icon-before.icon-email-address:before,
.icon-after.icon-email-address:after {
  content: "\e90a";
}

.icon-before.icon-email:before,
.icon-after.icon-email:after {
  content: "\e90b";
}

.icon-before.icon-facebook:before,
.icon-after.icon-facebook:after {
  content: "\e90c";
}

.icon-before.icon-home:before,
.icon-after.icon-home:after {
  content: "\e90d";
}

.icon-before.icon-integration:before,
.icon-after.icon-integration:after {
  content: "\e90e";
}

.icon-before.icon-linked-in:before,
.icon-after.icon-linked-in:after {
  content: "\e90f";
}

.icon-before.icon-logitech-logo:before,
.icon-after.icon-logitech-logo:after {
  content: "\e910";
}

.icon-before.icon-map-marker:before,
.icon-after.icon-map-marker:after {
  content: "\e911";
}

.icon-before.icon-phone:before,
.icon-after.icon-phone:after {
  content: "\e912";
}

.icon-before.icon-rocket:before,
.icon-after.icon-rocket:after {
  content: "\e913";
}

.icon-before.icon-timer-24hr:before,
.icon-after.icon-timer-24hr:after {
  content: "\e914";
}

.icon-before.icon-work-apps:before,
.icon-after.icon-work-apps:after {
  content: "\e915";
}

:root {
  --button-border-width: 0;
  --button-border-radius: 0;
  --button-padding-vertical: 0.75em;
  --button-padding-horizontal: 1em;
  --button-line-height: 1;
  --button-font-size: var(--milli-font-size);
}

@media screen and (min-width: 48em) {
  :root {
    --button-padding-vertical: 1em;
    --button-padding-horizontal: 1.25em;
    --button-font-size: var(--body-font-size);
    --button-line-height: 1.2;
  }
}
/* --------------------------------
Text Link
----------------------------------- */
/* --------------------------------
Sizes
----------------------------------- */
/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
.button,
.button-green,
.button--accent-01 {
  vertical-align: middle;
  white-space: normal;
  cursor: pointer;
  opacity: 1;
  text-decoration: none;
  font: inherit;
  text-align: center;
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  justify-content: center;
  align-items: center;
  border-radius: var(--button-border-radius);
  position: relative;
  margin: 0;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
  padding-block: calc(var(--button-padding-vertical) + 0.2em) var(--button-padding-vertical);
  padding-left: var(--button-padding-horizontal);
  padding-right: var(--button-padding-icon);
  line-height: var(--button-line-height);
  text-transform: uppercase;
  font-size: var(--button-font-size);
  font-family: "DIN Next W01", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 500;
  color: #ffffff;
  background: #61b258;
  border: var(--button-border-width) solid #61b258;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
}
.button:after,
.button-green:after,
.button--accent-01:after {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  content: "\e908";
}
.button svg,
.button-green svg,
.button--accent-01 svg {
  height: 1em;
  width: 1em;
}
.button svg [stroke*="#"],
.button-green svg [stroke*="#"],
.button--accent-01 svg [stroke*="#"] {
  stroke: currentColor;
}
.button svg [fill*="#"],
.button-green svg [fill*="#"],
.button--accent-01 svg [fill*="#"] {
  fill: currentColor;
}
.button:before, .button:after,
.button-green:before,
.button-green:after,
.button--accent-01:before,
.button--accent-01:after {
  color: #2c364a;
}
.button:hover, .button:focus, .button:active,
.button-green:hover,
.button-green:focus,
.button-green:active,
.button--accent-01:hover,
.button--accent-01:focus,
.button--accent-01:active {
  background-color: #4c9344;
  border-color: #4c9344;
  color: #ffffff;
}
.button:focus,
.button-green:focus,
.button--accent-01:focus {
  outline: 1px auto #0192d0;
}

/* ---- Sizes ---- */
/* Small */
.button--small,
.button-small {
  padding-block: calc(var(--button-padding-vertical) + 0.2em) var(--button-padding-vertical);
  padding-left: var(--button-padding-horizontal);
  padding-right: var(--button-padding-icon);
}
@media screen and (min-width: 48em) {
  .button--small,
  .button-small {
    --button-font-size: var(--milli-font-size);
    --button-padding-vertical: 0.75em;
    --button-padding-horizontal: 1em;
    --button-padding-icon: 2.2em;
    --button-line-height: 1;
    --button-icon-size: 1.6em;
    --button-icon-top: 0.38em;
    --button-icon-edge-align: 0.38em;
    --button-padding-icon: 2.38em;
  }
}

.button--y-small {
  --button-padding-vertical: 0.5em;
}

.button--large {
  padding-block: calc(var(--button-padding-vertical) + 0.2em) var(--button-padding-vertical);
  padding-left: var(--button-padding-horizontal);
  padding-right: var(--button-padding-icon);
}
@media screen and (min-width: 75em) {
  .button--large {
    --button-font-size: 1.9rem;
    --button-padding-vertical: 1.15em;
    --button-padding-horizontal: 1.35em;
    --button-padding-icon: 2.95em;
    --button-icon-size: 1.74em;
    --button-icon-top: 0.74em;
    --button-icon-edge-align: 1em;
    --button-padding-icon: 2.95em;
  }
}

/* ---- Button Colours ---- */
.button-darkgreen {
  vertical-align: middle;
  white-space: normal;
  cursor: pointer;
  opacity: 1;
  text-decoration: none;
  font: inherit;
  text-align: center;
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  justify-content: center;
  align-items: center;
  border-radius: var(--button-border-radius);
  position: relative;
  margin: 0;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
  padding-block: calc(var(--button-padding-vertical) + 0.2em) var(--button-padding-vertical);
  padding-left: var(--button-padding-horizontal);
  padding-right: var(--button-padding-icon);
  line-height: var(--button-line-height);
  text-transform: uppercase;
  font-size: var(--button-font-size);
  font-family: "DIN Next W01", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 500;
  color: #ffffff;
  background: #50a247;
  border: var(--button-border-width) solid #50a247;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
}
.button-darkgreen:after {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  content: "\e908";
}
.button-darkgreen svg {
  height: 1em;
  width: 1em;
}
.button-darkgreen svg [stroke*="#"] {
  stroke: currentColor;
}
.button-darkgreen svg [fill*="#"] {
  fill: currentColor;
}
.button-darkgreen:before, .button-darkgreen:after {
  color: #95d38e;
}
.button-darkgreen:hover, .button-darkgreen:focus, .button-darkgreen:active {
  background-color: #3e7f37;
  border-color: #3e7f37;
  color: #ffffff;
}
.button-darkgreen:focus {
  outline: 1px auto #0192d0;
}

.button--secondary,
.button-blue1 {
  vertical-align: middle;
  white-space: normal;
  cursor: pointer;
  opacity: 1;
  text-decoration: none;
  font: inherit;
  text-align: center;
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  justify-content: center;
  align-items: center;
  border-radius: var(--button-border-radius);
  position: relative;
  margin: 0;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
  padding-block: calc(var(--button-padding-vertical) + 0.2em) var(--button-padding-vertical);
  padding-left: var(--button-padding-horizontal);
  padding-right: var(--button-padding-icon);
  line-height: var(--button-line-height);
  text-transform: uppercase;
  font-size: var(--button-font-size);
  font-family: "DIN Next W01", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 500;
  color: #ffffff;
  background: #0575b3;
  border: var(--button-border-width) solid #0575b3;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
}
.button--secondary:after,
.button-blue1:after {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  content: "\e908";
}
.button--secondary svg,
.button-blue1 svg {
  height: 1em;
  width: 1em;
}
.button--secondary svg [stroke*="#"],
.button-blue1 svg [stroke*="#"] {
  stroke: currentColor;
}
.button--secondary svg [fill*="#"],
.button-blue1 svg [fill*="#"] {
  fill: currentColor;
}
.button--secondary:before, .button--secondary:after,
.button-blue1:before,
.button-blue1:after {
  color: #CC4925;
}
.button--secondary:hover, .button--secondary:focus, .button--secondary:active,
.button-blue1:hover,
.button-blue1:focus,
.button-blue1:active {
  background-color: #045581;
  border-color: #045581;
  color: #ffffff;
}
.button--secondary:focus,
.button-blue1:focus {
  outline: 1px auto #0192d0;
}

.button-blue2 {
  vertical-align: middle;
  white-space: normal;
  cursor: pointer;
  opacity: 1;
  text-decoration: none;
  font: inherit;
  text-align: center;
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  justify-content: center;
  align-items: center;
  border-radius: var(--button-border-radius);
  position: relative;
  margin: 0;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
  padding-block: calc(var(--button-padding-vertical) + 0.2em) var(--button-padding-vertical);
  padding-left: var(--button-padding-horizontal);
  padding-right: var(--button-padding-icon);
  line-height: var(--button-line-height);
  text-transform: uppercase;
  font-size: var(--button-font-size);
  font-family: "DIN Next W01", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 500;
  color: #ffffff;
  background: #4096b5;
  border: var(--button-border-width) solid #4096b5;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
}
.button-blue2:after {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  content: "\e908";
}
.button-blue2 svg {
  height: 1em;
  width: 1em;
}
.button-blue2 svg [stroke*="#"] {
  stroke: currentColor;
}
.button-blue2 svg [fill*="#"] {
  fill: currentColor;
}
.button-blue2:before, .button-blue2:after {
  color: #CC4925;
}
.button-blue2:hover, .button-blue2:focus, .button-blue2:active {
  background-color: #33778f;
  border-color: #33778f;
  color: #ffffff;
}
.button-blue2:focus {
  outline: 1px auto #0192d0;
}

.button--tertiary,
.button-blue3 {
  vertical-align: middle;
  white-space: normal;
  cursor: pointer;
  opacity: 1;
  text-decoration: none;
  font: inherit;
  text-align: center;
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  justify-content: center;
  align-items: center;
  border-radius: var(--button-border-radius);
  position: relative;
  margin: 0;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
  padding-block: calc(var(--button-padding-vertical) + 0.2em) var(--button-padding-vertical);
  padding-left: var(--button-padding-horizontal);
  padding-right: var(--button-padding-icon);
  line-height: var(--button-line-height);
  text-transform: uppercase;
  font-size: var(--button-font-size);
  font-family: "DIN Next W01", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 500;
  color: #ffffff;
  background: #069dd6;
  border: var(--button-border-width) solid #069dd6;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
}
.button--tertiary:after,
.button-blue3:after {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  content: "\e908";
}
.button--tertiary svg,
.button-blue3 svg {
  height: 1em;
  width: 1em;
}
.button--tertiary svg [stroke*="#"],
.button-blue3 svg [stroke*="#"] {
  stroke: currentColor;
}
.button--tertiary svg [fill*="#"],
.button-blue3 svg [fill*="#"] {
  fill: currentColor;
}
.button--tertiary:before, .button--tertiary:after,
.button-blue3:before,
.button-blue3:after {
  color: #CC4925;
}
.button--tertiary:hover, .button--tertiary:focus, .button--tertiary:active,
.button-blue3:hover,
.button-blue3:focus,
.button-blue3:active {
  background-color: #0579a4;
  border-color: #0579a4;
  color: #ffffff;
}
.button--tertiary:focus,
.button-blue3:focus {
  outline: 1px auto #0192d0;
}

.button-grey {
  vertical-align: middle;
  white-space: normal;
  cursor: pointer;
  opacity: 1;
  text-decoration: none;
  font: inherit;
  text-align: center;
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  justify-content: center;
  align-items: center;
  border-radius: var(--button-border-radius);
  position: relative;
  margin: 0;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
  padding-block: calc(var(--button-padding-vertical) + 0.2em) var(--button-padding-vertical);
  padding-left: var(--button-padding-horizontal);
  padding-right: var(--button-padding-icon);
  line-height: var(--button-line-height);
  text-transform: uppercase;
  font-size: var(--button-font-size);
  font-family: "DIN Next W01", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 500;
  color: #24292f;
  background: #EEE;
  border: var(--button-border-width) solid #EEE;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
}
.button-grey:after {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  content: "\e908";
}
.button-grey svg {
  height: 1em;
  width: 1em;
}
.button-grey svg [stroke*="#"] {
  stroke: currentColor;
}
.button-grey svg [fill*="#"] {
  fill: currentColor;
}
.button-grey:before, .button-grey:after {
  color: #696d73;
}
.button-grey:hover, .button-grey:focus, .button-grey:active {
  background-color: #d5d5d5;
  border-color: #d5d5d5;
  color: #24292f;
}
.button-grey:focus {
  outline: 1px auto #0192d0;
}

.button-red {
  vertical-align: middle;
  white-space: normal;
  cursor: pointer;
  opacity: 1;
  text-decoration: none;
  font: inherit;
  text-align: center;
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  justify-content: center;
  align-items: center;
  border-radius: var(--button-border-radius);
  position: relative;
  margin: 0;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
  padding-block: calc(var(--button-padding-vertical) + 0.2em) var(--button-padding-vertical);
  padding-left: var(--button-padding-horizontal);
  padding-right: var(--button-padding-icon);
  line-height: var(--button-line-height);
  text-transform: uppercase;
  font-size: var(--button-font-size);
  font-family: "DIN Next W01", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 500;
  color: #ffffff;
  background: #DC5959;
  border: var(--button-border-width) solid #DC5959;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
}
.button-red:after {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  content: "\e908";
}
.button-red svg {
  height: 1em;
  width: 1em;
}
.button-red svg [stroke*="#"] {
  stroke: currentColor;
}
.button-red svg [fill*="#"] {
  fill: currentColor;
}
.button-red:before, .button-red:after {
  color: #821c1c;
}
.button-red:hover, .button-red:focus, .button-red:active {
  background-color: #d32f2f;
  border-color: #d32f2f;
  color: #ffffff;
}
.button-red:focus {
  outline: 1px auto #0192d0;
}

.button--warning {
  vertical-align: middle;
  white-space: normal;
  cursor: pointer;
  opacity: 1;
  text-decoration: none;
  font: inherit;
  text-align: center;
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  justify-content: center;
  align-items: center;
  border-radius: var(--button-border-radius);
  position: relative;
  margin: 0;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
  padding-block: calc(var(--button-padding-vertical) + 0.2em) var(--button-padding-vertical);
  padding-left: var(--button-padding-horizontal);
  padding-right: var(--button-padding-icon);
  line-height: var(--button-line-height);
  text-transform: uppercase;
  font-size: var(--button-font-size);
  font-family: "DIN Next W01", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 500;
  color: #ffffff;
  background: #E04D4D;
  border: var(--button-border-width) solid #E04D4D;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
}
.button--warning:after {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  content: "\e908";
}
.button--warning svg {
  height: 1em;
  width: 1em;
}
.button--warning svg [stroke*="#"] {
  stroke: currentColor;
}
.button--warning svg [fill*="#"] {
  fill: currentColor;
}
.button--warning:before, .button--warning:after {
  color: #B72323;
}
.button--warning:hover, .button--warning:focus, .button--warning:active {
  background-color: #d52525;
  border-color: #d52525;
  color: #ffffff;
}
.button--warning:focus {
  outline: 1px auto #0192d0;
}

.button--neutral {
  vertical-align: middle;
  white-space: normal;
  cursor: pointer;
  opacity: 1;
  text-decoration: none;
  font: inherit;
  text-align: center;
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  justify-content: center;
  align-items: center;
  border-radius: var(--button-border-radius);
  position: relative;
  margin: 0;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
  padding-block: calc(var(--button-padding-vertical) + 0.2em) var(--button-padding-vertical);
  padding-left: var(--button-padding-horizontal);
  padding-right: var(--button-padding-icon);
  line-height: var(--button-line-height);
  text-transform: uppercase;
  font-size: var(--button-font-size);
  font-family: "DIN Next W01", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 500;
  color: #24292f;
  background: #EEE;
  border: var(--button-border-width) solid #EEE;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
}
.button--neutral:after {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  content: "\e908";
}
.button--neutral svg {
  height: 1em;
  width: 1em;
}
.button--neutral svg [stroke*="#"] {
  stroke: currentColor;
}
.button--neutral svg [fill*="#"] {
  fill: currentColor;
}
.button--neutral:before, .button--neutral:after {
  color: #2c364a;
}
.button--neutral:hover, .button--neutral:focus, .button--neutral:active {
  background-color: #d5d5d5;
  border-color: #d5d5d5;
  color: #24292f;
}
.button--neutral:focus {
  outline: 1px auto #0192d0;
}

.button--positive {
  vertical-align: middle;
  white-space: normal;
  cursor: pointer;
  opacity: 1;
  text-decoration: none;
  font: inherit;
  text-align: center;
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  justify-content: center;
  align-items: center;
  border-radius: var(--button-border-radius);
  position: relative;
  margin: 0;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
  padding-block: calc(var(--button-padding-vertical) + 0.2em) var(--button-padding-vertical);
  padding-left: var(--button-padding-horizontal);
  padding-right: var(--button-padding-icon);
  line-height: var(--button-line-height);
  text-transform: uppercase;
  font-size: var(--button-font-size);
  font-family: "DIN Next W01", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 500;
  color: #ffffff;
  background: #439230;
  border: var(--button-border-width) solid #439230;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
}
.button--positive:after {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  content: "\e908";
}
.button--positive svg {
  height: 1em;
  width: 1em;
}
.button--positive svg [stroke*="#"] {
  stroke: currentColor;
}
.button--positive svg [fill*="#"] {
  fill: currentColor;
}
.button--positive:before, .button--positive:after {
  color: #2c364a;
}
.button--positive:hover, .button--positive:focus, .button--positive:active {
  background-color: #316c23;
  border-color: #316c23;
  color: #ffffff;
}
.button--positive:focus {
  outline: 1px auto #0192d0;
}

/* ---- Button Variations ---- */
.button--block,
.button-block {
  display: block;
  width: 100%;
}

.button[disabled],
.button-disabled,
.button--disabled,
.button-disabled:hover,
.button-disabled:focus,
.button-disabled:active {
  border: none;
  background-image: none;
  opacity: 0.4;
  cursor: default;
  box-shadow: none;
}

/* Firefox: Get rid of the inner focus border */
.button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/* Hover/unhover states */
.button-hover-state {
  display: none;
}

.button:hover .button-hover-state {
  display: inline;
}

.button:hover .button-unhover-state {
  display: none;
}

/* Gets the buttons to line up with form inputs when you want them to */
.button.inline {
  margin-bottom: 0.75em;
}

/* Inline buttons */
.inline-buttons .button {
  margin-right: 8px;
  float: left;
}

.inline-buttons .button:last-child {
  margin-right: 0;
}

/* right */
.inline-buttons.inline-buttons-right {
  text-align: right;
}

.inline-buttons.inline-buttons-right .button {
  margin-right: 0;
  margin-left: 8px;
}

.inline-buttons.inline-buttons-right .button:first-child {
  margin-left: 0;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
:root {
  --text-link-colour: #00AAE9;
  --text-link-hover-colour: #0073BC;
}

a {
  color: var(--text-link-colour);
  word-break: break-word;
  transition: color 250ms ease-in-out, opacity 250ms ease-in-out;
}
a:hover, a:active, a:focus {
  color: var(--text-link-hover-colour);
}

/* Address `outline` inconsistency between Chrome and other browsers. */
a:focus {
  outline: thin dotted;
}

/* Improve readability when focused and also mouse hovered in all browsers. */
a:active,
a:hover {
  outline: 0;
}

/* Include file type and size in document links */
a.document::after {
  content: " (" attr(data-ext) " " attr(data-size) ")";
}

:target {
  animation: highlightPulse 700ms ease;
  outline: 3px solid #FFC57D;
  outline-offset: 4px;
}

.active-target {
  animation: highlightPulseOutline 2100ms ease;
}

.subtle-link {
  color: currentColor;
  text-decoration-color: transparent;
  transition: text-decoration-color 250ms ease-in-out;
}
.subtle-link:hover, .subtle-link:active, .subtle-link:focus {
  text-decoration-color: var(--text-link-hover-colour);
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
:root {
  --default-container: 90vw;
  --default-container-gutter: 5vw;
  --edge-container: var(--default-container);
  --edge-container-gutter: var(--default-container-gutter);
}

@media screen and (min-width: 35em) {
  :root {
    --default-container: 92vw;
    --default-container-gutter: 4vw;
    --edge-container: calc(100vw - 64px);
    --edge-container-gutter: 32px;
  }
}
@media screen and (min-width: 77.5em) {
  :root {
    --default-container: 1140px;
    --default-container-gutter: calc(50vw - (var(--default-container) / 2));
  }
}
@media screen and (min-width: 120em) {
  :root {
    --edge-container: 1856px;
    --edge-container-gutter: calc(50vw - (var(--edge-container) / 2));
  }
}
@media screen and (min-width: 150em) {
  :root {
    --default-container-gutter: calc((2400px - var(--default-container)) / 2);
    --edge-container-gutter: calc((2400px - var(--edge-container)) / 2);
  }
}
.container {
  width: var(--default-container);
  margin: 0 auto;
}

/* [01] The bleed utility classes now live in the background colours file */
/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/* ---- Events ---- */
.event-list__item__date {
  font-family: "DIN Next W01", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  text-transform: uppercase;
}

.event-list__item__date-day {
  background-color: #282f40;
  padding: 6px 10px 3px;
  line-height: 3.4rem;
  font-size: 3.4rem;
  color: #ffffff;
}

.event-list__item__date-month {
  background-color: #0073BC;
  padding: 6px 10px 3px;
  color: #ffffff;
}

.event-list__item__date-year {
  background-color: #0073BC;
  padding: 6px 10px 3px;
  color: #ffffff;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/* -----------------------------
Visibility Utilities
------------------------------*/
/* -----------------------------
Clearfix
------------------------------*/
/* -----------------------------
SVG
------------------------------*/
/* -----------------------------
List Mixins
------------------------------*/
/* -----------------------------
Fallback logo image
------------------------------*/
.website-attribution {
  padding: 20px 0;
  background-color: #24292f;
  color: #ffffff;
}
.website-attribution__inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: var(--milli-font-size);
  margin-left: -4px;
  margin-right: -4px;
  margin-bottom: 0;
}
.website-attribution__item {
  display: block;
  flex: 0 0 auto;
  padding-left: 4px;
  padding-right: 4px;
  transition: filter 250ms ease;
}
@supports (filter: grayscale(1)) {
  .website-attribution__item {
    filter: grayscale(1);
  }
  .website-attribution__item:hover, .website-attribution__item:focus, .website-attribution__item:active {
    filter: grayscale(0);
  }
}
.website-attribution__img {
  width: 20px;
  height: auto;
}
.website-attribution__note {
  display: block;
  flex: 0 0 100%;
  margin-bottom: 1em;
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.website-attribution__note.focusable:active, .website-attribution__note.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
:root {
  --field-border-width: 0;
  --field-border-colour: #EEE;
  --field-border: var(--field-border-width) solid var(--field-border-colour);
  --field-background: #f6f6f6;
  --field-text-color: #4b4b4b;
  --field-padding-vertical: var(--button-padding-vertical);
  --field-padding-horizontal: var(--button-padding-horizontal);
  --field-line-height: calc(var(--button-line-height) * var(--button-font-size));
  --field-radius: 2px;
  --field-vertical-whitespace: 13px;
  --fieldset-input-diameter: calc(var(--body-font-size) * 1.25);
  --fieldset-input-diameter-small: var(--centi-font-size);
  --field-font-size: 1.4rem;
}

@media screen and (min-width: 48em) {
  :root {
    --field-padding-vertical: var(--button-padding-vertical);
    --field-padding-horizontal: var(--button-padding-horizontal);
    --field-font-size: 1.5rem;
    --field-line-height: 1.2;
    --field-vertical-whitespace: 21px;
  }
}
/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/* -------------------------------
Fieldsets Mixins
---------------------------------- */
/* ---- Fieldset ---- */
/* Without labels */
.field-element--checkbox--no-label input[type=checkbox] + label,
.field-element--radio--no-label input[type=radio] + label {
  padding-left: 0;
}

.field-element--checkbox--no-label input[type=checkbox] + label:before,
.field-element--radio--no-label input[type=radio] + label:before {
  margin-left: 0;
  margin-right: 0;
}

.field-element--checkbox--no-label input[type=checkbox]:checked + label:after,
.field-element--radio--no-label input[type=radio]:checked + label:after {
  left: 1px;
}

.field-element--radio--no-label input[type=radio]:checked + label:after {
  margin-left: 4px;
}

/* Large */
.field-element--large input[type=checkbox] + label:before,
.field-element--large input[type=radio] + label:before {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  top: 5px;
}

.field-element--large input[type=radio]:checked + label:after {
  left: 7px;
  top: 12px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
}

.field-element--large input[type=checkbox]:checked + label:after {
  background-position: top -760px left 1px;
  width: 23px;
  height: 23px;
  margin: -2px 0 0 -1px;
}

/* -------------------------------
(Submit) Button Styles
---------------------------------- */
:where(button[type=submit]) {
  vertical-align: middle;
  white-space: normal;
  cursor: pointer;
  opacity: 1;
  text-decoration: none;
  font: inherit;
  text-align: center;
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  justify-content: center;
  align-items: center;
  border-radius: var(--button-border-radius);
  position: relative;
  margin: 0;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
  padding-block: calc(var(--button-padding-vertical) + 0.2em) var(--button-padding-vertical);
  padding-left: var(--button-padding-horizontal);
  padding-right: var(--button-padding-icon);
  line-height: var(--button-line-height);
  text-transform: uppercase;
  font-size: var(--button-font-size);
  font-family: "DIN Next W01", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 500;
  color: #ffffff;
  background: #61b258;
  border: var(--button-border-width) solid #61b258;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
}
:where(button[type=submit]):after {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  content: "\e908";
}
:where(button[type=submit]) svg {
  height: 1em;
  width: 1em;
}
:where(button[type=submit]) svg [stroke*="#"] {
  stroke: currentColor;
}
:where(button[type=submit]) svg [fill*="#"] {
  fill: currentColor;
}
:where(button[type=submit]):before, :where(button[type=submit]):after {
  color: #2c364a;
}
:where(button[type=submit]):hover, :where(button[type=submit]):focus, :where(button[type=submit]):active {
  background-color: #4c9344;
  border-color: #4c9344;
  color: #ffffff;
}
:where(button[type=submit]):focus {
  outline: 1px auto #0192d0;
}

/* -------------------------------
Inputs and Textarea
---------------------------------- */
:where([type=text],
[type=number],
[type=email],
[type=tel],
[type=search],
[type=password],
[type=url],
textarea) {
  -webkit-appearance: none;
  display: block;
  outline: none;
  width: 100%;
  line-height: var(--field-line-height);
  padding: var(--field-padding-vertical) var(--field-padding-horizontal);
  border: var(--field-border);
  background-color: var(--field-background);
  color: var(--field-text-color);
  border-radius: var(--field-radius);
}

/* -------------------------------
Select Styles
---------------------------------- */
:where(select) {
  -webkit-appearance: none;
  display: block;
  outline: none;
  width: 100%;
  line-height: var(--field-line-height);
  padding: var(--field-padding-vertical) var(--field-padding-horizontal);
  border: var(--field-border);
  background-color: var(--field-background);
  color: var(--field-text-color);
  border-radius: var(--field-radius);
  -moz-appearance: none;
  background-image: url(../images/icon-system/icon_form_arrow-down.svg), url(../images/icon-system/form_bg-fill.svg);
  background-repeat: no-repeat, repeat-Y;
  background-position: center right 8px, center right;
  background-size: 24px, 40px;
  padding-right: 52px;
}

/* -------------------------------
Fieldsets Styles
---------------------------------- */
/* Wrapper */
fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

/* Field Element */
:where(.field--checkbox_group) label,
:where(.field--radio_group) label {
  position: relative;
  cursor: pointer;
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  justify-content: start;
  align-items: center;
  font-weight: 400;
}

:where(.field--checkbox) .field-label {
  position: relative;
  cursor: pointer;
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  justify-content: start;
  align-items: center;
  font-weight: 400;
}

:where([type=checkbox]) {
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
}
:where([type=checkbox]):before {
  content: "";
  border: var(--field-border);
  background-color: var(--field-background);
  color: var(--field-text-color);
  border-radius: var(--field-radius);
  display: block;
  width: var(--fieldset-input-diameter);
  height: var(--fieldset-input-diameter);
}
:where([type=checkbox]):checked:after {
  content: "";
  border: var(--field-border);
  background-color: var(--field-background);
  color: var(--field-text-color);
  border-radius: var(--field-radius);
  display: block;
  width: var(--fieldset-input-diameter);
  height: var(--fieldset-input-diameter);
  position: absolute;
  top: 0;
  background: #0192d0 no-repeat center/90% url(../assets/icon-system/icon_form_tick-thick-white.svg);
  border-color: #0192d0;
  z-index: 1;
}

:where([type=radio]) {
  -webkit-appearance: none;
  -moz-appearance: none;
}
:where([type=radio]):before {
  content: "";
  border: var(--field-border);
  background-color: var(--field-background);
  color: var(--field-text-color);
  border-radius: var(--field-radius);
  display: block;
  border-radius: 100%;
  width: var(--fieldset-input-diameter);
  height: var(--fieldset-input-diameter);
}
:where([type=radio]):checked:before {
  background-color: #0192d0;
  border-color: #0192d0;
}
:where([type=radio]):checked:after {
  content: "";
  position: absolute;
  left: calc(var(--fieldset-input-diameter) / 4);
  top: 50%;
  transform: translateY(-50%);
  width: calc(var(--fieldset-input-diameter) / 2);
  height: calc(var(--fieldset-input-diameter) / 2);
  border-radius: 100%;
  background: #ffffff;
  z-index: 1;
}

/* ---- Base styles ---- */
.field-element {
  margin-bottom: var(--field-vertical-whitespace);
}

/* Clearfix */
.field-element:before,
.field-element:after {
  content: " ";
  display: table;
}

.field-element:after {
  clear: both;
}

/* ---- Sizes ---- */
.field-element--small :where([type=text],
[type=number],
[type=email],
[type=tel],
[type=search],
[type=password],
[type=url],
textarea) {
  -webkit-appearance: none;
  display: block;
  outline: none;
  width: 100%;
  line-height: var(--field-line-height);
  padding: var(--field-padding-vertical) var(--field-padding-horizontal);
  --field-font-size: var(--centi-font-size);
  --field-padding-vertical: 0.57em;
  --field-padding-horizontal: 1.1em;
}
.field-element--small :where(.button) {
  padding-block: calc(var(--button-padding-vertical) + 0.2em) var(--button-padding-vertical);
  padding-left: var(--button-padding-horizontal);
  padding-right: var(--button-padding-icon);
}
@media screen and (min-width: 48em) {
  .field-element--small :where(.button) {
    --button-font-size: var(--milli-font-size);
    --button-padding-vertical: 0.75em;
    --button-padding-horizontal: 1em;
    --button-padding-icon: 2.2em;
    --button-line-height: 1;
    --button-icon-size: 1.6em;
    --button-icon-top: 0.38em;
    --button-icon-edge-align: 0.38em;
    --button-padding-icon: 2.38em;
  }
}

.field-element--large :where(.button) {
  padding-block: calc(var(--button-padding-vertical) + 0.2em) var(--button-padding-vertical);
  padding-left: var(--button-padding-horizontal);
  padding-right: var(--button-padding-icon);
}
@media screen and (min-width: 75em) {
  .field-element--large :where(.button) {
    --button-font-size: 1.9rem;
    --button-padding-vertical: 1.15em;
    --button-padding-horizontal: 1.35em;
    --button-padding-icon: 2.95em;
    --button-icon-size: 1.74em;
    --button-icon-top: 0.74em;
    --button-icon-edge-align: 1em;
    --button-padding-icon: 2.95em;
  }
}

/* ---- Theme ---- */
.field-element--white {
  --field-background: #ffffff;
}

.field-element--lightgreen {
  --field-background: #95d38e;
}

/* ---- Caps ---- */
.field-element--caps .textbox,
.field-element--caps select {
  text-transform: uppercase;
}

/* ---- Disabled ---- */
.field-element--disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.field-element--disabled .textbox[disabled],
.field-element--disabled select[disabled],
.field-element--disabled button[disabled] {
  cursor: not-allowed;
}

/* ---- Lightweight elements ---- */
.field-element--lightweight {
  margin: 0;
}

/* ---- Captcha ---- */
.captcha {
  background-color: #f6f6f6;
  padding: 10px;
  width: 100%;
  border: 1px solid #D7D7D7;
}

.captcha .captcha-info, .captcha .info {
  float: right;
  padding-bottom: 8px;
}

.captcha .captcha-info, .captcha .info a {
  text-decoration: none;
}

.captcha .captcha-info .captcha-icon {
  width: 16px;
  height: 16px;
  margin-bottom: 16px;
}

.captcha .captcha-img {
  width: 200px;
  margin-bottom: 10px;
}

.captcha .captcha-input {
  width: 200px;
}

/* ---- reCAPTCHA ---- */
.g-recaptcha {
  padding-bottom: 15px;
}

/* ---- Color picker ---- */
.field-element--colorpicker .textbox.colorpicker {
  padding: 3px 8px;
  height: 43px;
  width: 50px;
}

/* Small */
.field-element--colorpicker.field-element--small .textbox.colorpicker {
  padding: 2px 7px;
  height: 33px;
  width: 39px;
}

/* Large and regular field elements turn small */
@media screen and (max-width: 900px) {
  .field-element.field-element--colorpicker .textbox.colorpicker,
  .field-element.field-element--colorpicker.field-element--large .textbox.colorpicker,
  .field-element.field-element--colorpicker.field-element-regular .textbox.colorpicker {
    padding: 2px 7px;
    height: 33px;
    width: 39px;
  }
}
/* ---- Date pickers ---- */
/* Date picker and Date-range picker*/
.field-element--datepicker .field-input .textbox,
.field-element--daterangepicker .field-input .textbox {
  padding-left: 55px;
  background-image: url(../images/form-icons.svg), url(../images/form-icons.svg);
  background-repeat: no-repeat, no-repeat;
  background-position: top 11px left 12px, top -447px left 0;
}

/* White */
.field-element--datepicker.field-element--white .field-input .textbox,
.field-element--daterangepicker.field-element--white .field-input .textbox {
  background-position: top 11px left 12px, top -607px left 0;
}

/* White + Small */
.field-element--datepicker.field-element--white.field-element--small .field-input .textbox,
.field-element--daterangepicker.field-element--white.field-element--small .field-input .textbox {
  background-position: top 7px left 12px, top -607px left 0;
}

/* White + Large */
.field-element--datepicker.field-element--white.field-element--large .field-input .textbox,
.field-element--daterangepicker.field-element--white.field-element--large .field-input .textbox {
  background-position: top 15px left 12px, top -607px left 0;
}

/* Small */
.field-element--datepicker.field-element--small .field-input .textbox,
.field-element--daterangepicker.field-element--small .field-input .textbox {
  background-position: top 6px left 12px, top -447px left 0;
}

/* Large */
.field-element--datepicker.field-element--large .field-input .textbox,
.field-element--daterangepicker.field-element--large .field-input .textbox {
  background-position: top 15px left 12px, top -447px left 0;
}

/* Large and regular field elements turn small */
@media screen and (max-width: 900px) {
  .field-element--datepicker .field-input .textbox,
  .field-element--daterangepicker .field-input .textbox,
  .field-element--datepicker.field-element--large .field-input .textbox,
  .field-element--daterangepicker.field-element--regular .field-input .textbox,
  .field-element--datepicker.field-element--large .field-input .textbox,
  .field-element--daterangepicker.field-element--regular .field-input .textbox {
    background-position: top 6px left 12px, top -447px left 0;
    padding-left: 55px;
  }
  .field-element--white.field-element--datepicker .field-input .textbox,
  .field-element--white.field-element--daterangepicker .field-input .textbox,
  .field-element--white.field-element--datepicker.field-element--large .field-input .textbox,
  .field-element--white.field-element--daterangepicker.field-element--regular .field-input .textbox,
  .field-element--white.field-element--datepicker.field-element--large .field-input .textbox,
  .field-element--white.field-element--daterangepicker.field-element--regular .field-input .textbox {
    background-position: top 7px left 12px, top -607px left 0;
  }
}
/* Date/time picker */
.field-element--datetimepicker .field-input .textbox {
  padding-left: 55px;
  background-image: url(../images/form-icons.svg), url(../images/form-icons.svg);
  background-repeat: no-repeat, no-repeat;
  background-position: top -88px left 12px, top -447px left 0;
}

/* White */
.field-element--datetimepicker.field-element--white .field-input .textbox {
  background-position: top -88px left 12px, top -607px left 0;
}

/* White + Small */
.field-element--datetimepicker.field-element--small.field-element--white .field-input .textbox {
  background-position: top -93px left 12px, top -607px left 0;
}

/* White + Large */
.field-element--datetimepicker.field-element--large.field-element--white .field-input .textbox {
  background-position: top -85px left 12px, top -607px left 0;
}

/* Small */
.field-element--datetimepicker.field-element--small .field-input .textbox {
  background-position: top -93px left 12px, top -447px left 0;
}

/* Large */
.field-element--datetimepicker.field-element--large .field-input .textbox {
  background-position: top -85px left 12px, top -447px left 0;
}

/* Large and regular field elements turn small */
@media screen and (max-width: 900px) {
  .field-element--datetimepicker .field-input .textbox,
  .field-element--datetimepicker.field-element--large .field-input .textbox,
  .field-element--datetimepicker.field-element--regular .field-input .textbox {
    background-position: top -93px left 12px, top -447px left 0;
    padding-left: 55px;
  }
  .field-element--white.field-element--datetimepicker .field-input .textbox,
  .field-element--white.field-element--datetimepicker.field-element--large .field-input .textbox,
  .field-element--white.field-element--datetimepicker.field-element--regular .field-input .textbox {
    background-position: top -93px left 12px, top -607px left 0;
  }
}
/* Container Appearance */
.daterangepicker {
  position: absolute;
  background: #fff;
  top: 100px;
  left: 20px;
  padding: 4px;
  margin-top: 2px;
  border-radius: 4px;
  width: 278px;
}

.daterangepicker.opensleft:before {
  position: absolute;
  top: -7px;
  right: 9px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: "";
}

.daterangepicker.opensleft:after {
  position: absolute;
  top: -6px;
  right: 10px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  content: "";
}

.daterangepicker.openscenter:before {
  position: absolute;
  top: -7px;
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: "";
}

.daterangepicker.openscenter:after {
  position: absolute;
  top: -6px;
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  content: "";
}

.daterangepicker.opensright:before {
  position: absolute;
  top: -7px;
  left: 9px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: "";
}

.daterangepicker.opensright:after {
  position: absolute;
  top: -6px;
  left: 10px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  content: "";
}

.daterangepicker.dropup {
  margin-top: -5px;
}

.daterangepicker.dropup:before {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid #CED2DC;
}

.daterangepicker.dropup:after {
  top: initial;
  bottom: -6px;
  border-bottom: initial;
  border-top: 6px solid #fff;
}

.daterangepicker.dropdown-menu {
  max-width: none;
  z-index: 3000;
  display: none;
  border: 1px solid #CED2DC;
}

.daterangepicker.single .ranges, .daterangepicker.single .calendar {
  float: none;
}

.daterangepicker .calendar {
  display: none;
  max-width: 270px;
  margin: 4px;
}

.daterangepicker.show-calendar .calendar {
  display: block;
}

.daterangepicker .calendar.single .calendar-table {
  border: none;
}

/* Calendars */
.daterangepicker .calendar th, .daterangepicker .calendar td {
  white-space: nowrap;
  text-align: center;
  min-width: 32px;
  border: 1px solid #E8E8E8;
}

.daterangepicker .calendar-table {
  border: 1px solid #ddd;
  padding: 4px;
  border-radius: 4px;
  background: #fff;
}

.daterangepicker table {
  width: 100%;
  margin: 0;
  border: none;
}

.daterangepicker td,
.daterangepicker th {
  text-align: center;
  width: 20px;
  height: 20px;
  white-space: nowrap;
  padding: 2px;
}

.daterangepicker td,
.daterangepicker th.available {
  cursor: pointer;
}

.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
  color: #999;
  background: #fff;
  border-color: #E8E8E8;
}

.daterangepicker td.off.in-range {
  background-color: #F4FAFC;
}

.daterangepicker td.off.active {
  background-color: #AAD8EA;
  color: #FFF;
}

.daterangepicker td.disabled,
.daterangepicker option.disabled {
  color: #999;
  cursor: not-allowed;
  text-decoration: line-through;
}

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background: #EAECF1;
}

.daterangepicker td.in-range {
  background: #ebf4f8;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #358AAB;
  border-color: #358AAB;
  color: #fff;
}

.daterangepicker td.week, .daterangepicker th.week {
  font-size: 80%;
  color: #ccc;
}

.daterangepicker select.monthselect, .daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
}

.daterangepicker select.yearselect,
.daterangepicker select.monthselect,
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
  margin-bottom: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 62px;
  background-color: #FFF;
  outline: none;
  border-radius: 5px;
  font-size: 1.4rem;
  line-height: 1.2;
  padding: 5px 23px 5px 8px;
  border: 1px solid #CED2DC;
  background-image: url(../images/form-icons.svg), url(../images/form-icons.svg);
  background-repeat: no-repeat, no-repeat;
  background-position: top -355px right -119px, top -607px right -115px;
}

.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 40%;
}

.daterangepicker select.yearselect {
  width: 44%;
}

.daterangepicker th.month {
  width: auto;
}

/* Previous/Next buttons */
.daterangepicker th.prev .daterangepicker-prev-icon,
.daterangepicker th.next .daterangepicker-next-icon {
  width: 11px;
  height: 17px;
  background-image: url(../images/form-icons.svg);
  background-repeat: no-repeat;
  display: block;
  margin: 0 auto;
}

.daterangepicker th.prev:hover,
.daterangepicker th.next:hover {
  background: none;
  opacity: 0.8;
}

.daterangepicker th.prev .daterangepicker-prev-icon {
  background-position: top -820px left 0px;
}

.daterangepicker th.next .daterangepicker-next-icon {
  background-position: top -880px left 1px;
}

.daterangepicker th.prev,
.daterangepicker th.next,
.daterangepicker th.month,
.daterangepicker .calendar-table thead tr:first-child th {
  border: none;
  background: none;
}

.daterangepicker .calendar-table thead tr:nth-child(2) th {
  background-color: #F2F2F5;
}

/* Text Input Above Each Calendar */
.daterangepicker .input-mini {
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #555;
  display: block;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  margin: 0 0 5px 0;
  padding: 0 6px 0 28px;
  width: 100%;
}

.daterangepicker .input-mini.active {
  border: 1px solid #358AAB;
  background-color: rgb(255, 255, 238);
}

.daterangepicker .daterangepicker_input i {
  position: absolute;
  left: 8px;
  top: 7px;
  color: #596474;
}

.daterangepicker .daterangepicker_input {
  position: relative;
}

/* Time Picker */
.daterangepicker .calendar-time {
  text-align: center;
  margin: 5px auto;
  line-height: 30px;
  position: relative;
}

.daterangepicker .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed;
}

/* Predefined Ranges */
.daterangepicker .ranges {
  font-size: 11px;
  float: none;
  margin: 4px;
  text-align: left;
}

.daterangepicker .applyBtn {
  background-color: #35ab75;
  color: #FFF;
}

.daterangepicker .applyBtn:hover,
.daterangepicker .applyBtn:focus {
  background-color: #2C9867;
  color: #FFF;
}

.daterangepicker .cancelBtn {
  background-color: #D0D3DA;
  color: #24292F;
}

.daterangepicker .cancelBtn:hover,
.daterangepicker .cancelBtn:focus {
  background-color: #BCBFC5;
  color: #24292f;
}

.daterangepicker .ranges ul {
  list-style: none;
  margin: 0 auto 12px;
  padding: 0;
  width: 100%;
}

.daterangepicker .ranges li {
  font-size: 1.3rem;
  background: #EAECF1;
  border: 1px solid #f5f5f5;
  color: #24292F;
  padding: 4px 12px 3px;
  margin-bottom: 7px;
  border-radius: 5px;
  cursor: pointer;
  line-height: 1.2;
}

.daterangepicker .ranges li.active, .daterangepicker .ranges li:hover {
  background: #358AAB;
  border: 1px solid #358AAB;
  color: #fff;
}

/*  Larger Screen Styling */
@media (min-width: 564px) {
  .daterangepicker {
    width: auto;
  }
  .daterangepicker .ranges ul {
    width: 160px;
  }
  .daterangepicker.single .ranges ul {
    width: 100%;
  }
  .daterangepicker .calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .daterangepicker .calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .daterangepicker .calendar.left {
    clear: left;
    margin-right: 0;
  }
  .daterangepicker.single .calendar.left {
    clear: none;
  }
  .daterangepicker.single .ranges,
  .daterangepicker.single .calendar {
    float: left;
  }
  .daterangepicker .calendar.right {
    margin-left: 0;
  }
  .daterangepicker .left .daterangepicker_input {
    padding-right: 12px;
  }
  .daterangepicker .calendar.left .calendar-table {
    padding-right: 12px;
  }
  .daterangepicker .ranges,
  .daterangepicker .calendar {
    float: left;
  }
}
@media (min-width: 730px) {
  .daterangepicker .ranges {
    width: auto;
    float: left;
  }
  .daterangepicker .calendar.left {
    clear: none;
  }
}
/* ---- Money ---- */
.field-element--money .field-input .textbox {
  padding-left: 55px;
  background-image: url(../images/form-icons.svg);
  background-repeat: no-repeat;
  background-position: top -447px left 0;
}

.field-element--money .field-input {
  position: relative;
}

.field-element--money .field-input .money-symbol:before {
  content: "¤"; /* Generic currency sign */
  position: absolute;
  display: block;
  top: 8px;
  left: 0;
  color: #58595B;
  font-weight: bold;
  font-size: 19px;
  width: 41px;
  text-align: center;
}

.field-element--money .field-input .money-symbol--dollar:before {
  content: "$"; /* Dollars */
}

.field-element--money .field-input .money-symbol--pound:before {
  content: "£"; /* Pounds */
}

.field-element--money .field-input .money-symbol--yen:before {
  content: "¥"; /* Yen */
}

.field-element--money .field-input .money-symbol--indian_rupee:before {
  content: "₹"; /* Indian rupee */
}

/* White */
.field-element--money.field-element--white .field-input .textbox,
.field-element--money.field-element--white.field-element--small .field-input .textbox,
.field-element--money.field-element--white.field-element--large .field-input .textbox {
  background-position: top -607px left 0;
}

/* Small */
.field-element--money.field-element--small .field-input .textbox {
  background-position: top -447px left 0;
}

.field-element--money.field-element--small .field-input .money-symbol:before {
  top: 4px;
}

/* Large */
.field-element--money.field-element--large .field-input .textbox {
  background-position: top -447px left 0;
}

.field-element--money.field-element--large .field-input .money-symbol:before {
  top: 14px;
}

/* Large and regular field elements turn small */
@media screen and (max-width: 900px) {
  .field-element--money .field-input .textbox,
  .field-element--money.field-element--large .field-input .textbox,
  .field-element--money.field-element--regular .field-input .textbox {
    background-position: top -447px left 0;
  }
  .field-element--white.field-element--money .field-input .textbox,
  .field-element--white.field-element--money.field-element--large .field-input .textbox,
  .field-element--white.field-element--money.field-element--regular .field-input .textbox {
    background-position: top -607px left 0;
  }
  .field-element--money .field-input .money-symbol:before,
  .field-element--money.field-element--large .field-input .money-symbol:before,
  .field-element--money.field-element--regular .field-input .money-symbol:before {
    top: 5px;
  }
}
:root {
  --multiple-select-item-vertical-padding: 0.4em;
}

.field--multiple_select :where(select) {
  padding: calc(var(--field-padding-vertical) - var(--multiple-select-item-vertical-padding)) 0;
  background-image: none;
}

.field--multiple_select :where(option) {
  padding: var(--multiple-select-item-vertical-padding) var(--field-padding-horizontal);
}

/* ---- Range ---- */
.field-element--range .field-input .textbox {
  -webkit-appearance: slider-horizontal;
  background: none;
  padding: 0;
  border-radius: 0;
  border: none;
  background: none !important;
}

input[type=range]::-moz-focus-outer {
  border: 0;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/* ---- Selects ---- */
.field-element select {
  -webkit-appearance: none;
  display: block;
  outline: none;
  width: 100%;
  line-height: var(--field-line-height);
  padding: var(--field-padding-vertical) var(--field-padding-horizontal);
  border: var(--field-border);
  background-color: var(--field-background);
  color: var(--field-text-color);
  border-radius: var(--field-radius);
  -moz-appearance: none;
  background-image: url(../images/icon-system/icon_form_arrow-down.svg), url(../images/icon-system/form_bg-fill.svg);
  background-repeat: no-repeat, repeat-Y;
  background-position: center right 8px, center right;
  background-size: 24px, 40px;
  padding-right: 52px;
}

.field-element select[multiple] {
  background-image: none;
  padding-right: 20px;
}

.field-element select::-ms-expand,
.daterangepicker select.monthselect::-ms-expand,
.daterangepicker select.yearselect::-ms-expand {
  display: none;
}

/* Multiple selects */
.field-element.field-element--select.field-element--select--multiple select {
  padding: 8px 8px;
  background-image: none;
}

.field-element.field-element--select.field-element--select--multiple option {
  padding: 6px 10px;
}

/* Small */
.field-element--small select {
  background-image: url(../images/icon-system/icon_form_arrow-down-sm.svg), url(../images/icon-system/form_bg-fill.svg);
  background-repeat: no-repeat, repeat-Y;
  background-position: center right 8px, center right;
  background-size: 16px, 32px;
  padding-right: 44px;
}

/* Large */
.field-element--large select {
  background-position: top -247px right -105px, top -447px right -92px;
}

/* Large and regular field elements turn small */
@media screen and (max-width: 900px) {
  .field-element select,
  .field-element.field-element--large select,
  .field-element.field-element--regular select {
    background-position: top -262px right -104px, top -447px right -92px;
    font-size: 1.4rem;
    padding: 7px 15px 7px;
    padding-right: 55px;
  }
}
/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
.field--signature :where(canvas) {
  -webkit-appearance: none;
  display: block;
  outline: none;
  width: 100%;
  line-height: var(--field-line-height);
  padding: var(--field-padding-vertical) var(--field-padding-horizontal);
  border: var(--field-border);
  background-color: var(--field-background);
  color: var(--field-text-color);
  border-radius: var(--field-radius);
  width: auto;
  border-color: #EEE !important;
}
.field--signature :where(button) {
  vertical-align: middle;
  white-space: normal;
  cursor: pointer;
  opacity: 1;
  text-decoration: none;
  font: inherit;
  text-align: center;
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  justify-content: center;
  align-items: center;
  border-radius: var(--button-border-radius);
  position: relative;
  margin: 0;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
  padding-block: calc(var(--button-padding-vertical) + 0.2em) var(--button-padding-vertical);
  padding-left: var(--button-padding-horizontal);
  padding-right: var(--button-padding-icon);
  line-height: var(--button-line-height);
  text-transform: uppercase;
  font-size: var(--button-font-size);
  font-family: "DIN Next W01", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 500;
  color: #ffffff;
  background: #696d73;
  border: var(--button-border-width) solid #696d73;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
}
.field--signature :where(button):after {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  content: "\e908";
}
.field--signature :where(button) svg {
  height: 1em;
  width: 1em;
}
.field--signature :where(button) svg [stroke*="#"] {
  stroke: currentColor;
}
.field--signature :where(button) svg [fill*="#"] {
  fill: currentColor;
}
.field--signature :where(button):before, .field--signature :where(button):after {
  color: #2c364a;
}
.field--signature :where(button):hover, .field--signature :where(button):focus, .field--signature :where(button):active {
  background-color: #515458;
  border-color: #515458;
  color: #ffffff;
}
.field--signature :where(button):focus {
  outline: 1px auto #0192d0;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
.field--submit {
  margin-top: 12px;
  margin-bottom: var(--field-vertical-whitespace);
  background-color: #f6f6f6;
  gap: 8px;
}
.field--submit :where(button):last-child {
  margin-left: auto;
}

/* ---- Submit Bar ---- */
.submit-bar {
  text-align: right;
  background: #f6f6f6;
  padding: 14px;
}

.sidebar .submit-bar {
  padding: 10px 0;
}

.submit-bar a {
  margin-right: 20px;
}

textarea {
  resize: vertical;
}

/* ---- Time pickers ---- */
.field-element--timepicker .field-input .textbox {
  padding-left: 55px;
  background-image: url(../images/form-icons.svg), url(../images/form-icons.svg);
  background-repeat: no-repeat, no-repeat;
  background-position: top -170px left 12px, top -447px left 0;
}

.ui-timepicker {
  display: block;
  position: absolute;
  height: 200px;
  overflow: auto;
}

.ui-timepicker-standard {
  background-color: #FFF;
  border: 1px solid #CED2DC;
  display: block;
  margin: 3px 0 0;
  padding: 5px;
  list-style: none outside none;
  border-radius: 4px;
}

.ui-timepicker-standard a {
  color: #24292f;
}

.ui-timepicker-standard .ui-state-hover {
  background-color: #EAECF1;
}

.ui-timepicker-standard .ui-menu-item {
  clear: left;
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
}

.ui-timepicker-standard .ui-menu-item a {
  display: block;
  padding: 5px 10px;
  line-height: 1.5;
  text-decoration: none;
  border-radius: 3px;
}

.ui-timepicker-hidden {
  display: none;
}

/* White */
.field-element--timepicker.field-element--white .field-input .textbox {
  background-position: top -170px left 12px, top -607px left 0;
}

/* White + Small */
.field-element--timepicker.field-element--white.field-element--small .field-input .textbox {
  background-position: top -175px left 12px, top -607px left 0;
}

/* White + Large */
.field-element--timepicker.field-element--white.field-element--large .field-input .textbox {
  background-position: top -166px left 12px, top -607px left 0;
}

/* Small */
.field-element--timepicker.field-element--small .field-input .textbox {
  background-position: top -175px left 12px, top -447px left 0;
}

/* Large */
.field-element--timepicker.field-element--large .field-input .textbox {
  background-position: top -166px left 12px, top -447px left 0;
}

/* Large and regular field elements turn small */
@media screen and (max-width: 900px) {
  .field-element--timepicker .field-input .textbox,
  .field-element--timepicker.field-element--large .field-input .textbox,
  .field-element--timepicker.field-element--regular .field-input .textbox {
    background-position: top -175px left 12px, top -447px left 0;
    padding-left: 55px;
  }
  .field-element--white.field-element--timepicker .field-input .textbox,
  .field-element--white.field-element--timepicker.field-element--large .field-input .textbox,
  .field-element--white.field-element--timepicker.field-element--regular .field-input .textbox {
    background-position: top -175px left 12px, top -607px left 0;
  }
}
/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/* ---- form style extenders ---- */
.field-element--totalselector__dropdown:before {
  position: absolute;
  top: -8px;
  left: 16px;
  display: inline-block;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #D7D7D7;
  border-left: 8px solid transparent;
  border-bottom-color: #D7D7D7;
  content: "";
}

.field-element--totalselector__dropdown:after {
  position: absolute;
  top: -7px;
  left: 17px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #fff;
  border-left: 7px solid transparent;
  content: "";
}

.field-element--totalselector {
  position: relative;
}

.total-selector__output {
  width: 100%;
  -webkit-appearance: none;
  display: block;
  outline: none;
  width: 100%;
  line-height: var(--field-line-height);
  padding: var(--field-padding-vertical) var(--field-padding-horizontal);
  border: var(--field-border);
  background-color: var(--field-background);
  color: var(--field-text-color);
  border-radius: var(--field-radius);
  background-image: url(~@/assets/images/fields/field_arrow-down.svg), url(~@/assets/images/fields/field_background-fill.svg);
  background-repeat: no-repeat, repeat-Y;
  background-position: center right calc((40px - 16px) / 2), center right;
  background-size: 16px, 40px;
  padding-right: 52px;
}
.field-element--guests .total-selector__output {
  -webkit-appearance: none;
  display: block;
  outline: none;
  width: 100%;
  line-height: var(--field-line-height);
  padding: var(--field-padding-vertical) var(--field-padding-horizontal);
  border: var(--field-border);
  background-color: var(--field-background);
  color: var(--field-text-color);
  border-radius: var(--field-radius);
  background-image: url(~@/assets/images/fields/field_persons.svg), url(~@/assets/images/fields/field_arrow-down.svg), url(~@/assets/images/fields/field_background-fill.svg);
  background-repeat: no-repeat, no-repeat, repeat-Y;
  background-position: center left 12px, center right calc((40px - 16px) / 2), center right;
  background-size: 16px, 16px, 40px;
  padding-left: 40px;
  padding-right: 40px;
}

.field-element--totalselector__dropdown {
  position: absolute;
  background: #ffffff;
  top: 100%;
  left: 0;
  right: 0;
  padding: 20px 24px;
  margin-top: 2px;
  border-radius: 4px;
  z-index: 800;
  border: 1px solid #EEE;
  display: none;
  white-space: nowrap;
  text-transform: none;
}

.field-element--totalselector.field-element--totalselector--active .field-element--totalselector__dropdown {
  display: block;
}

.total-selector__dropdown__field {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 0 0 16px;
}

.total-selector__dropdown__field:last-child {
  margin-bottom: 0;
}

.total-selector__dropdown__field__labels {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
}

.total-selector__dropdown__field__buttons {
  flex: 0 0 auto;
  display: flex;
  flex-wrap: nowrap;
}

.total-selector__dropdown__field__button {
  -webkit-appearance: none;
  border: 2px solid transparent;
  border-radius: 1px;
  outline: none;
  background: none;
  background-color: #ffffff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  padding: 0;
  height: 32px;
  width: 32px;
  display: grid;
  place-items: center;
  color: inherit;
  cursor: pointer;
  font-size: 80%;
  flex: 0 0 auto;
  border-radius: 4px;
}
.total-selector__dropdown__field__button path,
.total-selector__dropdown__field__button circle,
.total-selector__dropdown__field__button ellipse,
.total-selector__dropdown__field__button rect,
.total-selector__dropdown__field__button line,
.total-selector__dropdown__field__button polygon {
  stroke: currentColor;
}

.total-selector__dropdown__field__button:focus {
  color: #61b258;
  border-color: #696d73;
  outline: 1px dashed #696d73;
  outline-offset: 1px;
  background-color: #ffffff;
}

.total-selector__dropdown__field__button:hover {
  color: #61b258;
  border-color: #61b258;
  background-color: #ffffff;
}

/* Min / max */
.total-selector__dropdown__field__button.min,
.total-selector__dropdown__field__button.max {
  opacity: 0.5;
  pointer-events: none;
  border-color: transparent;
  color: #4b4b4b;
}

.total-selector__dropdown__field__total,
.field-input .textbox.total-selector__dropdown__field__total {
  -webkit-appearance: none;
  flex: 0 0 auto;
  padding: 0 12px;
  width: 50px;
  text-align: center;
  background-color: transparent;
  font: inherit;
  color: #000000;
  font-weight: 600;
}
.total-selector__dropdown__field__total[readonly],
.field-input .textbox.total-selector__dropdown__field__total[readonly] {
  border: none;
  text-align: center;
  background-color: transparent;
}
.total-selector__dropdown__field__total[readonly]:focus,
.field-input .textbox.total-selector__dropdown__field__total[readonly]:focus {
  outline: none;
}

.total-selector__dropdown__field__labels p {
  display: block;
  margin-right: 8px;
}

.total-selector__dropdown__field__labels__title,
.field-element--totalselector__dropdown .total-selector__dropdown__field__labels__title {
  margin: 0;
  color: #4b4b4b;
  font-weight: 600;
  padding-right: 8px;
}

.total-selector__dropdown__field__labels__helper,
.field-element--totalselector__dropdown .total-selector__dropdown__field__labels__helper {
  margin: 0;
  font-size: var(--milli-font-size);
  color: #696d73;
  text-transform: none;
}

.field-element--totalselector__fields {
  display: none;
}

/* ---- File upload area ---- */
.file-upload__input {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.file-upload__helptext {
  padding-top: 14px;
}

.file-upload__helptext--hidden {
  display: none;
}

.file-upload__helptext__line2 {
  display: block;
  font-size: 1.3rem;
}

.file-upload__helptext p {
  margin: 0;
}

.file-upload__helptext:before {
  content: " ";
  display: block;
  width: 27px;
  height: 32px;
  background-image: url(../images/form-icons.svg);
  background-repeat: no-repeat;
  background-position: 0 -1317px;
  margin: 0 auto 10px;
}

.file-upload__area {
  cursor: pointer;
  text-align: center;
}

.field-input .textbox.file-upload__area--dragenter {
  border-color: #35ab75;
}

.file-upload__uploads:before,
.file-upload__uploads:after {
  content: " ";
  display: table;
}

.file-upload__uploads:after {
  clear: both;
}

.file-upload__item,
.file-upload__helptext {
  height: 110px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.file-upload__item {
  background-color: #FFF;
  border: 1px solid #ced2dc;
  width: 110px;
  display: inline-block;
  margin-left: 6px;
  margin-right: 6px;
  position: relative;
  cursor: default;
  vertical-align: top;
}

.file-upload__item__remove {
  -webkit-appearance: none;
  padding: 0;
  outline: none;
  border: none;
  background: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.3);
}

.file-upload__item__remove:hover,
.file-upload__item__remove:focus {
  background: rgba(0, 0, 0, 0.7);
}

.file-upload__item__remove:after {
  content: " ";
  display: block;
  width: 17px;
  height: 17px;
  background-image: url(../images/form-icons.svg);
  background-repeat: no-repeat;
  background-position: 3px -938px;
}

.file-upload__item__remove__text {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/* Feedback */
.file-upload__item__feedback__response {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.file-upload__item__feedback__response p {
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
}

/* Not image response */
.file-upload__item__feedback__response .file-upload__item__feedback__name {
  font-size: 1.2rem;
  margin: 0 0 5px;
}

.file-upload__item__feedback__size {
  font-size: 1.1rem;
  margin: 0;
}

.file-upload__item__feedback__response--success--not-image {
  padding: 26px 15px 20px;
}

.file-upload__item__feedback__response--success--not-image:before {
  content: " ";
  display: block;
  width: 28px;
  height: 24px;
  background-image: url(../images/form-icons.svg);
  background-repeat: no-repeat;
  background-position: 0px -1396px;
  margin: 0 auto 10px;
}

/* Image response */
.file-upload__item__feedback__image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
}

/* Error */
.file-upload__item__feedback__response--error {
  padding: 25px 15px 20px;
}

.file-upload__item__feedback__response .file-upload__item__feedback__error__text {
  font-size: 1.2rem;
  white-space: normal;
  overflow: visible;
}

.file-upload__item__feedback__response--error:before {
  content: " ";
  display: block;
  width: 23px;
  height: 24px;
  background-image: url(../images/form-icons.svg);
  background-repeat: no-repeat;
  background-position: 0px -1473px;
  margin: 0 auto 10px;
}

/* Hover */
.file-upload__item__feedback__hover {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #FFF;
  padding: 33px 15px 20px;
  overflow: hidden;
}

.file-upload__item:hover .file-upload__item__feedback__hover {
  opacity: 1;
}

.file-upload__item__feedback__hover__size {
  font-size: 1.1rem;
}

.file-upload__item__feedback__hover__dimensions {
  font-size: 1.1rem;
}

.file-upload__item__feedback__hover .file-upload__item__feedback__hover__name {
  font-size: 1.1rem;
  margin-bottom: 6px;
}

/* Progress circles */
.file-upload__progress-circle {
  position: relative;
  font-size: 1.3rem;
  line-height: 1.1;
  display: block;
  width: 56px;
  height: 56px;
  padding: 21px 0 0 0;
  margin: 26px auto 0;
  text-align: center;
}

.file-upload__progress-circle__amount {
  z-index: 1;
  position: relative;
}

.file-upload__progress-circle__pie {
  -ms-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.file-upload__progress-circle__pie__piece {
  -webkit-transition: all 100ms ease-in-out;
  -o-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
}

/* Feedback */
/* ---- File selector ---- */
.field-element--fileselector .fs-select-button {
  float: left;
  margin-right: 9px;
}

.field-element--fileselector .fs-preview-wrapper {
  background-color: #EEF0F3;
  position: relative;
  overflow: hidden;
  display: block;
}

.field-element--fileselector .fs-filename {
  padding: 10px 15px;
  word-break: break-all;
  overflow: hidden;
  display: block;
}

.field-element--fileselector .fs-file-selected .fs-filename {
  padding-left: 10px;
  padding-right: 38px;
}

.field-element--fileselector .fs-preview {
  width: 39px;
  display: none;
  float: left;
}

.field-element--fileselector .fs-file-selected .fs-preview {
  display: block;
}

.field-element--fileselector .fs-remove {
  display: none;
  -webkit-appearance: none;
  outline: none;
  border: none;
  background: none;
  padding: 0;
  color: #D4D7DE;
  position: absolute;
  right: 6px;
  top: 9px;
  background-image: url(../images/form-icons.svg);
  background-repeat: no-repeat;
  background-position: top -1075px left 3px;
  width: 22px;
  height: 22px;
}

.field-element--fileselector .fs-remove:hover,
.field-element--fileselector .fs-remove:focus {
  background-position: top -1135px left 3px;
}

.field-element--fileselector .fs-file-selected .fs-remove {
  display: block;
}

/* Small */
.field-element--fileselector.field-element--small .fs-preview {
  width: 30px;
}

.field-element--fileselector.field-element--small .fs-filename {
  padding-top: 8px;
  padding-bottom: 6px;
  font-size: 1.4rem;
}

.field-element--fileselector.field-element--small .fs-remove {
  top: 6px;
  background-position: top -964px left 3px;
  width: 20px;
  height: 20px;
}

.field-element--fileselector.field-element--small .fs-remove:hover,
.field-element--fileselector.field-element--small .fs-remove:focus {
  background-position: top -1023px left 3px;
}

.field-element--fileselector.field-element--small .fs-file-selected .fs-filename {
  padding-right: 31px;
}

/* Large */
.field-element--fileselector.field-element--large .fs-preview {
  width: 48px;
}

.field-element--fileselector.field-element--large .fs-filename {
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 1.8rem;
}

.field-element--fileselector.field-element--large .fs-remove {
  top: 14px;
  right: 9px;
}

.field-element--fileselector.field-element--large .fs-file-selected .fs-filename {
  padding-right: 41px;
  padding-left: 20px;
}

/* Popup */
.file-selector-search-wrapper {
  display: none;
}

.file-selector-search-wrapper.searched {
  display: block;
}

#file-selector-preview {
  display: block;
  background: #f9f9fb;
  border: 1px solid #E0E3EA;
  margin: 0 0 20px;
  position: relative;
  padding: 20px 25px;
}

.no-results #file-selector-preview {
  display: none;
}

#file-selector-preview .preview-title {
  margin: 0 0 12px;
}

#file-selector-preview .preview-box {
  position: relative;
  height: 150px;
}

#file-selector-preview img {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: 0 auto;
  display: block;
}

#file-selector-upload .field-element--new-category,
#file-selector-upload.new-category .field-element--select-category {
  display: none;
}

#file-selector-upload.new-category .field-element--new-category,
#file-selector-upload .field-element--select-category {
  display: block;
}

#file-selector-result-wrap {
  max-height: 450px;
  overflow: auto;
  border-top: 1px solid #D7D7D7;
  border-bottom: 1px solid #D7D7D7;
}

#file-selector-upload .field-elements-inline,
#file-selector-search .field-elements-inline {
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}

#file-selector-upload .field-elements-inline .field-element--dropdown,
#file-selector-search .field-elements-inline .field-element--dropdown {
  -ms-flex-positive: 1;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}

#file-selector-upload .field-elements-inline .field-element--text,
#file-selector-search .field-elements-inline .field-element--text {
  -ms-flex-positive: 1;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}

#file-selector-stats {
  font-size: 1.6rem;
  margin-bottom: 14px;
}

#file-selector-results .file-select-table {
  margin: 0;
  width: 100%;
  border-left: 1px solid #E0E3EA;
  border-right: 1px solid #E0E3EA;
}

#file-selector-results .file-select-table tr {
  border-bottom: 1px solid #D7D7D7;
  cursor: pointer;
}

#file-selector-results .file-select-table tr:last-child {
  border-bottom: 0;
}

#file-selector-results .file-select-table tr:nth-child(odd) {
  background-color: #f9f9fb;
}

/* Hover */
#file-selector-results .file-select-table tr:hover {
  background-color: #ECE9E9;
}

#file-selector-paginate {
  margin-top: 16px;
}

#file-selector-prev {
  float: left;
}

#file-selector-next {
  float: right;
}

#file-selector-results a {
  text-decoration: none;
  color: inherit;
  display: block;
}

#file-selector-results .file-select-table-thumbnail {
  width: 100px;
  padding: 5px 20px;
}

#file-selector-results .file-select-table-text a {
  padding: 27px 25px 27px 0;
}

#file-selector-results .table--content-standard tr:hover td {
  background-color: red;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/* -- Field element attach --- */
.field-element-attach {
  position: relative;
}

.field-element-attach__button {
  position: absolute;
  top: 0;
  right: 0;
}

.field-element-attach .textbox,
.field-element-attach select {
  padding-right: 95px;
}

@media screen and (min-width: 75em) {
  /* Large */
  .field-element-attach .field-element--large .textbox,
  .field-element-attach .field-element--large select {
    padding-right: 120px;
  }
}
/* BP 3 */
@media screen and (max-width: 760px) {
  .field-element-attach .textbox,
  .field-element-attach select,
  .field-element-attach .field-element--regular .textbox,
  .field-element-attach .field-element--regular select,
  .field-element-attach .field-element--large .textbox,
  .field-element-attach .field-element--large select {
    padding-right: 72px;
  }
}
/* ---- Search Form ---- */
input.search-query {
  margin-bottom: 0;
  padding-right: 14px;
  padding-left: 14px;
  border-radius: 0;
}

.form-search .input-append .search-query,
.form-search .input-prepend .search-query {
  border-radius: 0;
}

.form-search .input-append .search-query {
  border-radius: 14px, 0, 0, 14px;
}

.form-search .input-append .btn {
  border-radius: 0, 14px, 14px, 0;
}

.form-search .input-prepend .search-query {
  border-radius: 0, 14px, 14px, 0;
}

.form-search .input-prepend .btn {
  border-radius: 14px, 0, 0, 14px;
}

.form-search {
  /*radio*/
}

.form-search input,
.form-search textarea,
.form-search select {
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
}

.form-search .hide {
  display: none;
}

.form-search label,
.form-search .btn-group {
  display: inline-block;
}

.form-search .radio,
.form-search .checkbox {
  margin-bottom: 0;
  padding-left: 0;
  vertical-align: middle;
}

.form-search .radio input[type=radio],
.form-search .checkbox input[type=checkbox] {
  float: left;
}

.form-search .radio input[type=radio] margin,
.form-search .checkbox input[type=checkbox] margin {
  right: 3px;
  left: 0;
}

.form-search .control-group {
  margin-bottom: 0.75em;
}

.form-search legend + .control-group {
  margin-top: 1.5em;
  -webkit-margin-top-collapse: separate;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-color: #EEE;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px #EEE inset;
}

input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  outline: #696d73 auto 5px;
  background-color: #EEE;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 80px #EEE inset;
  transition: background-color 5000s ease-in-out 0s;
}

/* ---- Autocomplete ---- */
.ui-autocomplete {
  list-style: none;
  background: #FFFFFF;
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #CED2DC;
  border-radius: 4px;
  padding: 3px 0;
  margin: 0;
}

.ui-autocomplete .ui-menu-item {
  padding: 5px 11px;
  cursor: pointer;
}

.ui-autocomplete .ui-menu-item.ui-state-focus {
  padding: 5px 11px;
  background: #358AAB;
  color: #FFF;
}

:where([type=text], [type=number], [type=email], textarea, select)[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

/* ---- Inline ---- */
.field-elements-inline:before,
.field-elements-inline:after {
  content: " ";
  display: table;
}

.field-elements-inline:after {
  clear: both;
}

.field-elements-inline .field-element {
  float: left;
  margin-right: 1%;
}

.field-elements-inline .field-element:last-child {
  margin-right: 0;
}

/* Button */
.field-elements-inline .field-element--button {
  margin-top: 29px;
}

.field-elements-inline .field-element--button.field-element--inline-no-label {
  margin-top: 0;
}

/* Add top margin to make empty space for margins */
.field-element--inline-with-label {
  margin-top: 29px;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
:where(label),
.field-label,
.fieldset__legend {
  display: block;
  font-weight: 600;
  font-size: var(--body-font-size);
  margin-bottom: 8px;
  line-height: 1.2;
  font-family: "Montserrat";
}

.field-instructions {
  font-size: var(--centi-font-size);
  margin-top: 0.25em;
}

/* Instead of h3s */
.label.heading {
  margin: 30px 0px 20px;
  padding: 8px 8px 8px 0px;
  border-bottom: 1px solid #EEE;
}

/* Hidden labels */
.field-element--hidden-label .field-label,
.field-element--hidden-label .fieldset__legend {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/* ---- Field helpers ---- */
.field-helper {
  font-weight: 400;
  font-size: 1.3rem;
  margin-bottom: 1em;
}

.field-label .field-helper {
  margin-top: 0.3em;
  margin-bottom: 0;
}

/* ---- Field errors ---- */
.field-error__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.field-error__list__item {
  color: #fff;
  background: #C44556;
  padding: 10px 20px;
  margin: 11px 0;
  border-left: 6px solid #A23947;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/* ---- Placeholders ---- */
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #9c9c9c;
}

::-moz-placeholder { /* Firefox 19+ */
  color: #9c9c9c;
}

:-ms-input-placeholder { /* IE 10+ */
  color: #9c9c9c;
}

:-moz-placeholder { /* Firefox 18- */
  color: #9c9c9c;
}

.field-element--lightgreen ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #439b3a;
}
.field-element--lightgreen ::-moz-placeholder { /* Firefox 19+ */
  color: #439b3a;
}
.field-element--lightgreen :-ms-input-placeholder { /* IE 10+ */
  color: #439b3a;
}
.field-element--lightgreen :-moz-placeholder { /* Firefox 18- */
  color: #439b3a;
}

.field-label__required {
  text-indent: -9999px;
  line-height: 0;
  display: inline-block;
}

.field-label__required:after {
  content: "*";
  text-indent: 0;
  display: block;
  line-height: inherit;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/*
svg path types
path,
circle,
ellipse,
rect,
line,
polygon
*/
.icon {
  display: inline-block;
  vertical-align: middle;
}

.icon svg {
  width: 16px;
  height: 16px;
}

.icon--24px svg {
  width: 24px;
  height: 24px;
}

.icon--32px svg {
  width: 32px;
  height: 32px;
}

.icon--40px svg {
  width: 40px;
  height: 40px;
}

.icon--48px svg {
  width: 48px;
  height: 48px;
}

.icon--52px svg {
  width: 52px;
  height: 52px;
}

.icon--60px svg {
  width: 60px;
  height: 60px;
}

.icon--68px svg {
  width: 68px;
  height: 68px;
}

/* Icon system colours */
.icon--primary path,
.icon--primary circle,
.icon--primary ellipse,
.icon--primary rect,
.icon--primary line,
.icon--primary polygon {
  fill: #2c364a;
}

.icon--secondary path,
.icon--secondary circle,
.icon--secondary ellipse,
.icon--secondary rect,
.icon--secondary line,
.icon--secondary polygon {
  fill: #0073BC;
}

.icon--alternative path,
.icon--alternative circle,
.icon--alternative ellipse,
.icon--alternative rect,
.icon--alternative line,
.icon--alternative polygon {
  fill: #00AAE9;
}

.icon--current-color path,
.icon--current-color circle,
.icon--current-color ellipse,
.icon--current-color rect,
.icon--current-color line,
.icon--current-color polygon {
  fill: currentColor;
}

.icon--white path,
.icon--white circle,
.icon--white ellipse,
.icon--white rect,
.icon--white line,
.icon--white polygon {
  fill: #ffffff;
}

.icon--accent-01 path,
.icon--accent-01 circle,
.icon--accent-01 ellipse,
.icon--accent-01 rect,
.icon--accent-01 line,
.icon--accent-01 polygon {
  fill: #61b258;
}

.icon--accent-02 path,
.icon--accent-02 circle,
.icon--accent-02 ellipse,
.icon--accent-02 rect,
.icon--accent-02 line,
.icon--accent-02 polygon {
  fill: #50a247;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/* ---- Icon circle list ---- */
.icon-circle-list {
  list-style: none;
  margin: 25px 0;
  padding: 0;
  line-height: 1.5;
}

.icon-circle-list__item {
  margin-bottom: 12px;
}

.icon-circle-list a,
.icon-circle-list span.icon-before {
  text-decoration: none;
  color: inherit;
  position: relative;
  white-space: nowrap;
}

.icon-circle-list__item a:before,
.icon-circle-list__item span.icon-before:before,
.icon-circle:before {
  background-color: #0073BC;
  border-radius: 50%;
  display: block;
  text-align: center;
  color: #FFF;
  display: inline-block;
  vertical-align: top;
  height: 24px;
  width: 24px;
  font-size: 21px;
  line-height: 1.1;
}
@media screen and (min-width: 48em) {
  .icon-circle-list__item a:before,
  .icon-circle-list__item span.icon-before:before,
  .icon-circle:before {
    height: 28px;
    width: 28px;
    font-size: 22px;
    line-height: 1.3;
  }
}
@media screen and (min-width: 62em) {
  .icon-circle-list__item a:before,
  .icon-circle-list__item span.icon-before:before,
  .icon-circle:before {
    height: 40px;
    width: 40px;
    line-height: 1.5;
    font-size: 28px;
  }
}

/* With text */
.icon-circle-list__item--text {
  display: flex;
  align-items: center;
}

.icon-circle-list__item--text a:before,
.icon-circle-list__item--text span.icon-before:before {
  margin-top: 0;
  margin-right: 10px;
}
@media screen and (min-width: 39em) {
  .icon-circle-list__item--text a:before,
  .icon-circle-list__item--text span.icon-before:before {
    margin-top: -4px;
    margin-right: 9px;
  }
}
@media screen and (min-width: 48em) {
  .icon-circle-list__item--text a:before,
  .icon-circle-list__item--text span.icon-before:before {
    margin-right: 8px;
  }
}
@media screen and (min-width: 62em) {
  .icon-circle-list__item--text a:before,
  .icon-circle-list__item--text span.icon-before:before {
    margin-right: 9px;
  }
}
@media screen and (min-width: 75em) {
  .icon-circle-list__item--text a:before,
  .icon-circle-list__item--text span.icon-before:before {
    margin-right: 13px;
  }
}

/* -- Inline -- */
.icon-circle-list--inline {
  margin: 0;
  line-height: 1.5;
}
@media screen and (min-width: 75em) {
  .icon-circle-list--inline {
    line-height: 1.2;
  }
}
.icon-circle-list--inline .icon-circle-list__item {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 2px;
  line-height: 1.6;
}
@media screen and (min-width: 62em) {
  .icon-circle-list--inline .icon-circle-list__item {
    margin-right: 5px;
    line-height: inherit;
  }
}
.icon-circle-list--inline .icon-circle-list__item--text a:before,
.icon-circle-list--inline .icon-circle-list__item--text span.icon-before:before {
  margin-top: 0;
}
.icon-circle-list--inline .icon-circle-list__item:last-child {
  margin-right: 0;
}
.icon-circle-list--inline.icon-circle-list--large {
  line-height: 1.6;
}
@media screen and (min-width: 75em) {
  .icon-circle-list--inline.icon-circle-list--large {
    line-height: 1.9;
  }
}

/* Lightblue */
.icon-circle-list__item.icon-circle-list__item--lightblue a:before,
.icon-circle-list__item.icon-circle-list__item--lightblue span.icon-before:before,
.icon-circle--lightblue:before {
  background-color: #445374;
  color: #2c364a;
}

.icon-circle-list__item.icon-circle-list__item--lightblue a:hover:before {
  background-color: #556891;
}

.icon-circle-list__item.icon-circle-list__item--lightblue-white a:before,
.icon-circle-list__item.icon-circle-list__item--lightblue-white span.icon-before:before,
.icon-circle--lightblue:before {
  background-color: #445374;
  color: #ffffff;
}

.icon-circle-list__item.icon-circle-list__item--lightblue-white a:hover:before {
  background-color: #556891;
}

/* White */
.icon-circle-list__item.icon-circle-list__item--white a:before,
.icon-circle-list__item.icon-circle-list__item--white span.icon-before:before,
.icon-circle--white:before {
  background-color: #FFF;
  color: #2c364a;
}

.icon-circle-list__item.icon-circle-list__item--white a,
.icon-circle-list__item.icon-circle-list__item--white span.icon-before,
.icon-circle--white {
  color: #FFF;
}

.icon-circle-list__item.icon-circle-list__item--white a:hover:before {
  color: #566582;
}

/* White green */
.icon-circle-list__item.icon-circle-list__item--whitegreen a:before,
.icon-circle-list__item.icon-circle-list__item--whitegreen span.icon-before:before,
.icon-circle--whitegreen:before {
  background-color: #FFF;
  color: #61b258;
}

.icon-circle-list__item.icon-circle-list__item--whitegreen a,
.icon-circle-list__item.icon-circle-list__item--whitegreen span.icon-before,
.icon-circle--whitegreen {
  color: #FFF;
}

.icon-circle-list__item.icon-circle-list__item--whitegreen a:hover:before {
  color: #7aca71;
}

/* Small */
.icon-circle-list--small a:before,
.icon-circle-list--small span.icon-before:before,
.icon-circle--small:before {
  height: 30px;
  width: 30px;
  font-size: 22px;
  line-height: 1.3;
}

@media screen and (min-width: 75em) {
  /* Large until BP2, then regular */
  .icon-circle-list--large,
  .icon-circle--large {
    font-size: 2.7rem;
    line-height: 2.2;
  }
  .icon-circle-list--large a:before,
  .icon-circle-list--large span.icon-before:before,
  .icon-circle--large:before {
    height: 56px;
    width: 56px;
    font-size: 37px;
    line-height: 1.5;
  }
  .icon-circle-list--large.icon-circle-list--inline .icon-circle-list__item {
    margin-right: 17px;
  }
}
/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
.slider {
  position: relative;
  width: 100%;
}
.slider__track {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.slider__track::-webkit-scrollbar {
  display: none;
  width: 0;
}
.slider__item {
  flex: 0 0 100%;
  flex-shrink: 0;
  scroll-snap-align: start;
}
.slider__dots {
  position: absolute;
  top: auto;
  bottom: 0;
  left: var(--default-container-gutter);
  right: auto;
  height: 24px;
  z-index: 1;
  display: flex;
}
.slider__dots a {
  padding: 12px;
  position: relative;
}
.slider__dots a span {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.slider__dots a span.focusable:active, .slider__dots a span.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
.slider__dots a:before {
  content: "";
  background-color: rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 0 2px transparent;
  height: 8px;
  width: 8px;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: background-color 200ms ease, box-shadow 200ms ease;
}
.slider__dots a:hover:before {
  background-color: #2c364a;
  box-shadow: 0 0 0 2px #ffffff;
}
.slider__dots a:focus:before {
  background-color: #2c364a;
  box-shadow: 0 0 0 2px #ffffff;
}
.slider__dots a.active:before {
  background-color: #2c364a;
  transition-delay: 50ms;
  box-shadow: 0 0 0 2px #ffffff;
}
.slider__arrows {
  -webkit-appearance: unset;
  position: absolute;
  top: calc(50% - 6vw);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  white-space: normal;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
  background: transparent;
  text-decoration: none;
  border-radius: 0;
  border: none;
  outline: none;
  opacity: 1;
  padding: 0;
  transition: background-color 250ms ease-in-out, color 250ms ease-in-out, border-color 250ms ease-in-out;
  width: 6vw;
  height: 12vw;
}
@media screen and (min-width: 48em) {
  .slider__arrows {
    top: calc(50% - 20px);
    width: 40px;
    height: 40px;
  }
}
@media screen and (min-width: 75em) {
  .slider__arrows {
    top: calc(50% - 28px);
    width: 56px;
    height: 56px;
  }
}
.slider__arrows.previous {
  left: 0;
}
.slider__arrows.next {
  right: 0;
}
.slider__arrows * {
  pointer-events: none;
}
@supports (filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.25))) {
  .slider__arrows svg {
    filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.25));
  }
}
.slider__arrows svg path,
.slider__arrows svg circle,
.slider__arrows svg ellipse,
.slider__arrows svg rect,
.slider__arrows svg line,
.slider__arrows svg polygon {
  stroke: #EEE;
}

[aria-label=gallery]:focus {
  outline: 4px solid DodgerBlue;
  outline-offset: -4px; /* compensates for 2px border */
}

.slider__item img {
  object-fit: cover;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
.modal-window {
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -999;
  transition: background 250ms ease-in-out;
}
.modal-window--visible {
  z-index: 900000;
  background-color: rgba(0, 0, 0, 0.8);
}
.modal-window__mainbox {
  background-color: #ffffff;
  width: 100ch;
  max-width: 94%;
  max-height: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0%) scale(0.75);
  opacity: 0;
  padding: 8px 16px;
  transition: all 250ms ease-in-out;
}
@media screen and (min-width: 30em) {
  .modal-window__mainbox {
    padding: 16px 24px;
  }
}
@media screen and (min-width: 48em) {
  .modal-window__mainbox {
    padding: 32px 48px;
  }
}
.modal-window--visible .modal-window__mainbox {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}
.modal-window__mainbox__closebtn {
  cursor: pointer;
  padding: 0;
  width: 32px;
  height: 32px;
  background-color: #f6f6f6;
  position: absolute;
  right: -8px;
  top: -8px;
  display: grid;
  justify-content: center;
  align-items: center;
  border: 2px solid #f6f6f6;
  border-radius: 100px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  transition: background 250ms ease-in-out, box-shadow 250ms ease-in-out;
}
@media screen and (min-width: 30em) {
  .modal-window__mainbox__closebtn {
    right: -16px;
    top: -16px;
  }
}
.modal-window__mainbox__closebtn svg {
  width: 16px;
  height: 16px;
  opacity: 0.65;
  transition: opacity 250ms ease-in-out;
  pointer-events: none;
}
.modal-window__mainbox__closebtn:hover, .modal-window__mainbox__closebtn:focus, .modal-window__mainbox__closebtn:active {
  background-color: #EEE;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.35);
}
.modal-window__mainbox__closebtn:hover svg, .modal-window__mainbox__closebtn:focus svg, .modal-window__mainbox__closebtn:active svg {
  opacity: 1;
}

/* content area specific stlyes */
.md-window--centered {
  text-align: center;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/* ---- Breadcrumbs ---- */
.breadcrumb {
  list-style: none;
  margin: 8px 0 32px;
  padding: 0;
  font-size: var(--centi-font-size);
  font-family: "DIN Next W01", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 100;
  text-transform: uppercase;
}

.breadcrumb > li {
  display: inline;
}

.breadcrumb > li:before {
  content: " // ";
}

.breadcrumb > li:first-child:before {
  content: none;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
.related-links {
  margin-bottom: 1.5em;
  /* - Depth 1 - */
  /* - Depth 2 - */
}
.related-links__title {
  line-height: normal;
}
.related-links ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  /* Active / on / current */
}
.related-links ul a {
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: inherit;
  transition: color 250ms ease-in-out, background-color 250ms ease-in-out;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.related-links ul a:after {
  content: "";
  position: absolute;
  bottom: 0.5em;
  left: 0;
  right: 0;
  height: 1px;
  background-color: currentColor;
  transition: left 250ms ease-in-out, right 250ms ease-in-out, background-color 250ms ease-in-out;
}
.related-links ul a:after {
  left: 50%;
  right: 50%;
}
.related-links ul a:hover, .related-links ul a:focus, .related-links ul a:active {
  color: #0073BC;
}
.related-links ul a:hover:after, .related-links ul a:focus:after, .related-links ul a:active:after {
  left: 0;
  right: 0;
}
.related-links ul li.current-item > a:before {
  background-color: currentColor;
  transition: left 250ms ease-in-out, right 250ms ease-in-out, background-color 100ms ease-in-out;
}
.related-links ul li.current-item > a:after {
  background-color: #0073BC;
  transition: left 250ms 100ms ease-in-out, right 250ms 100ms ease-in-out, background-color 250ms ease-in-out;
}
.related-links ul li.current-item > a:hover, .related-links ul li.current-item > a:focus, .related-links ul li.current-item > a:active {
  color: #0073BC;
}
.related-links ul li.current-item > a:hover:before, .related-links ul li.current-item > a:focus:before, .related-links ul li.current-item > a:active:before {
  background-color: #f6f6f6;
}
.related-links .depth1 li {
  display: block;
}
.related-links .depth2 {
  margin-left: 0.5em;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
:root {
  --menu-item-vertical-padding: 5px;
}

@media screen and (min-width: 48em) {
  :root {
    --menu-item-vertical-padding: 6px;
  }
}
@media screen and (min-width: 62em) {
  :root {
    --menu-item-vertical-padding: 8px;
  }
}
@media screen and (min-width: 75em) {
  :root {
    --menu-item-vertical-padding: 7px;
  }
}
/** Desktop and Mobile styling
===================================*/
#frankenmenu-list,
#frankenmenu-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
}

#frankenmenu-list a {
  text-decoration: none;
  display: block;
  font-size: 1.6rem;
}

/** Desktop menu only
===================================*/
@media screen and (min-width: 48em) {
  #frankenmenu-list {
    text-align: left;
  }
  #mobile-header {
    display: none;
  }
  /* ---- All level menu items ---- */
  #frankenmenu-list .menu-item {
    position: relative;
  }
  #frankenmenu-list .sub-menu {
    display: none;
    min-width: 200px;
    position: absolute;
    box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.4);
    z-index: 1;
    min-width: 250px;
    max-width: 350px;
    text-align: left;
    top: 0;
    left: 100%;
  }
  /* ---- First level menu ---- */
  #frankenmenu-list .menu-item-depth1 {
    float: left;
    text-align: center;
    margin-right: 11px;
  }
  /* Home */
  /* Current item */
  /* ---- Submenus ---- */
  /* ---- First level submenus ---- */
  /* ---- Right aligned submenus ---- */
  /* ---- Sub-navigation ---- */
  /* ---- Moved elements ---- */
  /* ---- Megamenu ---- */
  /* Remove relative positioning for mega menu */
  /* Megamenu preview */
  /* Megamenu columns */
  /* Megamenu submenus */
}
@media screen and (min-width: 48em) and (min-width: 48em) {
  #frankenmenu-list .menu-item-depth1 {
    margin-right: 8px;
  }
}
@media screen and (min-width: 48em) and (min-width: 62em) {
  #frankenmenu-list .menu-item-depth1 {
    margin-right: 14px;
  }
}
@media screen and (min-width: 48em) {
  #frankenmenu-list .menu-item-depth1 > a {
    display: block;
    color: #a8b4cc;
    font-size: 1.2rem;
    padding-top: calc(var(--menu-item-vertical-padding) * 1.4);
    padding-bottom: calc(var(--menu-item-vertical-padding) * 0.8);
    padding-inline: 3px;
    font-family: "DIN Next W01", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1.2;
  }
}
@media screen and (min-width: 48em) and (min-width: 48em) {
  #frankenmenu-list .menu-item-depth1 > a {
    padding-inline: 2px;
  }
}
@media screen and (min-width: 48em) and (min-width: 61.9375em) {
  #frankenmenu-list .menu-item-depth1 > a {
    font-size: 1.4rem;
  }
}
@media screen and (min-width: 48em) and (min-width: 75em) {
  #frankenmenu-list .menu-item-depth1 > a {
    padding-top: calc(var(--menu-item-vertical-padding) * 1.5);
    padding-inline: 5px;
    font-size: 1.8rem;
  }
}
@media screen and (min-width: 48em) {
  #frankenmenu-list .menu-item-depth1 > a:hover,
  #frankenmenu-list .menu-item-depth1 > a:focus,
  #frankenmenu-list .menu-item-depth1.frankenhover > a,
  #frankenmenu-list .menu-item-depth1.frankenhold > a {
    color: #282f40;
  }
  #frankenmenu-list > .menu-item.menu-home-page > a {
    text-indent: -9999px;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
    width: 22px;
  }
}
@media screen and (min-width: 48em) and (min-width: 48em) {
  #frankenmenu-list > .menu-item.menu-home-page > a {
    width: 24px;
  }
}
@media screen and (min-width: 48em) and (min-width: 61.9375em) {
  #frankenmenu-list > .menu-item.menu-home-page > a {
    width: 26px;
  }
}
@media screen and (min-width: 48em) and (min-width: 75em) {
  #frankenmenu-list > .menu-item.menu-home-page > a {
    width: 29px;
  }
}
@media screen and (min-width: 48em) {
  #frankenmenu-list > .menu-item.menu-home-page > a:before {
    content: "\e90d";
    font-family: "icomoon" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    text-indent: 0;
    position: absolute;
    top: 1px;
    left: 0;
    font-size: 22px;
  }
}
@media screen and (min-width: 48em) and (min-width: 48em) {
  #frankenmenu-list > .menu-item.menu-home-page > a:before {
    top: calc(var(--menu-item-vertical-padding) * 0.4);
    left: 0;
    font-size: 23px;
  }
}
@media screen and (min-width: 48em) and (min-width: 61.9375em) {
  #frankenmenu-list > .menu-item.menu-home-page > a:before {
    left: -1px;
    font-size: 28px;
  }
}
@media screen and (min-width: 48em) and (min-width: 75em) {
  #frankenmenu-list > .menu-item.menu-home-page > a:before {
    top: calc(var(--menu-item-vertical-padding) * 0.25);
    left: -2px;
    font-size: 34px;
  }
}
@media screen and (min-width: 48em) {
  #frankenmenu-list .menu-item-depth1.menu-current-item > a,
  #frankenmenu-list .menu-item-depth1.menu-current-item-ancestor > a {
    border-bottom: 2px solid #0073BC;
    color: #0073BC;
  }
}
@media screen and (min-width: 48em) and (min-width: 75em) {
  #frankenmenu-list .menu-item-depth1.menu-current-item > a,
  #frankenmenu-list .menu-item-depth1.menu-current-item-ancestor > a {
    border-bottom-width: 3px;
  }
}
@media screen and (min-width: 48em) {
  #frankenmenu-list .sub-menu a {
    border-top: 1px solid #ffffff;
    padding: 9px 18px 10px;
    font-size: 1.5rem;
    line-height: 1.8rem;
    background-color: #ffffff;
    color: #445374;
    transition: all 200ms ease;
  }
  #frankenmenu-list .sub-menu .menu-item:first-child a {
    border-top: none;
  }
  #frankenmenu-list .sub-menu .menu-item > a:hover,
  #frankenmenu-list .sub-menu .menu-item > a:focus,
  #frankenmenu-list .sub-menu .menu-item.frankenhover > a,
  #frankenmenu-list .sub-menu .menu-item.frankenhold > a {
    background: #445374;
    color: #ffffff;
  }
  #frankenmenu-list .frankenhold > .sub-menu {
    display: block !important;
    opacity: 1 !important;
  }
  #frankenmenu-list .sub-menu-depth1 {
    top: 100%;
    left: auto;
  }
  #frankenmenu-list .sub-menu.submenu-right-align,
  #frankenmenu-list .sub-menu.submenu-right-align .sub-menu {
    left: auto;
    right: 100%;
  }
  #frankenmenu-list .sub-menu-depth1.submenu-right-align {
    left: auto;
    right: 0;
  }
  #frankenmenu-list .menu-item-subnav {
    display: none;
  }
  #frankenmenu-subnav-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  #frankenmenu-subnav-list .menu-item-depth1 {
    display: inline-block;
  }
  #frankenmenu-subnav-list .menu-item-depth1 > a {
    text-decoration: none;
    display: block;
    color: inherit;
  }
  #frankenmenu-subnav-list .menu-item-depth1 > a:hover {
    text-decoration: underline;
  }
  #frankenmenu-subnav-list .sub-menu {
    display: none;
  }
  .frankenmoved {
    display: none;
  }
  #frankenmenu-list .mega-menu {
    left: 0;
    right: 0;
    background-color: #FFF;
    text-align: left;
    top: 100%;
    position: absolute;
    border-bottom: 4px solid #D8D8D8;
    z-index: 51;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    width: 1px;
  }
  .frankenmenu-mega #frankenmenu-list .menu-item > a {
    position: static;
  }
  #frankenmenu-list .menu-item.frankenhover .mega-menu,
  #frankenmenu-list .menu-item.frankenhold .mega-menu {
    padding: 40px 0;
    width: auto;
    height: auto;
    clip: auto;
    overflow: visible;
    margin: auto;
  }
  #frankenmenu-list .menu-item.frankenhold .mega-menu {
    display: block !important;
    height: auto !important;
  }
  #frankenmenu-list .mega-menu-preview {
    float: left;
    color: #FFF;
    padding: 18px 20px;
    background-color: #6F6F6F;
    width: 22%;
    margin-right: 3%;
  }
  #frankenmenu-list .mega-menu-preview-image {
    height: 120px;
    background: url(../images/mega-menu-image-fallback.jpg) no-repeat center center;
    background-size: cover;
  }
  #frankenmenu-list .mega-menu-preview-text {
    margin-bottom: 0;
    line-height: 1.2;
  }
  #frankenmenu-list .mega-menu .mega-menu-columns {
    float: left;
    width: 75%;
  }
  #frankenmenu-list .mega-menu-column {
    width: 31.33333333%;
    float: left;
    margin-right: 3%;
  }
  #frankenmenu-list .mega-menu-column:last-child {
    margin-right: 0;
  }
  #frankenmenu-list .mega-menu-submenu .menu-item {
    border-top: 1px solid #D8D8D8;
  }
  #frankenmenu-list .mega-menu-submenu .menu-item:last-child {
    border-bottom: 1px solid #D8D8D8;
  }
  #frankenmenu-list .mega-menu-submenu a {
    color: #000;
    padding: 5px 0;
  }
}
/** Mobile menu only
===================================*/
@media screen and (max-width: 47.9375em) {
  /* Force iOS click events trigger anywhere */
  html {
    cursor: pointer;
  }
  #wrap {
    margin-top: 40px;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-color: #ffffff;
  }
  #frankenmenu {
    top: 0;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    background-color: #ffffff;
    color: #445374;
    width: 70%;
    z-index: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    margin-top: 40px;
    padding-top: 16px;
    border-top: 1px solid #eef4f7;
  }
  #frankenmenu .container {
    position: relative;
    padding: 0;
    max-width: none;
    width: 100%;
    margin: 0;
    opacity: 0;
  }
  /* ---- Mobile header ---- */
  #mobile-header {
    background-color: #ffffff;
    height: var(--header-height);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }
  #mobile-header .mobile-header__logo-text {
    width: 68px;
  }
  #mobile-header .mobile-header__logo-circle {
    width: 36px;
    margin-right: 6px;
  }
  #mobile-header .container {
    display: flex;
    justify-content: space-between;
  }
  /* -- Menu button -- */
  #mobile-menu-button {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    outline: none;
    border: none;
    padding: 0;
    position: relative;
    width: 42px;
    height: 40px;
    background: none;
    vertical-align: top;
    margin: 0;
  }
  .show-focus-outlines #mobile-menu-button:focus {
    outline: 1px dotted #ff2128;
  }
  .mobile-menu-button-line {
    height: 3px;
    background-color: #445374;
    position: absolute;
    display: block;
    left: 6px;
    animation-fill-mode: forwards;
    animation-duration: 300ms;
    right: 6px;
  }
  .mobile-menu-button-top {
    top: 10px;
  }
  .mobile-menu-button-middle {
    top: 19px;
  }
  .mobile-menu-button-bottom {
    top: 28px;
  }
  /* Animations active */
  .nav-happened .mobile-menu-button-top {
    animation-name: mobileMenuBtnTopClose;
  }
  .nav-happened .mobile-menu-button-middle {
    animation-name: mobileMenuBtnMiddleClose;
  }
  .nav-happened .mobile-menu-button-bottom {
    animation-name: mobileMenuBtnBottomClose;
  }
  /* Close button */
  .frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-top {
    animation-name: mobileMenuBtnTopOpen;
  }
  .frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-middle {
    animation-name: mobileMenuBtnMiddleOpen;
  }
  .frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-bottom {
    animation-name: mobileMenuBtnBottomOpen;
  }
  /* ---- All level menu items ---- */
  #frankenmenu-list, #frankenmenu-list ul {
    width: 100%;
  }
  #frankenmenu-list .menu-item {
    position: relative;
    border-top: 1px solid rgba(68, 83, 116, 0.1);
    float: none;
    width: 100%;
  }
  #frankenmenu-list a {
    min-height: 39px;
    width: 100%;
    padding: 14px 85px 9px 20px;
    line-height: 1.05;
    color: #445374;
    font-family: "DIN Next W01", sans-serif;
    text-transform: uppercase;
  }
  #frankenmenu-list .menu-item.menu-current-item > a {
    background-color: #009EE2;
    color: #ffffff;
    padding-left: 17px;
  }
  #frankenmenu-list .menu-item.menu-current-item .submenu-toggle {
    color: #ffffff;
  }
  #frankenmenu-list .menu-item.menu-current-item .submenu-toggle:before {
    background-image: url(../images/icon-menu-white.svg);
  }
  /* ---- First level menu ---- */
  #frankenmenu-list .menu-item-depth1:last-child {
    border-bottom: 1px solid rgba(68, 83, 116, 0.1);
  }
  #frankenmenu-list .menu-item-depth1 > a {
    font-size: 1.4rem;
  }
  #frankenmenu-list .icon-circle-list__item .icon-before:before {
    content: none;
  }
  #frankenmenu-list .icon-circle-list__item .-vis-hidden {
    clip: auto;
    height: auto;
    margin: auto;
    overflow: visible;
    position: relative;
    width: auto;
  }
  #frankenmenu-list .icon-circle-list__item {
    margin: 0;
  }
  /* ---- Submenus ---- */
  #frankenmenu-list .sub-menu {
    display: none;
  }
  #frankenmenu-list .menu-current-item-ancestor > .sub-menu {
    display: block;
  }
  #frankenmenu-list .menu-item-depth1.menu-current-item-ancestor > a {
    border-left: 3px solid #009EE2;
    background-color: #e2f6ff;
  }
  /* Depth differences */
  #frankenmenu-list .menu-item-depth1 > a {
    padding-left: 20px;
    background-color: #ffffff;
    color: #445374;
  }
  #frankenmenu-list .menu-item-depth2 a {
    font-size: 1.4rem;
  }
  #frankenmenu-list .menu-item-depth2 > a {
    padding-left: 28px;
  }
  #frankenmenu-list .menu-item-depth3 > a {
    padding-left: 36px;
  }
  #frankenmenu-list .menu-item-depth4 > a {
    padding-left: 44px;
  }
  #frankenmenu-list .menu-item-depth5 > a {
    padding-left: 52px;
  }
  #frankenmenu-list .menu-item-depth6 > a {
    padding-left: 52px;
  }
  /* Expand buttons */
  .submenu-toggle {
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    border: none;
    color: #445374;
    font-size: 2.3rem;
    background: transparent;
    width: 55px;
    height: 39px;
    display: block;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    padding: 0;
  }
  .submenu-toggle:before {
    content: " ";
    width: 21px;
    height: 12px;
    background: url(../images/icon-menu.svg) no-repeat 0 -30px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0 -50% 0 0;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    transition: -webkit-transform 300ms ease-in;
    transition: transform 300ms ease-in;
  }
  .menu-item-submenu-open > .submenu-toggle:before {
    -webkit-transform: translate(-50%, -50%) rotate(180deg);
    transform: translate(-50%, -50%) rotate(180deg);
  }
  /* ---- Sub-navigation ---- */
  #frankenmenu-subnav-list {
    display: none;
  }
  /* ---- Menu positions ---- */
  /* Left */
  .frankenmenu-mobile-pos-left #frankenmenu {
    left: 0;
  }
  .frankenmenu-mobile-pos-left #frankenmenu .container {
    -webkit-transform: translate3d(-10%, 0, 0) scale(0.9);
    transform: translate3d(-10%, 0, 0) scale(0.9);
  }
  .frankenmenu-mobile-pos-left.frankenmenu-mob-menu-visible #wrap {
    -webkit-transform: translate3d(70%, 0, 0);
    transform: translate3d(70%, 0, 0);
  }
  /* Right */
  .frankenmenu-mobile-pos-right #frankenmenu {
    right: 0;
  }
  .frankenmenu-mobile-pos-right #frankenmenu .container {
    -webkit-transform: translate3d(10%, 0, 0) scale(0.9);
    transform: translate3d(10%, 0, 0) scale(0.9);
  }
  .frankenmenu-mobile-pos-right.frankenmenu-mob-menu-visible #wrap {
    -webkit-transform: translate3d(-70%, 0, 0);
    transform: translate3d(-70%, 0, 0);
  }
  .frankenmenu-mobile-pos-right #mobile-menu-button {
    float: right;
  }
  /* ---- Transitions ---- */
  #frankenmenu,
  #wrap,
  #frankenmenu .container {
    transition: all 350ms ease;
  }
  /* Menu open */
  .frankenmenu-mob-menu-visible #frankenmenu {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .frankenmenu-mob-menu-visible #frankenmenu .container {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
  }
  /* ---- Moved elements ---- */
  .frankenmove {
    display: none;
  }
  /* ---- Megamenu ---- */
  .mega-menu-preview {
    display: none;
  }
  .mega-menu-preview-image {
    background-image: none !important;
  }
  .mega-menu-column-title {
    color: #FFF;
    font-size: 1.6rem;
    padding: 3px 20px;
    margin: 0;
    background: rgba(0, 0, 0, 0.3);
  }
  #frankenmenu-list .mega-menu {
    display: none;
  }
  #frankenmenu-list .mega-menu ul {
    display: block;
  }
}
/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
.top-nav-section {
  background-color: #2c364a;
  color: #ffffff;
  padding: 8px 0;
}
@media screen and (max-width: 47.9375em) {
  .top-nav-section {
    display: none;
  }
}

.top-nav {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}
.top-nav :where(a) {
  color: rgba(255, 255, 255, 0.5);
  text-decoration-color: transparent;
  text-decoration-thickness: 1px;
  transition: color 250ms ease-in-out, text-decoration 250ms ease-in-out;
}
.top-nav :where(a):hover, .top-nav :where(a):focus, .top-nav :where(a):active {
  color: #EEE;
  text-decoration-color: currentColor;
}

.top-nav-item {
  display: grid;
  gap: 8px;
  grid-auto-flow: column;
  align-items: center;
}
.top-nav-item svg path,
.top-nav-item svg circle,
.top-nav-item svg ellipse,
.top-nav-item svg rect,
.top-nav-item svg line,
.top-nav-item svg polygon {
  fill: currentColor;
}
.top-nav-item__link {
  display: grid;
}
.top-nav-item::before {
  content: "|";
  font-weight: normal;
  opacity: 0.25;
}
.top-nav-item:first-child::before {
  content: none;
}
.top-nav-item--search svg {
  width: 16px;
  height: 16px;
}

/* ---- Search pagination ---- */
.search-paginate {
  padding: 10px 0;
  text-align: center;
}

.search-paginate .page-prev {
  margin: 0 8px 0 0;
}

.search-paginate .page {
  padding: 2px 9px;
  margin: 0 3px;
  background: #EEE;
  border: 1px #e85505 solid;
  text-decoration: none;
}

.search-paginate .page.on {
  color: #000000;
  border-color: #000000;
}

.search-paginate .page-next {
  margin: 0 0 0 8px;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/* ---- Partners list ---- */
.partners-logo-list {
  list-style: none;
  margin: 10px 0;
  padding: 0;
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 30em) {
  .partners-logo-list {
    gap: 1.5em;
  }
}
.partners-logo-list img {
  display: block;
  height: 26px;
  width: auto;
}
@media screen and (min-width: 22.5em) {
  .partners-logo-list img {
    height: 32px;
  }
}
@media screen and (min-width: 48em) {
  .partners-logo-list img {
    height: 36px;
  }
}
@media screen and (min-width: 62em) {
  .partners-logo-list img {
    height: 44px;
  }
}

.partners-logo-list__item {
  flex: 1 1 auto;
}

.partners-logo-list__item-link,
.partners-logo-list__wrapper {
  display: grid;
  place-items: center;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/* [01]
    Bleeds commented so as to not bloat the css unnecessarily.
    Uncomment to use
*/
.bg-white {
  background-color: #ffffff;
}

.bg-grey {
  background-color: #EEE;
}

.bg-lightblue {
  background-color: #eef4f7;
}

.bg-lightgrey {
  background-color: #f6f6f6;
}
.bg-lightgrey.background--bleed {
  position: relative;
  position: relative;
}
.bg-lightgrey.background--bleed:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 100%;
  width: 100vw;
  background-color: #f6f6f6;
}
.bg-lightgrey.background--bleed:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
  width: 100vw;
  background-color: #f6f6f6;
}

.bg-darkblue,
.bg-00 {
  background-color: #2c364a;
  color: #ffffff;
}
.bg-darkblue :where(a),
.bg-00 :where(a) {
  color: currentColor;
}
.bg-darkblue :where(a):hover,
.bg-darkblue :where(a):focus,
.bg-darkblue :where(a):active,
.bg-00 :where(a):hover,
.bg-00 :where(a):focus,
.bg-00 :where(a):active {
  color: rgba(255, 255, 255, 0.75);
}
.bg-darkblue :where(h1),
.bg-darkblue :where(h2),
.bg-darkblue :where(h3),
.bg-darkblue :where(h4),
.bg-00 :where(h1),
.bg-00 :where(h2),
.bg-00 :where(h3),
.bg-00 :where(h4) {
  color: #ffffff;
}
.bg-darkblue.background--bleed,
.bg-00.background--bleed {
  position: relative;
  position: relative;
}
.bg-darkblue.background--bleed:before,
.bg-00.background--bleed:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 100%;
  width: 100vw;
  background-color: #2c364a;
}
.bg-darkblue.background--bleed:after,
.bg-00.background--bleed:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
  width: 100vw;
  background-color: #2c364a;
}

.bg-darkerblue {
  background-color: #282f40;
  color: #ffffff;
}
.bg-darkerblue :where(a) {
  color: currentColor;
}
.bg-darkerblue :where(a):hover,
.bg-darkerblue :where(a):focus,
.bg-darkerblue :where(a):active {
  color: rgba(255, 255, 255, 0.75);
}
.bg-darkerblue :where(h1),
.bg-darkerblue :where(h2),
.bg-darkerblue :where(h3),
.bg-darkerblue :where(h4) {
  color: #ffffff;
}

.bg-logoblue,
.bg-01 {
  background-color: #0073BC;
  color: #ffffff;
}
.bg-logoblue :where(a),
.bg-01 :where(a) {
  color: currentColor;
}
.bg-logoblue :where(a):hover,
.bg-logoblue :where(a):focus,
.bg-logoblue :where(a):active,
.bg-01 :where(a):hover,
.bg-01 :where(a):focus,
.bg-01 :where(a):active {
  color: rgba(255, 255, 255, 0.75);
}
.bg-logoblue :where(h1),
.bg-logoblue :where(h2),
.bg-logoblue :where(h3),
.bg-logoblue :where(h4),
.bg-01 :where(h1),
.bg-01 :where(h2),
.bg-01 :where(h3),
.bg-01 :where(h4) {
  color: #ffffff;
}
.bg-logoblue.background--bleed,
.bg-01.background--bleed {
  position: relative;
  position: relative;
}
.bg-logoblue.background--bleed:before,
.bg-01.background--bleed:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 100%;
  width: 100vw;
  background-color: #0073BC;
}
.bg-logoblue.background--bleed:after,
.bg-01.background--bleed:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
  width: 100vw;
  background-color: #0073BC;
}

.bg-02 {
  background-color: #00AAE9;
}
.bg-02.background--bleed {
  position: relative;
  position: relative;
}
.bg-02.background--bleed:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 100%;
  width: 100vw;
  background-color: #00AAE9;
}
.bg-02.background--bleed:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
  width: 100vw;
  background-color: #00AAE9;
}

.bg-green,
.bg-05 {
  background-color: #61b258;
  color: #ffffff;
}
.bg-green :where(a),
.bg-05 :where(a) {
  color: currentColor;
}
.bg-green :where(a):hover,
.bg-green :where(a):focus,
.bg-green :where(a):active,
.bg-05 :where(a):hover,
.bg-05 :where(a):focus,
.bg-05 :where(a):active {
  color: rgba(255, 255, 255, 0.75);
}
.bg-green :where(h1),
.bg-green :where(h2),
.bg-green :where(h3),
.bg-green :where(h4),
.bg-05 :where(h1),
.bg-05 :where(h2),
.bg-05 :where(h3),
.bg-05 :where(h4) {
  color: #ffffff;
}
.bg-green.background--bleed,
.bg-05.background--bleed {
  position: relative;
  position: relative;
}
.bg-green.background--bleed:before,
.bg-05.background--bleed:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 100%;
  width: 100vw;
  background-color: color-06;
}
.bg-green.background--bleed:after,
.bg-05.background--bleed:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
  width: 100vw;
  background-color: color-06;
}

.bg-06 {
  background-color: #50a247;
}
.bg-06.background--bleed {
  position: relative;
  position: relative;
}
.bg-06.background--bleed:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 100%;
  width: 100vw;
  background-color: color-08;
}
.bg-06.background--bleed:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
  width: 100vw;
  background-color: color-08;
}

.bg-grey-dient {
  background: linear-gradient(to bottom, #f6f6f6 0%, #ffffff 100%);
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
:root {
  --section: 26px;
  --section-lg: 40px;
}

@media screen and (min-width: 22.5em) {
  :root {
    --section: 32px;
    --section-lg: 48px;
  }
}
@media screen and (min-width: 48em) {
  :root {
    --section: 40px;
    --section-lg: 64px;
  }
}
@media screen and (min-width: 62em) {
  :root {
    --section: 56px;
    --section-lg: 80px;
  }
}
@media screen and (min-width: 75em) {
  :root {
    --section: 80px;
    --section-lg: 96px;
  }
}
.section {
  position: relative;
  padding-top: var(--section);
  padding-bottom: var(--section);
}

.section-t0 {
  padding-top: 0;
}

.section-b0 {
  padding-bottom: 0;
}

/* ---- Testimonials ---- */
.section--testimonial__button {
  margin-top: 20px;
}

/* BP 3 */
@media screen and (max-width: 760px) {
  .section--testimonial__button {
    margin-top: 10px;
  }
}
/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/* ---- Quotations ---- */
blockquote {
  margin-block: 1rem;
  padding: 5px 20px 15px;
  background: #f6f6f6;
  font-style: normal;
}
@media screen and (min-width: 62em) {
  blockquote {
    margin-block: 1.5rem;
  }
}

/* Plain blockquote */
.blockquote-plain {
  background: none;
  padding: 0;
}

.blockquote-plain__body {
  color: #7e8fb5;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 2rem;
  line-height: 1.3;
}
@media screen and (min-width: 48em) {
  .blockquote-plain__body {
    font-size: 2.2rem;
  }
}
@media screen and (min-width: 62em) {
  .blockquote-plain__body {
    font-size: 2.9rem;
  }
}

.blockquote-plain__body:before,
.blockquote-plain__body:after {
  display: inline;
}

.blockquote-plain__body:before {
  content: open-quote;
}

.blockquote-plain__body:after {
  content: close-quote;
}

.blockquote-plain__body.blockquote-plain__hide-quotes:before,
.blockquote-plain__body.blockquote-plain__hide-quotes:after {
  display: none;
}

.blockquote-plain cite {
  font-style: normal;
  color: #2c364a;
  font-family: "DIN Next W01", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  text-align: right;
  line-height: 1.4;
}

.blockquote-plain__author {
  font-size: 1.6rem;
}
@media screen and (min-width: 48em) {
  .blockquote-plain__author {
    font-size: 1.7rem;
  }
}
@media screen and (min-width: 62em) {
  .blockquote-plain__author {
    font-size: 1.9rem;
  }
}

.blockquote-plain__author:before {
  content: "- ";
}

.blockquote-plain__author-from {
  font-size: 1.3rem;
}
@media screen and (min-width: 48em) {
  .blockquote-plain__author-from {
    font-size: 1.4rem;
  }
}
@media screen and (min-width: 62em) {
  .blockquote-plain__author-from {
    font-size: 1.5rem;
  }
}

.blockquote-plain__author,
.blockquote-plain__author-from {
  margin: 0;
}

.blockquote-plain__body .expando {
  margin: 0;
  padding: 0;
  border: none;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/* ---- Expando List ---- */
.expando-list {
  list-style: none;
  padding: 20px 0 10px;
  margin: 0;
}

.expando-opener-heading {
  padding: 0 0 0 42px;
  cursor: pointer;
  position: relative;
}

.expando-opener-heading:hover {
  color: #000;
}

.expando {
  border: 1px solid #e5e5e5;
  padding: 18px 20px 10px;
  margin: 10px 0 30px;
}

ul.expando-list ul {
  list-style: disc;
}

.expando-opener-heading:before {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  content: "\e900";
  padding: 4px;
  display: block;
  float: left;
  background-color: #f4f4f4;
  margin: -4px 15px 11px 0;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
}

.expando-opener-heading:hover:before {
  background-color: #e4e4e4;
}

.expando-opener-heading.expanded:before {
  content: "\e903";
}

.expando.expanded {
  -webkit-animation: fadeIn 300ms ease;
  animation: fadeIn 300ms ease;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
.highlight,
.highlight--right,
.highlight--left {
  margin: 0 0 1.5rem;
  padding: 30px;
  background: #f6f6f6;
}

@media screen and (min-width: 1000px) {
  .highlight--left {
    float: left;
    width: 45%;
    margin-right: 20px;
  }
  .highlight--right {
    float: right;
    width: 45%;
    margin-left: 20px;
  }
}
.highlight *:last-child,
.highlight--right *:last-child,
.highlight--left *:last-child {
  margin-bottom: 0;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/* ---- Site Messages ---- */
.messages {
  list-style: none;
  margin: 16px 0 25px;
  padding: 0;
}

.messages li {
  margin: 0 0 15px;
  padding: 10px 20px;
  vertical-align: bottom;
  text-align: left;
  font-size: var(--body-font-size);
  line-height: 1.2em;
}

.messages .error {
  background: #E04D4D;
  border-left: 4px solid #B72323;
  color: #ffffff;
}

.messages .confirm {
  background: #6DBB5A;
  border-left: 4px solid #439230;
  color: #ffffff;
}

.messages a {
  color: #ffffff;
}

.quote-box {
  padding: 24px 25px;
  color: #3e8f35;
  font-family: "Source Sans Pro", sans-serif;
  line-height: 1.3;
  font-size: 2.8rem;
  position: relative;
}

.quote-box p {
  margin: 0;
}

.quote-box:before {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 25px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 20px 20px;
  border-color: transparent transparent #007bff transparent;
}

/* Light green */
.quote-box--lightgreen {
  background-color: #95d38e;
}

.quote-box.quote-box--lightgreen:before {
  border-color: transparent transparent #95d38e transparent;
}

/* BP 1 */
@media screen and (max-width: 1240px) {
  .quote-box {
    padding: 18px 20px;
    font-size: 2.2rem;
  }
}
/* BP 2 */
@media screen and (max-width: 1000px) {
  .quote-box {
    padding: 16px 18px;
    font-size: 1.9rem;
  }
}
/* BP 4 */
@media screen and (max-width: 620px) {
  .quote-box:before {
    border-width: 0 0 11px 11px;
  }
  .quote-box {
    padding: 9px 15px;
    font-size: 1.6rem;
  }
}
/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
img {
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
:root {
  --tooltip-background: #4b4b4b;
  --tooltip-color: #ffffff;
  --tooltip-lines: #656565;
  --tooltip-header-background: #585858;
  --tooltip-section-padding: 4px 6px;
}

/* ---- Tooltips ---- */
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip--reverse {
  --tooltip-background: #f6f6f6;
  --tooltip-color: #0073BC;
  --tooltip-lines: #dddddd;
  --tooltip-header-background: #e9e9e9;
}

.tooltip__title {
  display: inline-block;
  position: relative;
  z-index: 1;
  opacity: 1;
  font: inherit;
  transition: opacity 100ms ease-in-out;
}
.tooltip__title a {
  color: inherit;
}
.tooltip__title a:hover, .tooltip__title a:focus {
  color: #24292f;
  text-decoration: none;
}

.tooltip__content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--tooltip-background);
  border: 1px solid var(--tooltip-lines);
  max-width: 270px;
  min-width: 120px;
  padding: 0;
  font-size: var(--centi-font-size);
  border-radius: 4px;
  margin-top: 7px;
  text-align: left;
  pointer-events: none;
  color: var(--tooltip-color);
  z-index: calc(var(--header-index) - 1);
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: normal;
  white-space: normal;
  text-transform: none;
  line-height: normal;
}
@media screen and (min-width: 48em) {
  .tooltip__content {
    min-width: 160px;
  }
}
@media screen and (min-width: 62em) {
  .tooltip__content {
    min-width: 200px;
  }
}
.tooltip__content p {
  font-size: var(--milli-font-size);
  margin-bottom: 0.25em;
}
.tooltip__content > *:last-child {
  margin-bottom: 0;
}
.tooltip__content small {
  font-size: 1.1rem;
}
.tooltip__content:before, .tooltip__content:after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 14px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent var(--tooltip-background) transparent;
  z-index: 1;
}
.tooltip__content:after {
  left: 13px;
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent var(--tooltip-lines) transparent;
  z-index: 0;
}

/* Center align */
.tooltip--center .tooltip__content {
  left: 50%;
  margin-right: -50%;
  transform: translateX(-50%);
}

.tooltip--center .tooltip__content:before,
.tooltip--center .tooltip__content:after {
  left: 0;
  right: 0;
  margin: 0 auto;
}

/* Right align */
.tooltip--right .tooltip__content {
  right: 0;
  left: auto;
}

.tooltip--right .tooltip__content:before {
  right: 14px;
  left: auto;
}

/* Up */
.tooltip--up .tooltip__content {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 7px;
}

.tooltip--up .tooltip__content:before {
  border-width: 5px 5px 0 5px;
  border-color: var(--tooltip-background) transparent transparent transparent;
  top: 100%;
}

/* Tooltip animations for hover state */
/* Fade in */
.tooltip:hover .tooltip__content,
.tooltip__title:hover + .tooltip__content {
  display: block;
  animation: fadeIn 200ms ease;
  animation-fill-mode: forwards;
}

/* Transition up */
.tooltip--transition-up .tooltip__content {
  -webkit-animation-name: fadeInUpMargin;
  animation-name: fadeInUpMargin;
}

/* Info icon */
.tooltip__icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: #9c9c9c;
  color: #ffffff;
  width: 1em;
  height: 1em;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  border: none;
  outline: none;
  padding: 0;
  margin: 0 0.2em;
}

.tooltip--icon .tooltip__icon {
  display: inline-block;
}

.tooltip__icon svg {
  display: block;
  width: 0.66em;
  height: 0.66em;
}
.tooltip__icon svg path {
  fill: currentColor;
}

.tooltip--icon .tooltip__title {
  border-bottom: none;
  margin-right: 7px;
}

.tooltip--icon .tooltip__content {
  left: -8px;
  right: auto;
}

.tooltip.tooltip--icon.tooltip--center .tooltip__content {
  right: auto;
  left: 50%;
}

.tooltip--icon.tooltip--right .tooltip__content {
  right: -8px;
  left: auto;
}

/* Disabled */
.tooltip--disabled .tooltip__content {
  display: none;
}

.tooltip__header {
  background-color: var(--tooltip-header-background);
  padding: var(--tooltip-section-padding);
  border-bottom: 1px solid var(--tooltip-lines);
}
.tooltip__header .tooltip__header__title {
  font-weight: 800;
  font-size: var(--centi-font-size);
  margin-bottom: 0;
}

.tooltip__body {
  padding: var(--tooltip-section-padding);
}

.tooltip__footer {
  padding: var(--tooltip-section-padding);
}

.tooltip__body + .tooltip__footer {
  border-top: 1px solid var(--tooltip-lines);
}

.tooltip--center .tooltip__body {
  text-align: center;
}

.tooltip--center .tooltip__header + .tooltip__body {
  text-align: left;
}

/* ---- TinyMCE tweaks ---- */
.mce-panel {
  border-color: #CED2DC !important;
}

.mce-toolbar-grp,
.mce-flow-layout {
  background-color: #EAECF1 !important;
}

.mce-tinymce {
  border-radius: 5px !important;
  overflow: hidden !important;
}

.mce-btn {
  border: 1px solid #EAECF1 !important;
  background-color: #EAECF1 !important;
}

.mce-btn:hover,
.mce-btn:focus {
  color: #333;
  background-color: #DDE0E6 !important;
  border-color: #C6CAD2 !important;
}

.mce-btn.mce-active,
.mce-btn.mce-active:hover {
  background-color: #CED2DC !important;
  border-color: #B5B8C1 !important;
}

.mce-btn:active {
  background-color: #e0e0e0 !important;
  border-color: #ccc !important;
}

.mce-menu-item:hover, .mce-menu-item.mce-selected, .mce-menu-item:focus {
  background-color: #358AAB !important;
  color: #FFF !important;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
.widget-RelatedLinks {
  margin-bottom: 25px;
  font-size: 1.4rem;
  text-transform: uppercase;
  font-family: "DIN Next W01", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 500;
}

.widget-RelatedLinks ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.widget-RelatedLinks li {
  line-height: 1.3;
  margin-bottom: 8px;
}

.widget-RelatedLinks li.depth1:last-child {
  margin-bottom: 0;
}

.widget-RelatedLinks a {
  text-decoration: none;
  display: block;
  color: #a8b4cc;
  padding: 3px 0 0;
}

.widget-RelatedLinks a:hover {
  color: #5c6e96;
}

.widget-RelatedLinks li.on > a,
.widget-RelatedLinks li.on > a:hover {
  color: #2c364a;
  padding-left: 7px;
  border-left: 3px solid #00aae9;
}

.widget-RelatedLinks li.on > ul.depth2 {
  margin-top: 8px;
}

.widget-RelatedLinks ul.depth2 {
  padding-left: 20px;
}

.widget-RelatedLinks li.depth2 {
  margin-bottom: 4px;
}

/* ---- children page gallery ---- */
.children-gallery-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.widget-ChildrenGallery .children-gallery-list-item {
  width: 21%;
  float: left;
  margin: 0 4% 20px 0;
  position: relative;
}

.widget-ChildrenGallery .children-gallery-list-item-link {
  display: block;
  text-decoration: none;
  color: inherit;
}

.widget-ChildrenGallery .children-gallery-list-item-link:hover {
  text-decoration: underline;
}

.widget-ChildrenGallery .children-gallery-list-item-image {
  margin-bottom: 9px;
}

.widget-ChildrenGallery .children-gallery-list-item-link:hover .children-gallery-list-item-image {
  opacity: 0.9;
}

.widget-ChildrenGallery .children-gallery-list-item-title {
  margin: 0;
}

.widget-ChildrenGallery .children-gallery-list-item-anchor {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@media screen and (max-width: 760px) {
  .widget-ChildrenGallery .children-gallery-list-item {
    width: 30.666666%;
  }
  .widget-ChildrenGallery .children-gallery-list-item:nth-child(3n+3) {
    margin-right: 4%;
  }
  .widget-ChildrenGallery .children-gallery-list-item:nth-child(3n+3) {
    margin-right: 0;
  }
}
@media screen and (max-width: 620px) {
  .widget-ChildrenGallery .children-gallery-list-item {
    width: 48%;
  }
  .widget-ChildrenGallery .children-gallery-list-item:nth-child(3n+3) {
    margin-right: 4%;
  }
  .widget-ChildrenGallery .children-gallery-list-item:nth-child(2n+2) {
    margin-right: 0;
  }
}
/* ---- image gallery ---- */
.widget-ImageGallery {
  margin: 30px 0;
}

/* ---- simple image gallery ---- */
.widget-GallerySimple {
  margin: 15px -10px;
}

.widget-GallerySimple h3 {
  padding: 10px;
}

.widget-GallerySimple .gallery-simple-thumb {
  width: 33.333%;
  padding: 10px;
  float: left;
}

.widget-GallerySimple .gallery-simple-thumb img {
  width: 100%;
}

/* ---- Sponsors ---- */
.sponsors-list__item {
  border: 1px solid #cecece;
  width: 48.1481481481%;
  float: left;
  margin-left: 3.5612535613%;
  margin-right: 0;
  text-align: center;
  padding: 10px;
}

.sponsors-list__item__link {
  color: inherit;
  text-decoration: none;
}

.sponsors-list__item__link:hover,
.sponsors-list__item__link:focus,
.sponsors-list__item__link:active {
  text-decoration: underline;
}

.sponsors-list__item a:first-child {
  line-height: 125px;
  display: block;
  vertical-align: middle;
}

.sponsors-list__item__image {
  width: auto;
  display: inline-block;
}

@media screen and (max-width: 620px) {
  .sponsors-list__item:nth-child(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 621px) and (max-width: 1240px) {
  .sponsors-list__item {
    width: 30.9591642925%;
  }
  .sponsors-list__item:nth-child(3n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 1241px) {
  .sponsors-list__item {
    width: 22.3290598291%;
  }
  .sponsors-list__item:nth-child(4n+1) {
    margin-left: 0;
    clear: left;
  }
}
/* ---- order form ---- */
.widget-OrderForm .product {
  width: 30%;
  margin-right: 5%;
  float: left;
}

.widget-OrderForm .product:nth-child(3n+3) {
  margin-right: 0;
}

/* ---- Videos ---- */
.tinymce-media-iframe,
.widget-Video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  overflow: hidden;
  width: 100%;
}

.tinymce-media-iframe iframe,
.tinymce-media-iframe object,
.tinymce-media-iframe embed,
.widget-Video iframe,
.widget-Video object,
.widget-Video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.tinymce-media-iframe {
  height: 0;
  clear: both;
  width: auto;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
.linklist-list {
  list-style-type: none;
  padding-left: 0;
  font-family: "DIN Next W01", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 100;
}

.linklist-list-item a {
  text-decoration: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  padding-block: 0.25em;
}
.linklist-list-item a:after {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  content: "\e908";
  display: block;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
#wrap {
  margin-top: var(--header-height);
  position: relative;
  overflow-x: hidden;
}
@media screen and (min-width: 150em) {
  #wrap {
    max-width: 2400px;
    margin-inline: auto;
  }
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
:root {
  --gutter-width: 1rem;
  --outer-margin: 2rem;
  --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
  --half-gutter-width: calc((var(--gutter-width) * 0.5));
}

.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: var(--gutter-compensation, -0.5rem);
  margin-left: var(--gutter-compensation, -0.5rem);
}

.row.reverse {
  flex-direction: row-reverse;
}

.col.reverse {
  flex-direction: column-reverse;
}

.col-xs,
.col-xs-0,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-offset-0,
.col-xs-offset-1,
.col-xs-offset-2,
.col-xs-offset-3,
.col-xs-offset-4,
.col-xs-offset-5,
.col-xs-offset-6,
.col-xs-offset-7,
.col-xs-offset-8,
.col-xs-offset-9,
.col-xs-offset-10,
.col-xs-offset-11,
.col-xs-offset-12,
.col-xs-shrink,
.col-xs-grow {
  display: initial;
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: var(--half-gutter-width, 0.5rem);
  padding-left: var(--half-gutter-width, 0.5rem);
}

.col-xs {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-xs-0 {
  display: none;
}

.col-xs-1 {
  flex-basis: 8.33333333%;
  max-width: 8.33333333%;
}

.col-xs-2 {
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
}

.col-xs-3 {
  flex-basis: 25%;
  max-width: 25%;
}

.col-xs-4 {
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
}

.col-xs-5 {
  flex-basis: 41.66666667%;
  max-width: 41.66666667%;
}

.col-xs-6 {
  flex-basis: 50%;
  max-width: 50%;
}

.col-xs-7 {
  flex-basis: 58.33333333%;
  max-width: 58.33333333%;
}

.col-xs-8 {
  flex-basis: 66.66666667%;
  max-width: 66.66666667%;
}

.col-xs-9 {
  flex-basis: 75%;
  max-width: 75%;
}

.col-xs-10 {
  flex-basis: 83.33333333%;
  max-width: 83.33333333%;
}

.col-xs-11 {
  flex-basis: 91.66666667%;
  max-width: 91.66666667%;
}

.col-xs-12 {
  flex-basis: 100%;
  max-width: 100%;
}

.col-xs-grow {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  max-width: 100%;
}

.col-xs-shrink {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  max-width: 100%;
}

.col-xs-offset-0 {
  margin-left: 0;
}

.col-xs-offset-1 {
  margin-left: 8.33333333%;
}

.col-xs-offset-2 {
  margin-left: 16.66666667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.33333333%;
}

.col-xs-offset-5 {
  margin-left: 41.66666667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.33333333%;
}

.col-xs-offset-8 {
  margin-left: 66.66666667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.33333333%;
}

.col-xs-offset-11 {
  margin-left: 91.66666667%;
}

.start-xs {
  justify-content: flex-start;
  text-align: start;
}

.center-xs {
  justify-content: center;
}

.end-xs {
  justify-content: flex-end;
  text-align: end;
}

.top-xs {
  align-items: flex-start;
}

.middle-xs {
  align-items: center;
}

.bottom-xs {
  align-items: flex-end;
}

.around-xs {
  justify-content: space-around;
}

.between-xs {
  justify-content: space-between;
}

.first-xs {
  order: -1;
}

.last-xs {
  order: 1;
}

@media screen and (min-width: 35em) {
  .col-xsm,
  .col-xsm-1,
  .col-xsm-2,
  .col-xsm-3,
  .col-xsm-4,
  .col-xsm-5,
  .col-xsm-6,
  .col-xsm-7,
  .col-xsm-8,
  .col-xsm-9,
  .col-xsm-10,
  .col-xsm-11,
  .col-xsm-12,
  .col-xsm-offset-0,
  .col-xsm-offset-1,
  .col-xsm-offset-2,
  .col-xsm-offset-3,
  .col-xsm-offset-4,
  .col-xsm-offset-5,
  .col-xsm-offset-6,
  .col-xsm-offset-7,
  .col-xsm-offset-8,
  .col-xsm-offset-9,
  .col-xsm-offset-10,
  .col-xsm-offset-11,
  .col-xsm-offset-12,
  .col-xsm-grow,
  .col-xsm-shrink {
    display: initial;
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: var(--half-gutter-width, 0.5rem);
    padding-left: var(--half-gutter-width, 0.5rem);
  }
  .row {
    display: flex;
  }
  .col-xsm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-xsm-0 {
    display: none;
  }
  .col-xsm-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xsm-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xsm-3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-xsm-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xsm-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xsm-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-xsm-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xsm-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xsm-9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-xsm-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xsm-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xsm-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-xsm-grow {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    max-width: 100%;
  }
  .col-xsm-shrink {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    max-width: 100%;
  }
  .col-xsm-offset-0 {
    margin-left: 0;
  }
  .col-xsm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-xsm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-xsm-offset-3 {
    margin-left: 25%;
  }
  .col-xsm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-xsm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-xsm-offset-6 {
    margin-left: 50%;
  }
  .col-xsm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-xsm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-xsm-offset-9 {
    margin-left: 75%;
  }
  .col-xsm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-xsm-offset-11 {
    margin-left: 91.66666667%;
  }
  .start-xsm {
    justify-content: flex-start;
    text-align: start;
  }
  .center-xsm {
    justify-content: center;
  }
  .end-xsm {
    justify-content: flex-end;
    text-align: end;
  }
  .top-xsm {
    align-items: flex-start;
  }
  .middle-xsm {
    align-items: center;
  }
  .bottom-xsm {
    align-items: flex-end;
  }
  .around-xsm {
    justify-content: space-around;
  }
  .between-xsm {
    justify-content: space-between;
  }
  .first-xsm {
    order: -1;
  }
  .last-xsm {
    order: 1;
  }
}
@media screen and (min-width: 48em) {
  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-offset-0,
  .col-sm-offset-1,
  .col-sm-offset-2,
  .col-sm-offset-3,
  .col-sm-offset-4,
  .col-sm-offset-5,
  .col-sm-offset-6,
  .col-sm-offset-7,
  .col-sm-offset-8,
  .col-sm-offset-9,
  .col-sm-offset-10,
  .col-sm-offset-11,
  .col-sm-offset-12,
  .col-sm-grow,
  .col-sm-shrink {
    display: initial;
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: var(--half-gutter-width, 0.5rem);
    padding-left: var(--half-gutter-width, 0.5rem);
  }
  .row {
    display: flex;
  }
  .col-sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-sm-0 {
    display: none;
  }
  .col-sm-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-sm-grow {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    max-width: 100%;
  }
  .col-sm-shrink {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    max-width: 100%;
  }
  .col-sm-offset-0 {
    margin-left: 0;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .start-sm {
    justify-content: flex-start;
    text-align: start;
  }
  .center-sm {
    justify-content: center;
  }
  .end-sm {
    justify-content: flex-end;
    text-align: end;
  }
  .top-sm {
    align-items: flex-start;
  }
  .middle-sm {
    align-items: center;
  }
  .bottom-sm {
    align-items: flex-end;
  }
  .around-sm {
    justify-content: space-around;
  }
  .between-sm {
    justify-content: space-between;
  }
  .first-sm {
    order: -1;
  }
  .last-sm {
    order: 1;
  }
}
@media screen and (min-width: 62em) {
  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-offset-0,
  .col-md-offset-1,
  .col-md-offset-2,
  .col-md-offset-3,
  .col-md-offset-4,
  .col-md-offset-5,
  .col-md-offset-6,
  .col-md-offset-7,
  .col-md-offset-8,
  .col-md-offset-9,
  .col-md-offset-10,
  .col-md-offset-11,
  .col-md-offset-12,
  .col-md-grow,
  .col-md-shrink {
    display: initial;
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: var(--half-gutter-width, 0.5rem);
    padding-left: var(--half-gutter-width, 0.5rem);
  }
  .row {
    display: flex;
  }
  .col-md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-md-0 {
    display: none;
  }
  .col-md-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-md-grow {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    max-width: 100%;
  }
  .col-md-shrink {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    max-width: 100%;
  }
  .col-md-offset-0 {
    margin-left: 0;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .start-md {
    justify-content: flex-start;
    text-align: start;
  }
  .center-md {
    justify-content: center;
  }
  .end-md {
    justify-content: flex-end;
    text-align: end;
  }
  .top-md {
    align-items: flex-start;
  }
  .middle-md {
    align-items: center;
  }
  .bottom-md {
    align-items: flex-end;
  }
  .around-md {
    justify-content: space-around;
  }
  .between-md {
    justify-content: space-between;
  }
  .first-md {
    order: -1;
  }
  .last-md {
    order: 1;
  }
}
@media screen and (min-width: 75em) {
  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-offset-0,
  .col-lg-offset-1,
  .col-lg-offset-2,
  .col-lg-offset-3,
  .col-lg-offset-4,
  .col-lg-offset-5,
  .col-lg-offset-6,
  .col-lg-offset-7,
  .col-lg-offset-8,
  .col-lg-offset-9,
  .col-lg-offset-10,
  .col-lg-offset-11,
  .col-lg-offset-12,
  .col-lg-grow,
  .col-lg-shrink {
    display: initial;
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: var(--half-gutter-width, 0.5rem);
    padding-left: var(--half-gutter-width, 0.5rem);
  }
  .row {
    display: flex;
  }
  .col-lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-lg-0 {
    display: none;
  }
  .col-lg-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-lg-grow {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    max-width: 100%;
  }
  .col-lg-shrink {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    max-width: 100%;
  }
  .col-lg-offset-0 {
    margin-left: 0;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .start-lg {
    justify-content: flex-start;
    text-align: start;
  }
  .center-lg {
    justify-content: center;
  }
  .end-lg {
    justify-content: flex-end;
    text-align: end;
  }
  .top-lg {
    align-items: flex-start;
  }
  .middle-lg {
    align-items: center;
  }
  .bottom-lg {
    align-items: flex-end;
  }
  .around-lg {
    justify-content: space-around;
  }
  .between-lg {
    justify-content: space-between;
  }
  .first-lg {
    order: -1;
  }
  .last-lg {
    order: 1;
  }
}
@media screen and (min-width: 77.5em) {
  .col-lg2,
  .col-lg2-1,
  .col-lg2-2,
  .col-lg2-3,
  .col-lg2-4,
  .col-lg2-5,
  .col-lg2-6,
  .col-lg2-7,
  .col-lg2-8,
  .col-lg2-9,
  .col-lg2-10,
  .col-lg2-11,
  .col-lg2-12,
  .col-lg2-offset-0,
  .col-lg2-offset-1,
  .col-lg2-offset-2,
  .col-lg2-offset-3,
  .col-lg2-offset-4,
  .col-lg2-offset-5,
  .col-lg2-offset-6,
  .col-lg2-offset-7,
  .col-lg2-offset-8,
  .col-lg2-offset-9,
  .col-lg2-offset-10,
  .col-lg2-offset-11,
  .col-lg2-offset-12,
  .col-lg2-grow,
  .col-lg2-shrink {
    display: initial;
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: var(--half-gutter-width, 0.5rem);
    padding-left: var(--half-gutter-width, 0.5rem);
  }
  .row {
    display: flex;
  }
  .col-lg2 {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-lg2-0 {
    display: none;
  }
  .col-lg2-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg2-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg2-3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-lg2-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg2-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg2-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-lg2-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg2-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg2-9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-lg2-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg2-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg2-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-lg2-grow {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    max-width: 100%;
  }
  .col-lg2-shrink {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    max-width: 100%;
  }
  .col-lg2-offset-0 {
    margin-left: 0;
  }
  .col-lg2-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg2-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg2-offset-3 {
    margin-left: 25%;
  }
  .col-lg2-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg2-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg2-offset-6 {
    margin-left: 50%;
  }
  .col-lg2-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg2-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg2-offset-9 {
    margin-left: 75%;
  }
  .col-lg2-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg2-offset-11 {
    margin-left: 91.66666667%;
  }
  .start-lg2 {
    justify-content: flex-start;
    text-align: start;
  }
  .center-lg2 {
    justify-content: center;
  }
  .end-lg2 {
    justify-content: flex-end;
    text-align: end;
  }
  .top-lg2 {
    align-items: flex-start;
  }
  .middle-lg2 {
    align-items: center;
  }
  .bottom-lg2 {
    align-items: flex-end;
  }
  .around-lg2 {
    justify-content: space-around;
  }
  .between-lg2 {
    justify-content: space-between;
  }
  .first-lg2 {
    order: -1;
  }
  .last-lg2 {
    order: 1;
  }
}
@media screen and (min-width: 100em) {
  .col-xlg,
  .col-xlg-1,
  .col-xlg-2,
  .col-xlg-3,
  .col-xlg-4,
  .col-xlg-5,
  .col-xlg-6,
  .col-xlg-7,
  .col-xlg-8,
  .col-xlg-9,
  .col-xlg-10,
  .col-xlg-11,
  .col-xlg-12,
  .col-xlg-offset-0,
  .col-xlg-offset-1,
  .col-xlg-offset-2,
  .col-xlg-offset-3,
  .col-xlg-offset-4,
  .col-xlg-offset-5,
  .col-xlg-offset-6,
  .col-xlg-offset-7,
  .col-xlg-offset-8,
  .col-xlg-offset-9,
  .col-xlg-offset-10,
  .col-xlg-offset-11,
  .col-xlg-offset-12,
  .col-xlg-grow,
  .col-xlg-shrink {
    display: initial;
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: var(--half-gutter-width, 0.5rem);
    padding-left: var(--half-gutter-width, 0.5rem);
  }
  .row {
    display: flex;
  }
  .col-xlg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-xlg-0 {
    display: none;
  }
  .col-xlg-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xlg-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xlg-3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-xlg-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xlg-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xlg-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-xlg-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xlg-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xlg-9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-xlg-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xlg-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xlg-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-xlg-grow {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    max-width: 100%;
  }
  .col-xlg-shrink {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    max-width: 100%;
  }
  .col-xlg-offset-0 {
    margin-left: 0;
  }
  .col-xlg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-xlg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-xlg-offset-3 {
    margin-left: 25%;
  }
  .col-xlg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-xlg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-xlg-offset-6 {
    margin-left: 50%;
  }
  .col-xlg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-xlg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-xlg-offset-9 {
    margin-left: 75%;
  }
  .col-xlg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-xlg-offset-11 {
    margin-left: 91.66666667%;
  }
  .start-xlg {
    justify-content: flex-start;
    text-align: start;
  }
  .center-xlg {
    justify-content: center;
  }
  .end-xlg {
    justify-content: flex-end;
    text-align: end;
  }
  .top-xlg {
    align-items: flex-start;
  }
  .middle-xlg {
    align-items: center;
  }
  .bottom-xlg {
    align-items: flex-end;
  }
  .around-xlg {
    justify-content: space-around;
  }
  .between-xlg {
    justify-content: space-between;
  }
  .first-xlg {
    order: -1;
  }
  .last-xlg {
    order: 1;
  }
}
/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/* ---- Unpadded row ---- */
.row-gap--0 {
  --gutter-width: 0rem;
  --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
  --half-gutter-width: calc((var(--gutter-width) * 0.5));
}

/* ---- small padded row ---- */
.row-gap--small {
  --gutter-width: 3.66666666vw;
  --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
  --half-gutter-width: calc((var(--gutter-width) * 0.5));
}

@media screen and (min-width: 48em) { /* 768 */
  .row-gap--small {
    --gutter-width: 30px;
    --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
    --half-gutter-width: calc((var(--gutter-width) * 0.5));
  }
}
/* ---- Medium padded row ---- */
.row-gap--medium {
  --gutter-width: 3.66666666vw;
  --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
  --half-gutter-width: calc((var(--gutter-width) * 0.5));
}
@media screen and (min-width: 48em) {
  .row-gap--medium {
    --gutter-width: 3.83333334vw;
    --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
    --half-gutter-width: calc((var(--gutter-width) * 0.5));
  }
}
@media screen and (min-width: 100em) {
  .row-gap--medium {
    --gutter-width: 60px;
    --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
    --half-gutter-width: calc((var(--gutter-width) * 0.5));
  }
}

/* ---- Large padded row ---- */
.row-gap--large {
  --gutter-width: 4.8888888vw;
  --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
  --half-gutter-width: calc((var(--gutter-width) * 0.5));
}
@media screen and (min-width: 48em) {
  .row-gap--large {
    --gutter-width: 5.11111vw;
    --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
    --half-gutter-width: calc((var(--gutter-width) * 0.5));
  }
}
@media screen and (min-width: 100em) {
  .row-gap--large {
    --gutter-width: 80px;
    --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
    --half-gutter-width: calc((var(--gutter-width) * 0.5));
  }
}

.row-gap--column {
  --gutter-width: 7.3333333vw;
  --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
  --half-gutter-width: calc((var(--gutter-width) * 0.5));
}
@media screen and (min-width: 48em) {
  .row-gap--column {
    --gutter-width: 7.66666667vw;
    --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
    --half-gutter-width: calc((var(--gutter-width) * 0.5));
  }
}
@media screen and (min-width: 100em) {
  .row-gap--column {
    --gutter-width: 120px;
    --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
    --half-gutter-width: calc((var(--gutter-width) * 0.5));
  }
}

.row--vertical-gutters *[class*=col-xs],
.row--vertical-gutters *[class*=col-sm],
.row--vertical-gutters *[class*=col-md],
.row--vertical-gutters *[class*=col-lg] {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/* ---- Row - nowrap  ---- */
.row-nowrap {
  flex-wrap: nowrap;
}

@media screen and (min-width: 35em) {
  .row-nowrap--xsm {
    flex-wrap: nowrap;
  }
}

@media screen and (min-width: 48em) {
  .row-nowrap--sm {
    flex-wrap: nowrap;
  }
}

@media screen and (min-width: 62em) {
  .row-nowrap--md {
    flex-wrap: nowrap;
  }
}

@media screen and (min-width: 75em) {
  .row-nowrap--lg {
    flex-wrap: nowrap;
  }
}

@media screen and (min-width: 77.5em) {
  .row-nowrap--lg2 {
    flex-wrap: nowrap;
  }
}

@media screen and (min-width: 100em) {
  .row-nowrap--xlg {
    flex-wrap: nowrap;
  }
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/* ---- Pre footer ---- */
.section--pre-footer .icon-circle {
  text-align: center;
}
@media screen and (max-width: 34.9375em) {
  .section--pre-footer .icon-circle {
    line-height: 1.6;
  }
}

.section--pre-footer .icon-circle-list,
.section--pre-footer .icon-circle {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 400;
  text-align: center;
  margin-bottom: 15px;
}
@media screen and (min-width: 48em) {
  .section--pre-footer .icon-circle-list,
  .section--pre-footer .icon-circle {
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 75em) {
  .section--pre-footer .icon-circle-list,
  .section--pre-footer .icon-circle {
    margin-bottom: 35px;
  }
}

.section--pre-footer .linklist-list {
  font-size: var(--large-font-size);
}

.section--pre-footer .icon-email-address:before {
  display: inline-grid;
  place-items: center;
}

.footer-box__text {
  text-align: center;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 400;
  line-height: 1.3;
  margin-bottom: 12px;
  font-size: 1.8rem;
}
@media screen and (min-width: 48em) {
  .footer-box__text {
    font-size: 2rem;
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 62em) {
  .footer-box__text {
    font-size: 2.4rem;
  }
}
@media screen and (min-width: 75em) {
  .footer-box__text {
    font-size: 3rem;
    margin: 0 0 25px;
  }
}

/* BP 3 */
@media screen and (max-width: 760px) {
  .pre-footer__news .field-element {
    margin-bottom: 0;
  }
}
/* ---- Footer ---- */
.section--footer {
  padding-top: 22px;
  padding-bottom: 24px;
  font-size: 1.6rem;
}

.section--footer:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(../images/texture-dots.svg);
  opacity: 0.1;
}

.section-footer__inner {
  position: relative;
  z-index: 1;
}

.footer-text {
  margin: 0;
  float: left;
}

.footer-list {
  float: right;
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-list__item {
  display: inline;
}

.section--footer,
.section--footer a {
  color: #687388;
}

.section--footer a {
  text-decoration: none;
}

.section--footer a:hover,
.section--footer a:focus {
  text-decoration: underline;
  color: #fff;
}

.section--footer .footer-list__item:after {
  content: " | ";
}

.section--footer .footer-list__item:last-child:after {
  content: none;
}

/* BP 1 */
@media screen and (max-width: 1240px) {
  .section--footer {
    padding-top: 18px;
    padding-bottom: 19px;
    font-size: 1.5rem;
  }
}
/* BP 3 */
@media screen and (max-width: 760px) {
  .footer-text {
    float: none;
  }
  .footer-list {
    float: none;
  }
  .section--footer {
    font-size: 1.3rem;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}
/* ---- Grid ---- */
.grid:before,
.grid:after {
  content: " ";
  display: table;
}

.grid:after {
  clear: both;
}

.grid-col {
  float: left;
  margin-right: 3.8596491228%;
}

.grid-col:last-child,
.grid-col.grid-col-last-in-row {
  margin-right: 0;
}

/* Reverse */
.grid.grid-reverse .grid-col {
  float: right;
  margin-right: 0;
  margin-left: 3.8596491228%;
}

.grid.grid-reverse .grid-col:last-child,
.grid.grid-reverse .grid-col.grid-col-last-in-row {
  margin-left: 0;
}

/* 1 col */
.grid-col-1 {
  width: 4.7953216374%;
}

/* 2 col */
.grid-col-2 {
  width: 13.4502923977%;
}

/* 3 col */
.grid-col-3 {
  width: 22.1052631579%;
}

/* 4 col */
.grid-col-4 {
  width: 30.7602339181%;
}

/* 5 col */
.grid-col-5 {
  width: 39.4152046784%;
}

/* 6 col */
.grid-col-6 {
  width: 48.0701754386%;
}

/* 7 col */
.grid-col-7 {
  width: 56.7251461988%;
}

/* 8 col */
.grid-col-8 {
  width: 65.3801169591%;
}

/* 9 col */
.grid-col-9 {
  width: 74.0350877193%;
}

/* 10 col */
.grid-col-10 {
  width: 82.6900584795%;
}

/* 11 col */
.grid-col-11 {
  width: 91.3450292398%;
}

/* 12 col */
.grid-col-12 {
  width: 100%;
}

/* BP 1 */
@media screen and (max-width: 1240px) {
  /* 1 col */
  .grid-col-1-bp1 {
    width: 4.7953216374%;
  }
  /* 2 col */
  .grid-col-2-bp1 {
    width: 13.4502923977%;
  }
  /* 3 col */
  .grid-col-3-bp1 {
    width: 22.1052631579%;
  }
  /* 4 col */
  .grid-col-4-bp1 {
    width: 30.7602339181%;
  }
  /* 5 col */
  .grid-col-5-bp1 {
    width: 39.4152046784%;
  }
  /* 6 col */
  .grid-col-6-bp1 {
    width: 48.0701754386%;
  }
  /* 7 col */
  .grid-col-7-bp1 {
    width: 56.7251461988%;
  }
  /* 8 col */
  .grid-col-8-bp1 {
    width: 65.3801169591%;
  }
  /* 9 col */
  .grid-col-9-bp1 {
    width: 74.0350877193%;
  }
  /* 10 col */
  .grid-col-10-bp1 {
    width: 82.6900584795%;
  }
  /* 11 col */
  .grid-col-11-bp1 {
    width: 91.3450292398%;
  }
  /* 12 col */
  .grid-col-12-bp1 {
    width: 100%;
  }
}
/* BP 2 */
@media screen and (max-width: 1000px) {
  .grid-col-last-of-row-bp2 {
    margin-right: 0;
  }
  /* 1 col */
  .grid-col-1-bp2 {
    width: 4.7953216374%;
  }
  /* 2 col */
  .grid-col-2-bp2 {
    width: 13.4502923977%;
  }
  /* 3 col */
  .grid-col-3-bp2 {
    width: 22.1052631579%;
  }
  /* 4 col */
  .grid-col-4-bp2 {
    width: 30.7602339181%;
  }
  /* 5 col */
  .grid-col-5-bp2 {
    width: 39.4152046784%;
  }
  /* 6 col */
  .grid-col-6-bp2 {
    width: 48.0701754386%;
  }
  /* 7 col */
  .grid-col-7-bp2 {
    width: 56.7251461988%;
  }
  /* 8 col */
  .grid-col-8-bp2 {
    width: 65.3801169591%;
  }
  /* 9 col */
  .grid-col-9-bp2 {
    width: 74.0350877193%;
  }
  /* 10 col */
  .grid-col-10-bp2 {
    width: 82.6900584795%;
  }
  /* 11 col */
  .grid-col-11-bp2 {
    width: 91.3450292398%;
  }
  /* 12 col */
  .grid-col-12-bp2 {
    width: 100%;
  }
}
/* BP 3 */
@media screen and (max-width: 760px) {
  /* 1 col */
  .grid-col-1-bp3 {
    width: 4.7953216374%;
  }
  /* 2 col */
  .grid-col-2-bp3 {
    width: 13.4502923977%;
  }
  /* 3 col */
  .grid-col-3-bp3 {
    width: 22.1052631579%;
  }
  /* 4 col */
  .grid-col-4-bp3 {
    width: 30.7602339181%;
  }
  /* 5 col */
  .grid-col-5-bp3 {
    width: 39.4152046784%;
  }
  /* 6 col */
  .grid-col-6-bp3 {
    width: 48.0701754386%;
  }
  /* 7 col */
  .grid-col-7-bp3 {
    width: 56.7251461988%;
  }
  /* 8 col */
  .grid-col-8-bp3 {
    width: 65.3801169591%;
  }
  /* 9 col */
  .grid-col-9-bp3 {
    width: 74.0350877193%;
  }
  /* 10 col */
  .grid-col-10-bp3 {
    width: 82.6900584795%;
  }
  /* 11 col */
  .grid-col-11-bp3 {
    width: 91.3450292398%;
  }
  /* 12 col */
  .grid-col-12-bp3 {
    width: 100%;
  }
}
/* BP 4 */
@media screen and (max-width: 620px) {
  /* 1 col */
  .grid-col-1-bp4 {
    width: 4.7953216374%;
  }
  /* 2 col */
  .grid-col-2-bp4 {
    width: 13.4502923977%;
  }
  /* 3 col */
  .grid-col-3-bp4 {
    width: 22.1052631579%;
  }
  /* 4 col */
  .grid-col-4-bp4 {
    width: 30.7602339181%;
  }
  /* 5 col */
  .grid-col-5-bp4 {
    width: 39.4152046784%;
  }
  /* 6 col */
  .grid-col-6-bp4 {
    width: 48.0701754386%;
  }
  /* 7 col */
  .grid-col-7-bp4 {
    width: 56.7251461988%;
  }
  /* 8 col */
  .grid-col-8-bp4 {
    width: 65.3801169591%;
  }
  /* 9 col */
  .grid-col-9-bp4 {
    width: 74.0350877193%;
  }
  /* 10 col */
  .grid-col-10-bp4 {
    width: 82.6900584795%;
  }
  /* 11 col */
  .grid-col-11-bp4 {
    width: 91.3450292398%;
  }
  /* 12 col */
  .grid-col-12-bp4 {
    width: 100%;
  }
}
/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
:root {
  --header-height: 40px;
  --header-index: 100;
}

@media screen and (min-width: 48em) {
  :root {
    --header-height: 44px;
  }
}
@media screen and (min-width: 48em) {
  :root {
    --header-height: 50px;
  }
}
@media screen and (min-width: 62em) {
  :root {
    --header-height: 69px;
  }
}
@media screen and (min-width: 75em) {
  :root {
    --header-height: 91px;
  }
}
/* ---- Header ---- */
.section--header {
  background-color: #ffffff;
  border-bottom: 1px solid #f6f6f6;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding-top: 0;
  padding-bottom: 0;
  z-index: 200;
  height: var(--header-height);
  display: flex;
  justify-content: center;
  align-items: center;
}
.section--header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (min-width: 48em) {
  .section--header .container {
    justify-content: flex-end;
  }
}

.section--header__logo {
  flex: 0 1 auto;
}
@media screen and (min-width: 48em) {
  .section--header__logo {
    margin-right: auto;
  }
}

.section--header__logo__link {
  text-decoration: none;
  display: flex;
  margin-top: 0;
}
@media screen and (min-width: 48em) {
  .section--header__logo__link {
    margin-top: 2px;
  }
}

.header__logo {
  height: 40px;
}
@media screen and (min-width: 48em) {
  .header__logo {
    height: 44px;
  }
}
@media screen and (min-width: 62em) {
  .header__logo {
    height: 60px;
  }
}

.section--header__logo__link:hover,
.section--header__logo__link:focus {
  color: #0073BC;
}

.header__contact-links {
  display: none;
}
@media screen and (min-width: 62em) {
  .header__contact-links {
    display: block;
    font-family: "Source Sans Pro", sans-serif;
    border-left: 1px solid #445374;
    margin-left: 7px;
    padding-left: 7px;
    line-height: 1.7;
    font-size: 1.8rem;
  }
}
@media screen and (min-width: 75em) {
  .header__contact-links {
    line-height: 1.5;
    font-size: 2.6rem;
    padding-left: 10px;
    margin-left: 10px;
  }
}
@media screen and (min-width: 87.5em) {
  .header__contact-links {
    margin-left: 12px;
    padding-left: 12px;
    font-size: 3.1rem;
    line-height: 1.2;
  }
}

.header__contact-links .icon-circle-list {
  display: flex;
  align-items: center;
}
.header__contact-links .icon-circle-list a {
  display: block;
}
@media screen and (max-width: 74.9375em) {
  .header__contact-links .icon-circle-list a:before {
    height: 30px;
    width: 30px;
    font-size: 22px;
    line-height: 1.3;
  }
}
.header__contact-links .icon-circle-list__item--text a {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 47.9375em) {
  .section--header {
    display: none;
  }
}
/* Mainbar */
.mainbar > .widget-RichText:first-child > :first-child {
  margin-top: 0;
}

.mainbar .h1 {
  margin: 16px 0 8px;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/* ---- Landing page styles eg. Crowdstrike pg ---- */
.landing {
  overflow-x: hidden;
}

.landing .section--inner-banner__inner {
  padding-top: 80px;
}

.landing .container {
  position: relative;
  z-index: 1;
}

.landing .widget.widget-RichText {
  padding: 30px 0;
  position: relative;
}

.landing .widget.widget-RichText:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: -60%;
  right: -60%;
  z-index: -2;
}

.landing .widget.widget-RichText:nth-child(1) {
  color: #FFF;
}

.landing .widget.widget-RichText:nth-child(1):before {
  background-color: #0073BC;
}

.landing .widget.widget-RichText:nth-child(1):after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: -60%;
  right: -60%;
  background-image: url(../images/texture-dots.svg);
  opacity: 0.1;
  z-index: -1;
}

.landing .widget.widget-RichText:nth-child(2n+3):before {
  background-color: rgb(240, 240, 240);
}

@media screen and (min-width: 1241px) {
  .landing .widget.widget-RichText {
    padding: 80px 0;
    font-size: 2rem;
  }
  .landing .widget.widget-RichText h4 {
    font-size: 2.2rem;
  }
  .landing .widget.widget-RichText:before {
    left: calc(-50% - 570px);
    right: calc(-50% - 570px);
  }
  .landing .widget.widget-RichText:nth-child(1):after {
    left: calc(-50% - 570px);
    right: calc(-50% - 570px);
  }
}
/* ---- Line title ---- */
.landing .widget-RichText {
  text-align: center;
}

.landing .widget-RichText h2:first-child {
  position: relative;
  color: #2c364a;
  text-transform: uppercase;
  font-weight: 100;
  line-height: 1.2;
  display: inline-block;
  margin: 0 auto 32px;
  font-family: "DIN Next W01", sans-serif;
}

.landing .widget-RichText h2:first-child:after {
  content: " ";
  display: block;
  height: 18px;
  margin-top: 18px;
}

.landing .widget-RichText:first-child h2:first-child {
  color: #fff;
  font-weight: bold;
}

/* Dark blue */
.landing .widget-RichText h2:first-child:after {
  background-image: url(../images/texture-lines-blue.png);
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/* Sidebar */
.sidebar h2 {
  text-transform: uppercase;
  color: #2c364a;
  font-size: 2rem;
  font-family: "DIN Next W01", -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-weight: 500;
}

/* ---- Catch all ---- */
.sidebar .widget {
  margin-bottom: 25px;
}

.sidebar .widget:last-child {
  margin-bottom: 0;
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
#content {
  background-color: #ffffff;
}
@media screen and (min-width: 48em) {
  #content {
    overflow-x: hidden;
  }
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

@media screen and (min-width: 35em) {
  .text-align-right--xsm {
    text-align: right;
  }
}

@media screen and (min-width: 35em) {
  .text-align-left--xsm {
    text-align: left;
  }
}

@media screen and (min-width: 35em) {
  .text-align-center--xsm {
    text-align: center;
  }
}

@media screen and (min-width: 48em) {
  .text-align-right--sm {
    text-align: right;
  }
}

@media screen and (min-width: 48em) {
  .text-align-left--sm {
    text-align: left;
  }
}

@media screen and (min-width: 48em) {
  .text-align-center--sm {
    text-align: center;
  }
}

@media screen and (min-width: 62em) {
  .text-align-right--md {
    text-align: right;
  }
}

@media screen and (min-width: 62em) {
  .text-align-left--md {
    text-align: left;
  }
}

@media screen and (min-width: 62em) {
  .text-align-center--md {
    text-align: center;
  }
}

@media screen and (min-width: 75em) {
  .text-align-right--lg {
    text-align: right;
  }
}

@media screen and (min-width: 75em) {
  .text-align-left--lg {
    text-align: left;
  }
}

@media screen and (min-width: 75em) {
  .text-align-center--lg {
    text-align: center;
  }
}

@media screen and (min-width: 77.5em) {
  .text-align-right--lg2 {
    text-align: right;
  }
}

@media screen and (min-width: 77.5em) {
  .text-align-left--lg2 {
    text-align: left;
  }
}

@media screen and (min-width: 77.5em) {
  .text-align-center--lg2 {
    text-align: center;
  }
}

@media screen and (min-width: 100em) {
  .text-align-right--xlg {
    text-align: right;
  }
}

@media screen and (min-width: 100em) {
  .text-align-left--xlg {
    text-align: left;
  }
}

@media screen and (min-width: 100em) {
  .text-align-center--xlg {
    text-align: center;
  }
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/* ---- Floats and positioning ---- */
.left {
  margin: 0 30px 30px 0;
  float: left;
}

.right {
  margin: 0 0 30px 30px;
  float: right;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.block {
  margin: 0 0 30px;
  display: block;
}

@media screen and (max-width: 620px) {
  .left,
  .center,
  .right {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    display: block;
    float: none;
  }
}
.clear {
  height: 0;
  clear: both;
  display: block;
}

.-clearfix:before, .-clearfix:after {
  content: " ";
  display: table;
}
.-clearfix:after {
  clear: both;
}

.-ir {
  background-color: transparent;
  border: 0;
  overflow: hidden;
}

.-ir:before {
  content: "";
  display: block;
  width: 0;
  height: 150%;
}

/* Angle Brackets */
.-r-arrow-after:after {
  content: " >";
}

.-r-arrow-before:before {
  content: "> ";
}

.-l-arrow-after:after {
  content: " <";
}

.-l-arrow-before:before {
  content: "< ";
}

/* Ellipsis */
.ellipsis-2:after {
  content: "..";
}

.ellipsis-3:after {
  content: "...";
}

/*
    Global SASS 'Members' - aka, Mixins, Functions & Variables
*/
/* ---- Hidden ---- */
.-hidden {
  display: none !important;
  visibility: hidden;
}

/* ---- Invisible ---- */
.-invisible {
  visibility: hidden;
}

/* ---- Visibility Hidden ---- */
.-vis-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.-vis-hidden.focusable:active,
.-vis-hidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

/* ---- Hide / Show  ---- */
.hide {
  display: none;
}

.show {
  display: initial;
}

/* ---- Legacy show/hide ---- */
/* BP 1 */
@media screen and (max-width: 1240px) {
  .show-bp1 {
    display: initial;
  }
  .hide-bp1 {
    display: none;
  }
}
/* BP 2 */
@media screen and (max-width: 1000px) {
  .show-bp2 {
    display: initial;
  }
  .hide-bp2 {
    display: none;
  }
}
/* BP 3 */
@media screen and (max-width: 760px) {
  .show-bp3 {
    display: initial;
  }
  .hide-bp3 {
    display: none;
  }
}
/* BP 4 */
@media screen and (max-width: 620px) {
  .show-bp4 {
    display: initial;
  }
  .hide-bp4 {
    display: none;
  }
}
/* ---- Updated show/hide ---- */
@media screen and (min-width: 35em) {
  .hide--xsm {
    display: none;
  }
}

.show--xsm {
  display: none;
}
@media screen and (min-width: 35em) {
  .show--xsm {
    display: initial;
  }
}

@media screen and (min-width: 48em) {
  .hide--sm {
    display: none;
  }
}

.show--sm {
  display: none;
}
@media screen and (min-width: 48em) {
  .show--sm {
    display: initial;
  }
}

@media screen and (min-width: 62em) {
  .hide--md {
    display: none;
  }
}

.show--md {
  display: none;
}
@media screen and (min-width: 62em) {
  .show--md {
    display: initial;
  }
}

@media screen and (min-width: 75em) {
  .hide--lg {
    display: none;
  }
}

.show--lg {
  display: none;
}
@media screen and (min-width: 75em) {
  .show--lg {
    display: initial;
  }
}

@media screen and (min-width: 77.5em) {
  .hide--lg2 {
    display: none;
  }
}

.show--lg2 {
  display: none;
}
@media screen and (min-width: 77.5em) {
  .show--lg2 {
    display: initial;
  }
}

@media screen and (min-width: 100em) {
  .hide--xlg {
    display: none;
  }
}

.show--xlg {
  display: none;
}
@media screen and (min-width: 100em) {
  .show--xlg {
    display: initial;
  }
}

.animate-fade {
  animation: fadeInDown 400ms ease-in-out;
}

.demo-animate .demo-animate__item {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.demo-animate:hover .demo-animate__item {
  clip: auto;
  height: auto;
  overflow: visible;
  position: relative;
  width: auto;
  padding: 5px;
  -webkit-animation: fadeInDown 400ms ease-in-out;
  animation: fadeInDown 400ms ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

/* ------------------------------------------------------
**** Print styles
------------------------------------------------------ */
@media print {
  * {
    background: transparent !important;
    color: #000 !important; /* Black prints faster: h5bp.com/s */
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  /*
   * Don't show links for images, or javascript/internal links
   */
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group; /* h5bp.com/t */
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}