@use '../00-settings/breakpoints' as *;


/* ------------------------------------------------------
**** Fonts
------------------------------------------------------ */

/* ---- Webfonts ---- */
/*

Source Sans Pro
Google fonts
font-family: 'Source Sans Pro', sans-serif;

DIN Next
Fonts.com
font-family: 'DIN Next W01', sans-serif;

Neue Helvetica
Fonts.com
font-family: 'Neue Helvetica W01', sans-serif;

Montserrat
Field labels

*/



:root {
    --body-font-size: 1.5rem;
    --body-line-height: 1.5;

    --large-font-size: 1.6rem;

    // headings
    --h1-font-size: 2rem;
    --h1-line-height: 1.2;

    --h2-font-size: 1.8rem;
    --h2-line-height: 1.2;

    --h3-font-size: 1.8rem;
    --h3-line-height: 1.2;

    --h4-font-size: 1.8rem;
    --h4-line-height: 1.2;

    --h5-font-size: 1.7rem;
    --h5-line-height: 1.2;

    --h6-font-size: 1.7rem;
    --h6-line-height: 1.2;

    /* [02] */
    // big
    --giga-font-size: 1.8rem; // based off hero banner title size
    --mega-font-size: 4.4rem;
    --kilo-font-size: 4rem;

    --milli-font-size: 1.3rem;
    --centi-font-size: 1.4rem;
}


@include screen($bp360) {
    :root {
        --h1-font-size: 2.2rem;

        --h2-font-size: 2rem;

        --h3-font-size: 1.9rem;

        --giga-font-size: 2.8rem;
    }
}

@include screen($bp624) {
    :root {
        --giga-font-size: 4.3rem;
    }
}

@include screen($bp768) {
    :root {
        --body-font-size: 1.6rem;

        --large-font-size: 1.8rem;

        --h1-font-size: 2.6rem;

        --h2-font-size: 2.2rem;
    }
}

@include screen($bp992) {
    :root {
        --body-font-size: 1.8rem;

        --large-font-size: 2rem;

        --h1-font-size: 3rem;

        --h2-font-size: 2.8rem;

        --giga-font-size: 6.7rem;
    }
}

@include screen($bp1200) {
    :root {
        --h1-font-size: 4rem;

        --h2-font-size: 3.2rem;

        --h3-font-size: 1.9rem;

        --h4-font-size: 1.8rem;

        --h5-font-size: 1.7rem;

        --h6-font-size: 1.7rem;

        --giga-font-size: 7.3rem;
    }
}

/*
    Basic system font stacks
*/

$system-font: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;

/*
    Brand fonts
*/

$primary-font-face: 'Neue Helvetica W01' !default;

$accent-font-face: 'DIN Next W01' !default;

$alternative-font-face-01: 'Source Sans Pro' !default;

$alternative-font-face-02: 'Montserrat' !default;

/*
    Font stacks
*/

$primary-font: $primary-font-face, $system-font;

$accent-font: $accent-font-face, $system-font;

$alternative-font-01: $alternative-font-face-01, $system-font;

$alternative-font-02: $alternative-font-face-02, $system-font;

/*
    Font Family key for Fractal
*/

$font-families: (
    primary: $primary-font,
    accent: $accent-font,
    alternative-01: $alternative-font-01,
    alternative-02: $alternative-font-02
) !default;


/* Weights */

$fw-light: 100;

$fw-normal: 400;

$fw-quasibold: 500;

$fw-semibold: 600;

$fw-bold: 700;

$fw-heavy: 800;


$font-weight-list: (
    fw-light: $fw-light,
    fw-normal: $fw-normal,
    fw-quasibold: $fw-quasibold,
    fw-semibold: $fw-semibold,
    fw-bold: $fw-bold,
    fw-heavy: $fw-heavy
) !default;


/* Size */

$fs-body: var(--body-font-size);

/* -----
* NOTES
* [02]
* A series of classes for setting massive type; for use in heroes, mastheads,
* promos, etc.
* As per: csswizardry.com/2012/02/pragmatic-practical-font-sizing-in-css
------ */


/*
    Font Mixins
*/

@mixin font($font-family: $accent-font, $font-weight: $fw-normal, $font-style: false) {
    font-family: $font-family;
    font-weight: $font-weight;

    @if $font-style {
        font-style: $font-style;
    }
}

@mixin font-set($set: 'primary') {

    @if $set == 'primary' {
        font-family: $primary-font;
        font-weight: $fw-normal;
    }

    @if $set == 'primary-bold' {
        font-family: $primary-font;
        font-weight: $fw-heavy;
    }

    @if $set == 'accent' {
        font-family: $accent-font;
        font-weight: $fw-light;
    }

    @if $set == 'accent-bold' {
        font-family: $accent-font;
        font-weight: $fw-quasibold;
    }

    @if $set == 'alternative' {
        font-family: $alternative-font-01;
        font-weight: $fw-normal;
    }

    @if $set == 'alternative-bold' {
        font-family: $alternative-font-01;
        font-weight: $fw-bold;
    }

}

