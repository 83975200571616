
@use "../../../00-settings" as *;
@use "../../../05-utilities/helpers/utility-mixins" as *;

.slider {
    position: relative;
    width: 100%;

    &__track {
        list-style-type: none;
        padding: 0;
        margin: 0;
        width: 100%;
        display: flex;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            display: none;
            width: 0;
        }
    }

    &__item {
        flex: 0 0 100%;
        flex-shrink: 0;
        scroll-snap-align: start;
    }

    &__dots {
        position: absolute;
        top: auto;
        bottom: 0;
        left: var(--default-container-gutter);
        right: auto;
        height: 24px;
        z-index: 1;
        display: flex;

        a {
            padding: 12px;
            position: relative;

            span {
                @include vis-hidden;
            }

            &:before {
                content: "";
                background-color: rgba($color-white, 0.6);
                box-shadow: 0 0 0 2px transparent;
                height: 8px;
                width: 8px;
                border-radius: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition:
                    background-color 200ms ease,
                    box-shadow 200ms ease;
            }

            &:hover:before {
                background-color: $color-00;
                box-shadow: 0 0 0 2px $color-white;
            }

            &:focus:before {
                background-color: $color-00;
                box-shadow: 0 0 0 2px $color-white;
            }

            &.active:before {
                background-color: $color-00;
                transition-delay: 50ms;
                box-shadow: 0 0 0 2px $color-white;
            }
        }
    }

    &__arrows {
        -webkit-appearance: unset;
        position: absolute;
        top: calc(50% - 6vw);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        white-space: normal;
        vertical-align: middle;
        text-align: center;
        cursor: pointer;
        color: $color-white;
        background: transparent;
        text-decoration: none;
        border-radius: 0;
        border: none;
        outline: none;
        opacity: 1;
        padding: 0;
        transition:
            background-color 250ms ease-in-out,
            color 250ms ease-in-out,
            border-color 250ms ease-in-out;
        width: 6vw;
        height: 12vw;

        @include screen($bp768) {
            top: calc(50% - 20px);
            width: 40px;
            height: 40px;
        }

        @include screen($bp1200) {
            top: calc(50% - 28px);
            width: 56px;
            height: 56px;
        }

        &.previous {
            left: 0;
        }

        &.next {
            right: 0;
        }

        * {
            pointer-events: none;
        }

        svg {
            @supports(filter: drop-shadow(0 1px 4px rgba($color-black, 25%))) {
                filter: drop-shadow(0 1px 4px rgba($color-black, 25%));
            }

            @include svg-contents {
                stroke: $color-grey-02;
            }
        }
    }
}

[aria-label="gallery"]:focus {
  outline: 4px solid DodgerBlue;
  outline-offset: -4px; /* compensates for 2px border */
}

.slider__item img {
    object-fit: cover;
}
