/* ---- Testimonials ---- */
.section--testimonial__button {
	margin-top: 20px;
}

/* BP 3 */
@media screen and (max-width: 760px) {
	.section--testimonial__button {
		margin-top: 10px;
	}
}
