
@use "../../00-settings" as *;

/* ---- Breadcrumbs ---- */
.breadcrumb {
	list-style: none;
	margin: 8px 0 32px;
	padding: 0;
	font-size: var(--centi-font-size);
    @include font-set('accent');
	text-transform: uppercase;
}

.breadcrumb > li {
    display: inline;
}

.breadcrumb > li:before {
    content: "\00a0//\00a0";
}

.breadcrumb > li:first-child:before {
    content: none;
}

