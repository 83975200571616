/* ---- Field errors ---- */
.field-error__list {
	list-style: none;
	padding: 0;
	margin: 0;
}

.field-error__list__item {
	color: #fff;
	background: #C44556;
	padding: 10px 20px;
	margin: 11px 0;
	border-left: 6px solid #A23947;
}
