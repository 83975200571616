@use "../../00-settings" as *;

/* ---- Partners list ---- */
.partners-logo-list {
	list-style: none;
	margin: 10px 0;
	padding: 0;
	display: flex;
	gap: 1em;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;

	@include screen($bp480) {
		gap: 1.5em;
	}

	img {
		display: block;
		height: 26px;
		width: auto;

		@include screen($bp360) {
			height: 32px;
		}

		@include screen($bp768) {
			height: 36px;
		}

		@include screen($bp992) {
			height: 44px;
		}
	}
}


.partners-logo-list__item {
	flex: 1 1 auto;
}

.partners-logo-list__item-link,
.partners-logo-list__wrapper {
	display: grid;
	place-items: center;
}
