
@use "../../00-settings" as *;

.highlight,
.highlight--right,
.highlight--left {
	margin: 0 0 1.5rem;
	padding: 30px;
	background: $color-grey-01;
}

@media screen and (min-width: 1000px) {
	.highlight--left {
		float: left;
		width: 45%;
		margin-right: 20px;
	}

	.highlight--right {
		float: right;
		width: 45%;
		margin-left: 20px;
	}
}

.highlight *:last-child,
.highlight--right *:last-child,
.highlight--left *:last-child {
	margin-bottom: 0;
}
