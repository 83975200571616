
/* ---- Inline ---- */
.field-elements-inline:before,
.field-elements-inline:after {
	content: " ";
	display: table;
}

.field-elements-inline:after {
	clear: both;
}
.field-elements-inline .field-element {
	float: left;
	margin-right: 1%;
}
.field-elements-inline .field-element:last-child {
	margin-right: 0;
}

/* Button */
.field-elements-inline .field-element--button {
	margin-top: 29px;
}
.field-elements-inline .field-element--button.field-element--inline-no-label {
	margin-top: 0;
}

/* Add top margin to make empty space for margins */
.field-element--inline-with-label {
	margin-top: 29px;
}
