@use '../../00-settings/breakpoints' as *;

:root {
    --paragraph-break: 1.5rem;
    --large-heading-break: 1rem;
}

@include screen($bp768) {
    :root {
        --large-heading-break: 1.4rem;
    }
}

@include screen($bp992) {
    :root {
        --large-heading-break: 1.7rem;
    }
}

@include screen($bp1200) {
    :root {
        --large-heading-break: 1.9rem;
    }
}

p,
address {
    margin: 0 0 var(--paragraph-break);
}

small {
    font-size: var(--milli-font-size);
}
