
@use "../../00-settings" as *;
@use "../../01-base/typography" as *;

/* ---- Site Messages ---- */
.messages {
	list-style: none;
	margin: 16px 0 25px;
	padding: 0;
}
.messages li {
	margin: 0 0 15px;
	padding: 10px 20px;
	vertical-align: bottom;
	text-align: left;
	font-size: var(--body-font-size);
	line-height: 1.2em;
}

.messages .error {
    @include error;
}

.messages .confirm {
    @include positive;
}

.messages a {
	color: $color-white;
}
