
@use "../../00-settings" as *;
@use "../../01-base/typography/reverse-text-mixins" as *;
@use "../../02-components/section/section-mixins" as *;

/* [01]
    Bleeds commented so as to not bloat the css unnecessarily.
    Uncomment to use
*/

.bg-white {
    background-color: $color-white;
}

.bg-grey {
	background-color: $color-grey-02;
}

.bg-lightblue {
	background-color: $color-lightblue;
}

.bg-lightgrey {
    background-color: $color-grey-01;

    &.background--bleed {
        @include bleed-left($color-grey-01);
        @include bleed-right($color-grey-01);
    }
}

.bg-darkblue,
.bg-00 {
    background-color: $color-00;
    @include reverse-text;

    &.background--bleed {
        @include bleed-left($color-00);
        @include bleed-right($color-00);
    }
}

.bg-darkerblue {
    background-color: $color-00-dark;
    @include reverse-text;
}

.bg-logoblue,
.bg-01 {
    background-color: $color-01;
    @include reverse-text;

    &.background--bleed {
        @include bleed-left($color-01);
        @include bleed-right($color-01);
    }
}

.bg-02 {
    background-color: $color-03;

    &.background--bleed {
        @include bleed-left($color-03);
        @include bleed-right($color-03);
    }
}

.bg-green,
.bg-05 {
    background-color: $color-06;
    @include reverse-text;

    &.background--bleed {
        @include bleed-left(color-06);
        @include bleed-right(color-06);
    }
}

.bg-06 {
    background-color: $color-08;

    &.background--bleed {
        @include bleed-left(color-08);
        @include bleed-right(color-08);
    }
}

.bg-grey-dient {
    background: linear-gradient(to bottom, $color-grey-01 0%, $color-white 100%);
}
