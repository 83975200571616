@use "../form/" as *;

/* -------------------------------
(Submit) Button Styles
---------------------------------- */
:where(button[type="submit"]) {
    @include button;
}

/* -------------------------------
Inputs and Textarea
---------------------------------- */
:where(
    [type="text"],
    [type="number"],
    [type="email"],
    [type="tel"],
    [type="search"],
    [type="password"],
    [type="url"],
    textarea) {
    @include form-textbox;
    @include form-textbox-styles;
}

/* -------------------------------
Select Styles
---------------------------------- */
:where(select) {
    @include field-select;
}

/* -------------------------------
Fieldsets Styles
---------------------------------- */

/* Wrapper */
fieldset {
    border: none;
    padding: 0;
    margin: 0;
 }

 /* Field Element */
:where(.field--checkbox_group) label,
:where(.field--radio_group) label {
    @include fieldsetLabel;
}

:where(.field--checkbox) .field-label {
    @include fieldsetLabel;
}

:where([type="checkbox"]) {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;

    &:before {
        content: "";
        @include checkbox;
    }

    &:checked:after {
        content: "";
        @include checkboxChecked;
    }
}

:where([type="radio"]) {
    -webkit-appearance: none;
    -moz-appearance: none;

    &:before {
        content: "";
        @include radio;
    }

    &:checked:before {
        background-color: $color-utility-neutral;
        border-color: $color-utility-neutral;
    }

    &:checked:after {
        content: "";
        @include radioChecked;
    }
}

/* ---- Base styles ---- */
.field-element {
	margin-bottom: var(--field-vertical-whitespace);
}

/* Clearfix */

.field-element:before,
.field-element:after {
	content: " ";
	display: table;
}

.field-element:after {
	clear: both;
}

/* ---- Sizes ---- */
.field-element--small {
    :where(
    [type="text"],
    [type="number"],
    [type="email"],
    [type="tel"],
    [type="search"],
    [type="password"],
    [type="url"],
    textarea) {
        @include form-textbox('small');
    }

    :where(.button) {
        @include button-size('small');
    }
}

.field-element--large {
    :where(.button) {
        @include button-size('large');
    }
}

/* ---- Theme ---- */
.field-element--white {
    --field-background: #{$color-white};
}

.field-element--lightgreen {
    --field-background: #{$color-10};
}

/* ---- Caps ---- */
.field-element--caps .textbox,
.field-element--caps select {
	text-transform: uppercase;
}

/* ---- Disabled ---- */
.field-element--disabled {
	opacity: 0.6;
	cursor: not-allowed;
}
.field-element--disabled .textbox[disabled],
.field-element--disabled select[disabled],
.field-element--disabled button[disabled] {
	cursor: not-allowed;
}

/* ---- Lightweight elements ---- */
.field-element--lightweight {
	margin: 0;
}
