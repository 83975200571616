textarea {
	resize: vertical;
}

// The following is only required if a height is on the form-textbox mixin
//
// :where(textarea) {
//     min-height: var(--field-height);
//     height: unset;
// }
