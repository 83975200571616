@use "../../00-settings" as *;

/* ---- Quotations ---- */
blockquote {
    margin-block: 1rem;
	padding: 5px 20px 15px;
	background: #f6f6f6;
	font-style: normal;

    @include screen($bp992) {
        margin-block: 1.5rem;
    }
}

/* Plain blockquote */
.blockquote-plain {
	background: none;
	padding: 0;
}

.blockquote-plain__body {
	color: #7e8fb5;
	font-family: 'Source Sans Pro', sans-serif;
    font-size: 2rem;
	line-height: 1.3;

    @include screen($bp768) {
        font-size: 2.2rem;
    }

    @include screen($bp992) {
        font-size: 2.9rem;
    }
}

.blockquote-plain__body:before,
.blockquote-plain__body:after {
	display: inline;
}

.blockquote-plain__body:before {
	content: open-quote;
}

.blockquote-plain__body:after {
	content: close-quote;
}

.blockquote-plain__body.blockquote-plain__hide-quotes:before,
.blockquote-plain__body.blockquote-plain__hide-quotes:after {
	display: none;
}

.blockquote-plain cite {
	font-style: normal;
	color: $color-00;
	font-family: 'DIN Next W01', sans-serif;
	text-transform: uppercase;
	font-weight: 500;
	text-align: right;
	line-height: 1.4;
}

.blockquote-plain__author {
	font-size: 1.6rem;

    @include screen($bp768) {
        font-size: 1.7rem;
    }

    @include screen($bp992) {
        font-size: 1.9rem;
    }
}

.blockquote-plain__author:before {
	content: "- ";
}

.blockquote-plain__author-from {
    font-size: 1.3rem;

    @include screen($bp768) {
        font-size: 1.4rem;
    }

    @include screen($bp992) {
        font-size: 1.5rem;
    }
}

.blockquote-plain__author,
.blockquote-plain__author-from {
	margin: 0;
}

.blockquote-plain__body .expando {
	margin: 0;
	padding: 0;
	border: none;
}



