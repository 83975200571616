
@use "../../00-settings" as *;

:root {
	--tooltip-background: #{$color-grey-09};
	--tooltip-color: #{$color-white};
	--tooltip-lines: #{lighten($color-grey-09, 10%)};
	--tooltip-header-background: #{lighten($color-grey-09, 5%)};
	--tooltip-section-padding: 4px 6px;
}

/* ---- Tooltips ---- */
.tooltip {
	position: relative;
	display: inline-block;
}

.tooltip--reverse {
	--tooltip-background: #{$color-grey-01};
	--tooltip-color: #{$color-01};
	--tooltip-lines: #{darken($color-grey-01, 10%)};
	--tooltip-header-background: #{darken($color-grey-01, 5%)};
}

.tooltip__title {
	display: inline-block;
	position: relative;
	z-index: 1;
    opacity: 1;
	font: inherit;
    transition:
		opacity 100ms ease-in-out;

	a {
		color: inherit;

		&:hover,
		&:focus {
			color: $color-grey-10;
			text-decoration: none;
		}
	}
}

.tooltip__content {
	display: none;
	position: absolute;
	top: 100%;
	left: 0;
	background-color: var(--tooltip-background);
	border: 1px solid var(--tooltip-lines);
	max-width: 270px;
	min-width: 120px;
	padding: 0;
	font-size: var(--centi-font-size);
	border-radius: 4px;
	margin-top: 7px;
	text-align: left;
	pointer-events: none;
	color: var(--tooltip-color);
	z-index: calc(var(--header-index) - 1);
	font-family: $system-font;
	font-weight: normal;
	white-space: normal;
	text-transform: none;
	line-height: normal;

	@include screen($bp768) {
		min-width: 160px;
	}

	@include screen($bp992) {
		min-width: 200px;
	}

	p {
		font-size: var(--milli-font-size);
		margin-bottom: 0.25em;
	}

	> *:last-child {
		margin-bottom: 0;
	}

	small {
		font-size: 1.1rem;
	}

	&:before,
	&:after {
		content: " ";
		position: absolute;
		bottom: 100%;
		left: 14px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 5px 5px 5px;
		border-color: transparent transparent var(--tooltip-background) transparent;
		z-index: 1;
	}

	&:after {
		left: 13px;
		border-width: 0 6px 6px 6px;
		border-color: transparent transparent var(--tooltip-lines) transparent;
		z-index: 0;
	}
}

/* Center align */
.tooltip--center .tooltip__content {
	left: 50%;
    margin-right: -50%;
	transform: translateX(-50%);
}
.tooltip--center .tooltip__content:before,
.tooltip--center .tooltip__content:after {
	left: 0;
	right: 0;
	margin: 0 auto;
}

/* Right align */
.tooltip--right .tooltip__content {
	right: 0;
	left: auto;
}
.tooltip--right .tooltip__content:before {
	right: 14px;
	left: auto;
}

/* Up */
.tooltip--up .tooltip__content {
	top: auto;
	bottom: 100%;
	margin-top: 0;
	margin-bottom: 7px;
}
.tooltip--up .tooltip__content:before {
	border-width: 5px 5px 0 5px;
	border-color: var(--tooltip-background) transparent transparent transparent;
	top: 100%;
}

/* Tooltip animations for hover state */
/* Fade in */
.tooltip:hover .tooltip__content,
.tooltip__title:hover + .tooltip__content {
	display: block;
	animation: fadeIn 200ms ease;
	animation-fill-mode: forwards;
}
/* Transition up */
.tooltip--transition-up .tooltip__content {
	-webkit-animation-name: fadeInUpMargin;
			animation-name: fadeInUpMargin;
}

/* Info icon */
.tooltip__icon {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border-radius: 100%;
	background: $color-grey-05;
	color: $color-white;
	width: 1em;
	height: 1em;
	cursor: pointer;
	text-align: center;
	-webkit-appearance: none;
	border: none;
	outline: none;
	padding: 0;
	margin: 0 0.2em;
}
.tooltip--icon .tooltip__icon {
	display: inline-block;
}
.tooltip__icon svg {
	display: block;
	width: 0.66em;
	height: 0.66em;

	path {
		fill: currentColor;
	}
}
.tooltip--icon .tooltip__title {
	border-bottom: none;
	margin-right: 7px;
}
.tooltip--icon .tooltip__content {
	left: -8px;
	right: auto;
}
.tooltip.tooltip--icon.tooltip--center .tooltip__content {
	right: auto;
	left: 50%;
}
.tooltip--icon.tooltip--right .tooltip__content {
	right: -8px;
	left: auto;
}

/* Disabled */
.tooltip--disabled .tooltip__content {
	display: none;
}

.tooltip__header {
	background-color: var(--tooltip-header-background);
	padding: var(--tooltip-section-padding);
	border-bottom: 1px solid var(--tooltip-lines);

	.tooltip__header__title {
    	font-weight: $fw-heavy;
		font-size: var(--centi-font-size);
		margin-bottom: 0;
	}
}

.tooltip__body {
	padding: var(--tooltip-section-padding);
}

.tooltip__footer {
	padding: var(--tooltip-section-padding);
}

.tooltip__body + .tooltip__footer {
	border-top: 1px solid var(--tooltip-lines);
}

.tooltip--center .tooltip__body {
	text-align: center;
}

.tooltip--center .tooltip__header + .tooltip__body {
	text-align: left;
}
