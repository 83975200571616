
@use "../../../00-settings" as *;

/* ---- Responsive table ---- */
table.responsive {
	overflow: auto;
	overflow-y: hidden;
	overflow-x: auto;
	display: block;
}

// @media screen and (max-width: $bp560) {
//     .table--responsive {
//         font-size: var(--centi-font-size);
//     }
//     .table--responsive,
//     .table--responsive tbody,
//     .table--responsive tr {
//         display: block;
//         border: none;
//     }
//     .table--responsive thead,
//     .table--responsive th {
//         display: none;
//     }
//     .table--responsive tr {
//         display: table;
//         width: 100%;
//     }
//     .table--responsive tr.table--responsive__first-row {
//         display: none;
//     }
//     .table--responsive td {
//         display: table-row;
//         padding: 0;
//     }
//     .table--responsive td[data-title]:before {
//         content: attr(data-title);
//         font-weight: bold;
//         background-color: #F0F0F0;
//         border-right: 1px solid #d7d7d7;
//     }
//     .table--responsive td[data-title] {
//         border-left: 1px solid #d7d7d7;
//         border-right: 1px solid #d7d7d7;
//         border-top: 1px solid #d7d7d7;
//     }
//     .table--responsive tbody tr {
//         margin-bottom: 20px;
//         border-bottom: 1px solid #d7d7d7;
//     }
//     .table--responsive td[data-title]:before,
//     .table--responsive__content {
//         padding: 5px 15px;
//         display: table-cell;
//     }

// }
