
@use "../../../00-settings" as *;

.table--header-column {
    th {
        text-align: left;
    }
}

.table--row-striped {
    border: 1px solid transparent;

    th,
    td {
        border: 1px solid transparent;
    }

    th,
    thead td {
        background: transparent;
    }

    tr:nth-child(odd) td,
    tr:nth-child(odd) th {
        background-color: $color-white;
    }

    tr:nth-child(even):last-child {
        td, th {
            border-bottom-color: $color-white;
            border-bottom-width: 2px;
        }
    }

    tr:first-child {
        td:first-child,
        th:first-child {
            border-top-left-radius: 2px;
        }

        td:last-child,
        th:last-child {
            border-top-right-radius: 2px;
        }
    }

    tr:last-child {
        td:first-child,
        th:first-child {
            border-bottom-left-radius: 2px;
        }

        td:last-child,
        th:last-child {
            border-bottom-right-radius: 2px;
        }
    }
}

[data-hidden-by-rules="true"] {
    display: none;
}
