
@use "../../00-settings" as *;

.modal-window {
    background-color: rgba(0,0,0,0);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -999;
    transition: background $link-transition;

    &--visible {
        z-index: 900000;
        background-color: rgba(0,0,0,.8);
    }

    &__mainbox {
        background-color: $color-white;
        width: 100ch;
        max-width: 94%;
        max-height: 90%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 0%) scale(.75);
        opacity: 0;
        padding: $spacing $spacing*2;
        transition: all $link-transition;

        @include screen($bp480) {
            padding: $spacing*2 $spacing*3;
        }

        @include screen($bp768) {
            padding: $spacing*4 $spacing*6;
        }

        .modal-window--visible & {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1);
        }

        &__closebtn {
            cursor: pointer;
            padding: 0;
            width: $spacing*4;
            height: $spacing*4;
            background-color: $color-grey-01;
            position: absolute;
            right: -$spacing;
            top: -$spacing;
            display: grid;
            justify-content: center;
            align-items: center;
            border: 2px solid $color-grey-01;
            border-radius: 100px;
            box-shadow: 0 0 $spacing*1 rgba(0,0,0,.20);
            transition: background $link-transition,
            box-shadow $link-transition;

            @include screen($bp480) {
                right: -$spacing*2;
                top: -$spacing*2;
            }

            svg {
                width: $spacing*2;
                height: $spacing*2;
                opacity: .65;
                transition: opacity $link-transition;
                pointer-events: none;
            }

            &:hover,
            &:focus,
            &:active {
                background-color: $color-grey-02;
                box-shadow: 0 0 $spacing*2 rgba(0,0,0,.35);

                svg {
                    opacity: 1;
                }

            }
        }
    }

}


/* content area specific stlyes */
.md-window {
    &--centered {
        text-align: center;
    }
}

