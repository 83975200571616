
/* ---- Sponsors ---- */

.widget-SponsorList {

}

.sponsors-list__item {
	border: 1px solid #cecece;
	width: 48.148148148148%;
	float: left;
	margin-left: 3.561253561254%;
	margin-right: 0;
	text-align: center;
	padding: 10px;
}

.sponsors-list__item__link {
	color: inherit;
	text-decoration: none;
}

.sponsors-list__item__link:hover,
.sponsors-list__item__link:focus,
.sponsors-list__item__link:active {
	text-decoration: underline;
}
.sponsors-list__item a:first-child {
	line-height: 125px;
	display: block;
	vertical-align: middle;
}

.sponsors-list__item__image {
	width: auto;
	display: inline-block;
}


@media screen and (max-width: 620px) {
	.sponsors-list__item:nth-child(2n+1) {
		margin-left: 0;
		clear: left;
	}
}

@media screen and (min-width: 621px) and (max-width: 1240px) {
	.sponsors-list__item {
		width: 30.95916429249733%;
	}
	.sponsors-list__item:nth-child(3n+1) {
		margin-left: 0;
		clear: left;
	}
}

@media screen and (min-width: 1241px) {
	.sponsors-list__item {
		width: 22.3290598290595%;
	}
	.sponsors-list__item:nth-child(4n+1) {
		margin-left: 0;
		clear: left;
	}
}
