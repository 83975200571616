
@use "../../00-settings" as *;

/* ---- Row - nowrap  ---- */
.row-nowrap {
    flex-wrap: nowrap;
}

@each $key, $value in $breakpoints-list {
    .row-nowrap--#{$key} {
        @include screen(#{$value}) {
            flex-wrap: nowrap;
        }
    }
}
