@use "../../../00-settings" as *;
@use "../../../02-components/forms/_form-mixins.scss" as *;

/* ---- form style extenders ---- */
%form-field-pseudo-anchor--before-lhs {
    position: absolute;
    top: -$form-spacing;
    left: $form-spacing*2;
    display: inline-block;
    border-right: $form-spacing solid transparent;
    border-bottom: $form-spacing solid $color-grey-03;
    border-left: $form-spacing solid transparent;
    border-bottom-color: $color-grey-03;
    content: '';
}
%form-field-pseudo-anchor--after-lhs {
    position: absolute;
    top: -$form-spacing + 1;
    left: $form-spacing * 2 + 1;
    display: inline-block;
    border-right: $form-spacing - 1 solid transparent;
    border-bottom: $form-spacing - 1 solid #fff;
    border-left: $form-spacing - 1 solid transparent;
    content: '';
}


%form-field-pseudo-anchor--before-rhs {
    position: absolute;
    top: -$form-spacing;
    right: $form-spacing*2;
    left: auto;
    display: inline-block;
    border-right: $form-spacing solid transparent;
    border-bottom: $form-spacing solid $color-grey-03;
    border-left: $form-spacing solid transparent;
    border-bottom-color: $color-grey-03;
    content: '';
}
%form-field-pseudo-anchor--after-rhs {
    position: absolute;
    top: -$form-spacing + 1;
    right: $form-spacing * 2 + 1;
    left: auto;
    display: inline-block;
    border-right: $form-spacing - 1 solid transparent;
    border-bottom: $form-spacing - 1 solid #fff;
    border-left: $form-spacing - 1 solid transparent;
    content: '';
}
