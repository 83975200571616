
@use "../../00-settings" as *;
@use "../buttons-and-links/button-mixins" as *;

.button,
.button-green,
.button--accent-01 {
    @include button;
}

/* ---- Sizes ---- */
/* Small */
.button--small,
.button-small {
    @include button-size('small');
}

.button--y-small {
    --button-padding-vertical: 0.5em;
}

.button--large {
    @include button-size('large');
}


/* ---- Button Colours ---- */
.button-darkgreen {
	@include button($color-08, $color-white, $color-10);
    // icon colour: #95d38e;
}

.button--secondary,
.button-blue1 {
    @include button($color-01-dark, $color-white, $color-utility-alert);
}

.button-blue2 {
    @include button($color-04-dull, $color-white, $color-utility-alert);
}

.button--tertiary,
.button-blue3 {
    @include button($color-02-dark, $color-white, $color-utility-alert);
}

.button-grey {
    @include button($color-grey-02, $color-grey-10, $color-grey-07);
}

.button-red {
    @include button($color-alternative-subtle, $color-white, $color-alternative);
}

.button--warning {
    @include button($color-utility-warning-subtle, $color-white, $color-utility-warning);
}

.button--neutral {
    @include button($color-grey-02, $color-grey-10);
}

.button--positive {
    @include button($color-utility-positive);
}
