
@use "../../00-settings" as *;
@use "../../05-utilities/helpers/utility-mixins" as *;

/* ---- Hidden ---- */
.-hidden {
    display: none !important;
    visibility: hidden;
}

/* ---- Invisible ---- */
.-invisible {
    visibility: hidden;
}

/* ---- Visibility Hidden ---- */
%vis-hidden,
.-vis-hidden {
    @include vis-hidden;
}

/* ---- Hide / Show  ---- */
// Display none utility classes
.hide {
    display: none;
}

.show {
    display: initial;
}

/* ---- Legacy show/hide ---- */
/* BP 1 */
@media screen and (max-width: 1240px) {
	.show-bp1 {
		display: initial;
	}
	.hide-bp1 {
		display: none;
	}
}
/* BP 2 */
@media screen and (max-width: 1000px) {
	.show-bp2 {
		display: initial;
	}
	.hide-bp2 {
		display: none;
	}
}
/* BP 3 */
@media screen and (max-width: 760px) {
	.show-bp3 {
		display: initial;
	}
	.hide-bp3 {
		display: none;
	}
}
/* BP 4 */
@media screen and (max-width: 620px) {
	.show-bp4 {
		display: initial;
	}
	.hide-bp4 {
		display: none;
	}
}

/* ---- Updated show/hide ---- */
@each $key, $value in $breakpoints-list {
    .hide--#{$key} {
        @include screen(#{$value}) {
            display: none;
        }
    }

    .show--#{$key} {
        display: none;

        @include screen(#{$value}) {
            display: initial;
        }
    }
}
