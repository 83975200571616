
@use "../../00-settings" as *;
@use "../../05-utilities/helpers/utility-mixins" as *;

/*
svg path types
path,
circle,
ellipse,
rect,
line,
polygon
*/


.icon {
    display: inline-block;
    vertical-align: middle;
}

.icon svg {
    width: 16px;
    height: 16px;
}

.icon--24px svg {
    width: 24px;
    height: 24px;
}

.icon--32px svg {
    width: 32px;
    height: 32px;
}

.icon--40px svg {
    width: 40px;
    height: 40px;
}

.icon--48px svg {
    width: 48px;
    height: 48px;
}

.icon--52px svg {
    width: 52px;
    height: 52px;
}

.icon--60px svg {
    width: 60px;
    height: 60px;
}

.icon--68px svg {
    width: 68px;
    height: 68px;
}


/* Icon system colours */

.icon--primary {
    @include svg-contents {
        fill: $color-00;
    }
}

.icon--secondary {
    @include svg-contents {
        fill: $color-01;
    }
}

.icon--alternative {
    @include svg-contents {
        fill: $color-03;
    }
}

.icon--current-color {
    @include svg-contents {
        fill: currentColor;
    }
}

.icon--white {
    @include svg-contents {
        fill: $color-white;
    }
}

.icon--accent-01 {
    @include svg-contents {
        fill: $color-06;
    }
}

.icon--accent-02 {
    @include svg-contents {
        fill: $color-08;
    }
}
